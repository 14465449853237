// © ООО «Эдиспа», 2022

import {
  useReducer as useReactReducer,
  useCallback,
  useEffect,
  useRef
} from 'react';

import { AbstractAction, PayloadAction } from 'utils/reducer';

enum ActionType {
  START,
  SUCCESS,
  FAILURE
}

type StartAction = AbstractAction<ActionType.START>;

type SuccessAction<T> = PayloadAction<ActionType.SUCCESS, T>;

type FailureAction = PayloadAction<ActionType.FAILURE, Error>;

export interface State<T = any> {
  loading: boolean;
  error?: Error;
  data?: T;
}

type Action<T> = StartAction | SuccessAction<T> | FailureAction;

type Reducer<T> = (state: State<T>, action: Action<T>) => State<T>;

export function reducer<T>(state: State<T>, action: Action<T>): State<T> {
  switch (action.type) {
    case ActionType.START: {
      return {
        loading: true
      };
    }
    case ActionType.SUCCESS: {
      return {
        loading: false,
        data: action.payload
      };
    }
    case ActionType.FAILURE: {
      return {
        loading: false,
        error: action.payload
      };
    }
    default: {
      return state;
    }
  }
}

type Dispatch<T> = (action: Action<T>) => void;

export function useReducer<T>(initialState: State<T>): [State<T>, Dispatch<T>] {
  return useReactReducer<Reducer<T>>(reducer, initialState);
}

export interface ListState<T = any> {
  loading: boolean;
  error?: Error;
  data: T[];
}

type ListAction<T> = StartAction | SuccessAction<T[]> | FailureAction;

type ListReducer<T> = (
  state: ListState<T>,
  action: ListAction<T>
) => ListState<T>;

type ListDispatch<T> = (action: ListAction<T>) => void;

export function listReducer<T>(
  state: ListState<T>,
  action: ListAction<T>
): ListState<T> {
  switch (action.type) {
    case ActionType.START: {
      return {
        loading: true,
        data: []
      };
    }
    case ActionType.SUCCESS: {
      return {
        loading: false,
        data: action.payload
      };
    }
    case ActionType.FAILURE: {
      return {
        loading: false,
        error: action.payload,
        data: []
      };
    }
    default: {
      return state;
    }
  }
}

export function useListReducer<T>(
  initialState: ListState<T>
): [ListState<T>, ListDispatch<T>] {
  return useReactReducer<ListReducer<T>>(listReducer, initialState);
}

const onStart = (): StartAction => ({ type: ActionType.START });

function onSuccess<T>(payload: T): SuccessAction<T> {
  return { type: ActionType.SUCCESS, payload };
}

const onFailure = (payload: Error): FailureAction => ({
  type: ActionType.FAILURE,
  payload
});

export const actions = {
  onStart,
  onSuccess,
  onFailure
};

type ListStateHandler<T> = () => Promise<T[]>;

export function useList<T>(handler: ListStateHandler<T>): ListState<T> {
  const ref = useRef(handler);
  const [state, dispatch] = useListReducer<T>({
    loading: true,
    data: []
  });

  useEffect(() => {
    const dispatchHandler = async () => {
      try {
        const handler = ref.current;
        const result = await handler();
        dispatch(actions.onSuccess(result));
      } catch (error: any) {
        dispatch(actions.onFailure(error));
      }
    };

    dispatchHandler();
  }, [dispatch, ref]);

  return state;
}

type StateHandler<T, P> = (params: P) => Promise<T>;

export function useFetch<T, P>(
  handler: StateHandler<T, P>
): [React.Dispatch<P>, State<T>] {
  const ref = useRef(handler);
  const [state, dispatch] = useReducer<T>({
    loading: false
  });

  const dispatchHandler = useCallback<React.Dispatch<P>>(
    async (params: P) => {
      try {
        dispatch(actions.onStart());
        const result = await handler(params);
        dispatch(actions.onSuccess(result));
      } catch (error: any) {
        dispatch(actions.onFailure(error));
      }
    },
    [dispatch, ref]
  );
  return [dispatchHandler, state];
}
