// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';

import Button from 'components/button/Button';
import Message from 'components/message/Message';
import { Title, Paragraph } from 'components/Typography';
import DeletePowerGridModal from 'grid/components/DeletePowerGridModal';
import { SettingsViewContainer } from 'grid/components/Layout';
import { Route } from 'router';

const PowerGridDeleteView: FunctionComponent = () => {
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);

  const onClick = () => setModalVisible(true);

  const onCancel = () => setModalVisible(false);

  const onComplete = () => {
    navigate(Route.HOME);
    setTimeout(() => {
      message.info({
        content: <Message type="info">Сеть удалена.</Message>
      });
    }, 100);
  };

  return (
    <SettingsViewContainer>
      <Title>
        <FormattedMessage id="page.delete.power.grid.title" />
      </Title>
      <Paragraph className="edispa-body-one-light">
        <FormattedMessage id="page.delete.power.grid.instructions" />
      </Paragraph>
      <Button type="danger" onClick={onClick}>
        <FormattedMessage id="button.delete.power.grid" />
      </Button>
      <DeletePowerGridModal
        visible={modalVisible}
        onComplete={onComplete}
        onCancel={onCancel}
      />
    </SettingsViewContainer>
  );
};

export default PowerGridDeleteView;
