// © ООО «Эдиспа», 2022

import csvtojson from 'csvtojson';
import { getAuth, User } from 'firebase/auth';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  serverTimestamp,
  updateDoc,
  where
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { flatten } from 'lodash';

import { getCurrentUser } from 'auth/utils';
import { Schema } from 'grid';
import { convertDocumentToImportMappings, ImportMappings } from 'import';
import { convertCsvDataToNodes, convertCsvDataToEdges } from 'import/utils';
import { useFetch, useList } from 'utils/hooks';

export const useListImportMappings = () =>
  useList(async () => {
    const currentUser = getCurrentUser();

    const scopes = [currentUser.uid, 'public'];

    const db = getFirestore();
    const importMappingsRef = collection(db, 'importMappings');
    const listImportMappingsQuery = query(
      importMappingsRef,
      where('scope', 'in', scopes)
    );
    const { docs } = await getDocs(listImportMappingsQuery);

    return docs.map(convertDocumentToImportMappings);
  });

type CreateImportMappingParams = Pick<
  ImportMappings,
  'title' | 'delimiter' | 'mappings'
>;

export const useCreateImportMappings = () =>
  useFetch<ImportMappings, CreateImportMappingParams>(async params => {
    const { title, delimiter, mappings } = params;
    const { uid, displayName } = getAuth().currentUser as User;
    const db = getFirestore();
    const importMappingsRef = collection(db, 'importMappings');
    const ref = await addDoc(importMappingsRef, {
      title,
      delimiter,
      owner: {
        id: uid,
        displayName
      },
      scope: uid,
      mappings,
      createdAt: serverTimestamp()
    });
    const importMappingsDoc = await getDoc(ref);
    return convertDocumentToImportMappings(importMappingsDoc);
  });

type UpdateImportMappingParams = Pick<
  ImportMappings,
  'id' | 'title' | 'delimiter' | 'mappings'
>;

export const useUpdateImportMappings = () =>
  useFetch<ImportMappings, UpdateImportMappingParams>(async params => {
    const { id, title, delimiter, mappings } = params;
    const db = getFirestore();
    const importMappingsRef = doc(db, 'importMappings', id);

    await updateDoc(importMappingsRef, {
      title,
      delimiter,
      mappings,
      updatedAt: serverTimestamp()
    });

    const importMappingsDoc = await getDoc(importMappingsRef);
    return convertDocumentToImportMappings(importMappingsDoc);
  });

export const useDeleteImportMappings = () =>
  useFetch<boolean, string>(async id => {
    const db = getFirestore();
    const importMappingsRef = doc(db, 'importMappings', id);
    await deleteDoc(importMappingsRef);
    return true;
  });

interface ImportFromCsvParams {
  mappings: ImportMappings;
  nodeFileList: any[];
  edgeFileList: any[];
}

export const useImportFromCsv = () =>
  useFetch<Pick<Schema, 'nodes' | 'edges'>, ImportFromCsvParams>(
    async ({ mappings, nodeFileList, edgeFileList }) => {
      const upload = (file: any): Promise<any> =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (event: any) => {
            const csvData = event.target.result;
            resolve(
              csvtojson({
                delimiter: mappings.delimiter
              }).fromString(csvData)
            );
          };
          reader.onerror = () => {
            reject('Не удалось загрузить файл.');
          };
          reader.readAsText(file, 'windows-1251');
        });

      const nodeData = flatten(await Promise.all(nodeFileList.map(upload)));
      const nodes = convertCsvDataToNodes(nodeData, mappings.mappings.node);
      const edgeData = flatten(await Promise.all(edgeFileList.map(upload)));
      const edges = convertCsvDataToEdges(edgeData, mappings.mappings.edge);

      return {
        nodes,
        edges
      };
    }
  );

const upload = (file: any): Promise<any> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      const text = event.target.result;
      try {
        resolve(JSON.parse(text));
      } catch (error: any) {
        reject(error);
      }
    };
    reader.onerror = () => {
      reject('Не удалось загрузить файл.');
    };
    reader.readAsText(file, 'windows-1251');
  });

interface ImportFromJsonParams {
  file: File;
}

export interface ValidateSchemaResponse {
  schema: Schema;
  isValid: boolean;
}

export const useImportFromJson = () =>
  useFetch<Schema, ImportFromJsonParams>(async ({ file }) => {
    const data = await upload(file);
    const functions = getFunctions();
    const validateSchema = httpsCallable<any, ValidateSchemaResponse>(
      functions,
      'validateSchema'
    );
    const result = await validateSchema(data);
    const { schema } = result.data;

    return schema;
  });
