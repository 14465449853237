// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Checkbox } from 'antd';
import { FormItemProps, Rule, RuleRender } from 'antd/lib/form';
import { isBoolean, isNil, omit, omitBy } from 'lodash';
import { v4 } from 'uuid';

import Form, { FormProps, Row } from 'components/form/Form';
import { useRules } from 'components/form/rules';
import Label from 'components/form/Label';
import InputNumber from 'components/input/InputNumber';
import { Spacer } from 'components/Layout';
import Select, { Option } from 'components/select/Select';
import { Edge, PowerGridMode } from 'grid';
import { usePowerGridContext } from 'grid/context';

const { Item } = Form;

export interface EdgeDetailsFormProps {
  /**
   * ветвь
   */
  edge?: Edge;
  /**
   * функция обратного вызова для сохранения свойств ветви
   */
  onSubmit: (edge: Edge) => void;
  /**
   * футер
   */
  footer: FormItemProps['children'];
}

interface CustomRules {
  loop: (name: string) => RuleRender;
  duplicate: (edges: Edge[]) => RuleRender;
}

const useCustomRules = (): CustomRules => {
  const intl = useIntl();

  return useMemo(
    () => ({
      loop:
        (name: string) =>
        ({ getFieldValue }) => ({
          validator: async (rule: Rule, value: any) => {
            const otherValue = getFieldValue(name);
            if (value && otherValue && value === otherValue) {
              const message = intl.formatMessage({
                id: 'form.validation.invalid.value'
              });
              throw new Error(message);
            }
          }
        }),
      duplicate:
        (edges: Edge[]) =>
        ({ getFieldValue }) => ({
          validator: async () => {
            const source = getFieldValue('source');
            const target = getFieldValue('target');
            if (
              edges.filter(
                item => item.source === source && item.target === target
              ).length > 0
            ) {
              const message = intl.formatMessage({
                id: 'form.validation.duplicate.value'
              });
              throw new Error(message);
            }
          }
        })
    }),
    []
  );
};

const EdgeDetailsForm: FunctionComponent<EdgeDetailsFormProps & FormProps> = ({
  edge,
  onSubmit,
  footer,
  ...otherProps
}) => {
  const intl = useIntl();
  const customRules = useCustomRules();
  const rules = useRules();
  const [form] = Form.useForm();
  const { grid } = usePowerGridContext();
  const { schema, mode } = grid;
  const { nodes, edges } = schema;
  const initialValues = edge && omit(edge, 'messages');

  const otherEdges = useMemo(() => {
    return edge ? edges.filter(item => item.id !== edge.id) : edges;
  }, [edge, edges]);

  const onFinish = (values: any) => {
    values = {
      ...values,
      disabled: !values.enabled
    };
    const edge = omitBy(
      initialValues
        ? {
            ...initialValues,
            ...values
          }
        : {
            ...values,
            id: v4()
          },
      isNil
    ) as Edge;
    onSubmit(edge);
  };

  const { disabled, ...otherValues } = initialValues || { disabled: false };

  return (
    <Form
      form={form}
      requiredMark={false}
      layout="vertical"
      onFinish={onFinish}
      initialValues={{
        ...otherValues,
        enabled: isNil(disabled) || isBoolean(disabled) ? !disabled : disabled
      }}
      {...otherProps}
    >
      <Row>
        <Item
          name="enabled"
          valuePropName="checked"
          style={{ marginBottom: 24 }}
          rules={[rules.boolean]}
        >
          <Checkbox>
            <FormattedMessage id="edge.prop.enabled.label" />
          </Checkbox>
        </Item>
      </Row>
      {mode === PowerGridMode.CONFIGURATION && (
        <Row>
          <Item
            name="source"
            label={
              <Label
                value={<FormattedMessage id="edge.prop.source.label" />}
                info={<FormattedMessage id="edge.prop.source.description" />}
              />
            }
            dependencies={['target']}
            rules={[
              { required: true },
              customRules.loop('target'),
              customRules.duplicate(otherEdges)
            ]}
          >
            <Select>
              <Option value="">
                {intl.formatMessage({ id: 'label.empty' })}
              </Option>
              {nodes.map(node => (
                <Option key={node.id} value={node.id}>
                  {node.label}
                </Option>
              ))}
            </Select>
          </Item>
          <Item
            name="target"
            label={
              <Label
                value={<FormattedMessage id="edge.prop.target.label" />}
                info={<FormattedMessage id="edge.prop.target.description" />}
              />
            }
            dependencies={['source']}
            rules={[
              { required: true },
              customRules.loop('source'),
              customRules.duplicate(otherEdges)
            ]}
          >
            <Select>
              <Option value="">
                {intl.formatMessage({ id: 'label.empty' })}
              </Option>
              {nodes.map(node => (
                <Option key={node.id} value={node.id}>
                  {node.label}
                </Option>
              ))}
            </Select>
          </Item>
        </Row>
      )}
      <Row>
        <Item
          name="r"
          label={
            <Label
              value={<FormattedMessage id="edge.prop.r.label" />}
              info={<FormattedMessage id="edge.prop.r.description" />}
            />
          }
          rules={[{ required: true }, rules.number, rules.min()]}
        >
          <InputNumber />
        </Item>
        <Item
          name="x"
          label={
            <Label
              value={<FormattedMessage id="edge.prop.x.label" />}
              info={<FormattedMessage id="edge.prop.x.description" />}
            />
          }
          rules={[{ required: true }, rules.number, rules.min()]}
        >
          <InputNumber />
        </Item>
      </Row>
      <Row>
        <Item
          name="gc"
          label={
            <Label
              value={<FormattedMessage id="edge.prop.gc.label" />}
              info={<FormattedMessage id="edge.prop.gc.description" />}
            />
          }
          rules={[{ required: true }, rules.number]}
        >
          <InputNumber />
        </Item>
        <Item
          name="bc"
          label={
            <Label
              value={<FormattedMessage id="edge.prop.bc.label" />}
              info={<FormattedMessage id="edge.prop.bc.description" />}
            />
          }
          rules={[{ required: true }, rules.number]}
        >
          <InputNumber />
        </Item>
      </Row>
      <Row>
        <Item
          name="iMaxSrc"
          label={
            <Label
              value={<FormattedMessage id="edge.prop.i.max.src.label" />}
              info={<FormattedMessage id="edge.prop.i.max.src.description" />}
            />
          }
          rules={[{ required: true }, rules.number, rules.greater()]}
        >
          <InputNumber />
        </Item>
        <Item
          name="iMaxTgt"
          label={
            <Label
              value={<FormattedMessage id="edge.prop.i.max.tgt.label" />}
              info={<FormattedMessage id="edge.prop.i.max.tgt.description" />}
            />
          }
          rules={[{ required: true }, rules.number, rules.greater()]}
        >
          <InputNumber />
        </Item>
      </Row>
      <Row>
        <Item
          name="kt"
          label={
            <Label
              value={<FormattedMessage id="edge.prop.kt.label" />}
              info={<FormattedMessage id="edge.prop.kt.description" />}
            />
          }
          rules={[rules.number, rules.greater()]}
        >
          <InputNumber />
        </Item>
        <Spacer />
      </Row>
      {footer && <Item noStyle>{footer}</Item>}
    </Form>
  );
};

export default EdgeDetailsForm;
