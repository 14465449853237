// © ООО «Эдиспа», 2022

import React from 'react';
import ReactPDF, { Text, View } from '@react-pdf/renderer';

import styles from 'components/pdf/table/styles';
import { Column } from 'components/pdf/table/types';

export interface RowProps<T> {
  columns: Column<T>[];
  record: T;
  index: number;
}

export default function Row<T>(props: RowProps<T> & ReactPDF.ViewProps) {
  const { columns, record, index, ...otherProps } = props;
  return (
    <View {...otherProps}>
      {columns.map(column => (
        <View
          key={column.dataIndex}
          style={{
            width: column.width,
            flex: column.flex,
            textAlign: column.align || 'center'
          }}
        >
          <Text style={styles.cell}>
            {column.render
              ? column.render(record, index)
              : (record as any)[column.dataIndex]}
          </Text>
        </View>
      ))}
    </View>
  );
}
