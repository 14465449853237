// © ООО «Эдиспа», 2022

import React from 'react';
import { View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { isString } from 'lodash';

import Header from 'components/pdf/table/Header';
import Row from 'components/pdf/table/Row';
import styles from 'components/pdf/table/styles';
import { Column } from 'components/pdf/table/types';

type RowKeyFunction<T> = (record: T, index: number) => string;

export interface TableProps<T> {
  rowKey?: string | RowKeyFunction<T>;
  dataSource: T[];
  columns: Column<T>[];
  style?: Style;
}

export default function Table<T = any>(props: TableProps<T>) {
  const { columns, dataSource, style, rowKey = 'id' } = props;
  const allStyles: Style[] = [styles.table];
  if (style) {
    allStyles.push(style);
  }
  return (
    <View style={allStyles}>
      <Header columns={columns} />
      {dataSource.map((record, index) => {
        const key = isString(rowKey)
          ? (record as any)[rowKey]
          : rowKey(record, index);

        const style: Style[] = [styles.row];
        if (index === dataSource.length - 1) {
          style.push(styles.lastRow);
        }

        return (
          <Row<T>
            style={style}
            key={key}
            record={record}
            columns={columns}
            index={index}
          />
        );
      })}
    </View>
  );
}
