// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { Document, Page, Text, Font, StyleSheet } from '@react-pdf/renderer';
import { omit, round } from 'lodash';

import Table from 'components/pdf/table/Table';
import Header from 'emergency/components/Header';
import OverloadedEdgeSummary from 'emergency/components/OverloadedEdgeSummary';
import {
  EffectiveGenerator,
  EmergencyLiquidationReport,
  Generator,
  OverloadedEdge,
  Action,
  Deviation,
  getActionTypeLabel
} from 'emergency/report';

export interface EmergencyLiquidationReportDocumentProps {
  report: EmergencyLiquidationReport;
  formatMessage: (id: string, values?: any) => string;
}

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: '/fonts/Roboto/Roboto-Regular.ttf'
    },
    {
      src: '/fonts/Roboto/Roboto-Bold.ttf',
      fontWeight: 'bold'
    }
  ]
});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
    fontSize: 10,
    size: 'A4',
    orientation: 'portrait',
    paddingVertical: 40,
    paddingHorizontal: 32
  },
  header: {
    marginTop: -40
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 24,
    marginBottom: 16
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 16,
    marginBottom: 8
  },
  emptyText: {
    marginTop: 16
  },
  logo: {
    width: 74,
    height: 64
  }
});

const EmergencyLiquidationReportDocument: FunctionComponent<EmergencyLiquidationReportDocumentProps> =
  ({ report, formatMessage }) => {
    const { edges, generators, phases, summary, title, date, deviations } =
      report;
    return (
      <Document>
        <Page style={styles.page}>
          <Header style={styles.header} title={title} date={date} />
          <Text style={styles.title}>Исходные данные</Text>
          {deviations.length > 0 && (
            <>
              <Text style={omit(styles.subtitle, 'marginTop')}>
                Отклонения от нормальной схемы
              </Text>
              <Table<Deviation>
                dataSource={deviations}
                columns={[
                  {
                    dataIndex: 'label',
                    title: 'Имя',
                    flex: 1,
                    align: 'left'
                  },
                  {
                    dataIndex: 'disabled',
                    title: 'Состояние',
                    render: record =>
                      record.disabled ? 'Отключена' : 'Включена',
                    width: 210,
                    align: 'left'
                  }
                ]}
              />
            </>
          )}
          <Text
            style={
              deviations.length > 0
                ? styles.subtitle
                : omit(styles.subtitle, 'marginTop')
            }
          >
            Состояния генераторов
          </Text>
          <Table<Generator>
            dataSource={generators}
            columns={[
              {
                dataIndex: 'label',
                title: 'Имя',
                flex: 1,
                align: 'left'
              },
              {
                dataIndex: 'pGen',
                title: 'Pг',
                width: 70,
                align: 'left'
              },
              {
                dataIndex: 'pMin',
                title: 'Pмин',
                width: 70,
                align: 'left'
              },
              {
                dataIndex: 'pMax',
                title: 'Pмакс',
                width: 70,
                align: 'left'
              }
            ]}
          />
          <Text style={styles.subtitle}>Перегруженные ветви</Text>
          <Table<OverloadedEdge>
            dataSource={edges}
            columns={[
              {
                dataIndex: 'label',
                title: 'Ветвь',
                flex: 1,
                align: 'left'
              },
              {
                dataIndex: 'iSrc',
                title: 'Iнач',
                width: 70,
                align: 'left'
              },
              {
                dataIndex: 'iMaxSrc',
                title: 'Iмакс.н',
                width: 70,
                align: 'left'
              },
              {
                dataIndex: 'iTgt',
                title: 'Iкон',
                width: 70,
                align: 'left'
              },
              {
                dataIndex: 'iMaxTgt',
                title: 'Iмакс.к',
                width: 70,
                align: 'left'
              },
              {
                dataIndex: 'loadFactorSrc',
                title: 'Kзагр.н',
                width: 70,
                align: 'left'
              },
              {
                dataIndex: 'loadFactorTgt',
                title: 'Kзагр.к',
                width: 70,
                align: 'left'
              }
            ]}
          />
          {phases.map((phase, index) => {
            const { before, after, generators, actions } = phase;
            return (
              <React.Fragment key={index}>
                <Text
                  style={styles.title}
                >{`Разгрузка ветви ${before.label}`}</Text>
                <OverloadedEdgeSummary edge={before} />
                {actions.length > 0 ? (
                  <>
                    <Text style={styles.subtitle}>
                      {formatMessage('title.effective.generators')}
                    </Text>
                    <Table<EffectiveGenerator>
                      dataSource={generators}
                      columns={[
                        {
                          dataIndex: 'label',
                          title: formatMessage('node.prop.label'),
                          flex: 1,
                          align: 'left'
                        },
                        {
                          dataIndex: 'pGen',
                          title: formatMessage('node.prop.p.gen.label'),
                          width: 70,
                          align: 'left'
                        },
                        {
                          dataIndex: 'pMin',
                          title: formatMessage('node.prop.p.min.label'),
                          width: 70,
                          align: 'left'
                        },
                        {
                          dataIndex: 'pMax',
                          title: formatMessage('node.prop.p.max.label'),
                          width: 70,
                          align: 'left'
                        },
                        {
                          dataIndex: 'factor',
                          title: formatMessage('label.efficiency'),
                          width: 100,
                          // TODO: поэкспериментировать с форматированием коэффициента,
                          // так как его величина может быть мала.
                          render: record => round(record.factor, 3),
                          align: 'left'
                        }
                      ]}
                    />
                    <Text style={styles.subtitle}>
                      {formatMessage('title.emergency.response')}
                    </Text>
                    <Table<Action>
                      dataSource={actions}
                      columns={[
                        {
                          dataIndex: 'label',
                          title: formatMessage('label.name'),
                          flex: 1,
                          align: 'left'
                        },
                        {
                          dataIndex: 'type',
                          title: formatMessage('label.operation'),
                          width: 100,
                          align: 'left',
                          render: record => getActionTypeLabel(record.type)
                        },
                        {
                          dataIndex: 'limit',
                          title: formatMessage('label.limit'),
                          flex: 1,
                          align: 'left'
                        },
                        {
                          dataIndex: 'pGen',
                          title: formatMessage('node.prop.p.gen.label'),
                          width: 100,
                          align: 'left'
                        }
                      ]}
                    />
                    <Text style={styles.subtitle}>
                      {formatMessage('title.result')}
                    </Text>
                    <OverloadedEdgeSummary edge={after} />
                  </>
                ) : (
                  <Text style={styles.emptyText}>
                    {formatMessage('title.emergency.response.not.found')}
                  </Text>
                )}
              </React.Fragment>
            );
          })}
          <Text style={styles.title}>
            {formatMessage('title.summary.report')}
          </Text>
          {phases.some(phase => phase.actions.length > 0) ? (
            <>
              <Text style={omit(styles.subtitle, 'marginTop')}>
                {formatMessage('title.emergency.response')}
              </Text>
              <Table<Action>
                dataSource={summary.actions}
                rowKey={(_, index) => `${index}`}
                columns={[
                  {
                    dataIndex: 'label',
                    title: formatMessage('label.name'),
                    flex: 1,
                    align: 'left'
                  },
                  {
                    dataIndex: 'type',
                    title: formatMessage('label.operation'),
                    width: 140,
                    align: 'left',
                    render: record => getActionTypeLabel(record.type)
                  },
                  {
                    dataIndex: 'pGen',
                    title: formatMessage('node.prop.p.gen.label'),
                    width: 100,
                    align: 'left'
                  }
                ]}
              />
              <Text style={styles.subtitle}>
                {formatMessage('title.result')}
              </Text>
              <Table<OverloadedEdge>
                dataSource={report.summary.edges}
                columns={[
                  {
                    dataIndex: 'label',
                    title: 'Ветвь',
                    flex: 1,
                    align: 'left'
                  },
                  {
                    dataIndex: 'iSrc',
                    title: 'Iнач',
                    width: 70,
                    align: 'left'
                  },
                  {
                    dataIndex: 'iMaxSrc',
                    title: 'Iмакс.н',
                    width: 70,
                    align: 'left'
                  },
                  {
                    dataIndex: 'iTgt',
                    title: 'Iкон',
                    width: 70,
                    align: 'left'
                  },
                  {
                    dataIndex: 'iMaxTgt',
                    title: 'Iмакс.к',
                    width: 70,
                    align: 'left'
                  },
                  {
                    dataIndex: 'loadFactorSrc',
                    title: 'Kзагр.н',
                    width: 70,
                    align: 'left'
                  },
                  {
                    dataIndex: 'loadFactorTgt',
                    title: 'Kзагр.к',
                    width: 70,
                    align: 'left'
                  }
                ]}
              />
            </>
          ) : (
            <Text>{formatMessage('title.emergency.response.not.found')}</Text>
          )}
        </Page>
      </Document>
    );
  };

export default EmergencyLiquidationReportDocument;
