// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'antd';

import IconButton from 'grid/components/toolbar/IconButton';
import { usePowerGridDispatch } from 'grid/context';
import { actions, PowerGridFeature } from 'grid/store';
import { IconDiskSvg } from 'svg';

const SaveButton: FunctionComponent = () => {
  const dispatch = usePowerGridDispatch();

  const onClick = () =>
    dispatch(actions.setActiveFeature(PowerGridFeature.UPDATE_SCHEMA));

  return (
    <Tooltip mouseEnterDelay={1} title={<FormattedMessage id="button.save" />}>
      <IconButton onClick={onClick}>
        <IconDiskSvg />
      </IconButton>
    </Tooltip>
  );
};

export default SaveButton;
