// © ООО «Эдиспа», 2022

import { DocumentSnapshot, Timestamp } from 'firebase/firestore';
import { isPlainObject, isString } from 'lodash';

import { CodeError } from 'utils/CodeError';

/**
 * Сообщение об ошибочном состоянии узла или ветви
 */
export interface Message {
  /**
   * Код сообщения
   */
  code: string;
  /**
   * Детали сообщения
   */
  details?: any;
}

export type Messages = { [path: string]: Message };

/**
 * Static Load Characteristic (статическая характеристика нагрузки)
 */
export interface Slc {
  id: string;
  a: [number, number, number];
  b: [number, number, number];
}

export enum PowerGridMode {
  CONFIGURATION = 'configuration',
  OPERATION = 'operation'
}

export enum NodeType {
  GENERATION = 'generation',
  LOAD = 'load',
  BALANCE = 'balance'
}

export const isNodeType = (value: any): value is NodeType =>
  value === NodeType.BALANCE ||
  value === NodeType.GENERATION ||
  value === NodeType.LOAD;

/**
 * Координаты узла
 */
interface NodePosition {
  x: number;
  y: number;
}

/**
 * Лэйаут узла
 */
export interface NodeLayout {
  position: NodePosition;
}

/**
 * Узел схемы
 */
export interface Node {
  /**
   * Идентификатор узла
   */
  id: string;
  /**
   * Тип
   */
  type: NodeType;
  /**
   * Лэйбл
   */
  label: string;
  /**
   * Номинальное напряжение
   */
  uNom?: number;
  /**
   * Активная мощность генерации
   */
  pGen?: number;
  /**
   * Реактивная мощность генерации
   */
  qGen?: number;
  /**
   * Номинальная активная мощность нагрузки
   */
  pNom?: number;
  /**
   * Номинальная реактивная мощность нагрузки
   */
  qNom?: number;
  /**
   * Заданное напряжение
   */
  uGiv?: number;
  /**
   * Минимально допустимая активная мощность генерации
   */
  pMin?: number;
  /**
   * Максимально допустимая активная мощность генерации
   */
  pMax?: number;
  /**
   * Минимально допустимая реактивная мощность генерации
   */
  qMin?: number;
  /**
   * Максимально допустимая реактивная мощность генерации
   */
  qMax?: number;
  /**
   * Активная проводимость шунта на землю
   */
  gSh?: number;
  /**
   * Реактивная проводимость шунта на землю
   */
  bSh?: number;
  /**
   * Номер СХН
   */
  slc?: string;
  /**
   * Установившееся напряжение
   */
  u?: number;
  /**
   * Установившееся значение угла
   */
  delta?: number;
  /**
   * Установившаяся потребляемая активная мощность нагрузки
   */
  p?: number;
  /**
   * Установившаяся потребляемая реактивная мощность нагрузки
   */
  q?: number;
  /**
   * Лэйаут узла
   */
  layout?: NodeLayout;
  /**
   * Сообщения об ошибочном состоянии свойств узла
   */
  messages?: Messages;
}

/**
 * Позиция порта узла
 */
export enum PortPosition {
  LEFT = 'left',
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom'
}

/**
 * Направление перетоков
 */
export enum FlowDirection {
  FORWARD = 'forward',
  BACKWARD = 'backward',
  NONE = 'none'
}

/**
 * Лэйаут ветви
 */
export interface EdgeLayout {
  /**
   * Позиция порта в узле начала ветви
   */
  source: PortPosition;
  /**
   * Позиция порта в узле конца ветви
   */
  target: PortPosition;
}

/**
 * Ветвь схемы
 */
export interface Edge {
  /**
   * Идентификатор ветви
   */
  id: string;
  /**
   * Идентификатор узла начала ветви
   */
  source: string;
  /**
   * Идентификатор узла конца ветви
   */
  target: string;
  /**
   * Активное сопротивление
   */
  r?: number;
  /**
   * Реактивное сопротивление
   */
  x?: number;
  /**
   * Коэффициент трансформации
   */
  kt?: number;
  /**
   * Максимальная токовая нагрузка в начале ветви
   */
  iMaxSrc?: number;
  /**
   * Максимальная токовая нагрузка в конце ветви
   */
  iMaxTgt?: number;
  /**
   * Ток в начале ветви
   */
  iSrc?: number;
  /**
   * Коэффициент загрузки в начале ветви
   */
  loadFactorSrc?: number;
  /**
   * Ток в конце ветви
   */
  iTgt?: number;
  /**
   * Коэффициент загрузки в конце ветви
   */
  loadFactorTgt?: number;
  /**
   * Активная проводимость на землю
   */
  gc?: number;
  /**
   * Реактивная проводимость на землю
   */
  bc?: number;
  /**
   * Установивщееся значение перетока в начале ветви
   */
  pSrc?: number;
  /**
   * Установивщееся значение перетока в начале ветви
   */
  qSrc?: number;
  /**
   * Установивщееся значение перетока в конце ветви
   */
  pTgt?: number;
  /**
   * Установивщееся значение перетока в конце ветви
   */
  qTgt?: number;
  /**
   * Установивщееся значение перетока в начале ветви
   */
  sSrc?: number;
  /**
   * Установивщееся значение перетока в конце ветви
   */
  sTgt?: number;
  /**
   * Лэйаут ветви
   */
  layout?: EdgeLayout;
  /**
   * Флаг выключенного состояния ветви.
   */
  disabled?: boolean;
  /**
   * TODO
   */
  uSrc?: number;
  /**
   * TODO
   */
  uTgt?: number;
  /**
   * Сообщения об ошибочном состоянии свойств ветви
   */
  messages?: Messages;
}

export type DenormalizedEdge = Omit<Edge, 'source' | 'target'> & {
  source: Node;
  target: Node;
};

export interface Schema {
  /**
   * Версия схемы
   */
  version: number;
  /**
   * Список узлов
   */
  nodes: Node[];
  /**
   * Список ветвей
   */
  edges: Edge[];
  /**
   * Список статических характеристик нагрузки (СХН)
   */
  slcs: Slc[];
}

export type DenormalizedSchema = Pick<Schema, 'nodes'> & {
  edges: DenormalizedEdge[];
};

export interface PowerGrid {
  id: string;
  title: string;
  scope: string;
  /**
   * Состояние энергосистемы (редактирование/эксплуатация)
   */
  mode: PowerGridMode;
  /**
   * Создатель конфигурации
   */
  owner: {
    id: string;
    displayName: string;
  };
  /**
   * Описание
   */
  description?: string;
  /**
   * Дата создания конфигурации энергосистемы
   */
  createdAt: number;
  /**
   * Дата последнего обновления конфигурации энергосистемы
   */
  updatedAt?: number;
  /**
   * Схема
   */
  schema: Schema;
}

export const convertDocumentToPowerGrid = (
  doc: DocumentSnapshot
): PowerGrid => {
  const data = doc.data();
  if (!data) {
    throw new CodeError('edispa/power-grid-not-found');
  }
  const {
    title,
    description,
    schema,
    createdAt,
    updatedAt,
    owner,
    scope,
    mode,
    slcs
  } = data;
  return {
    id: doc.id,
    title,
    description,
    schema,
    owner,
    scope,
    mode,
    createdAt: (createdAt as Timestamp).toMillis(),
    updatedAt: updatedAt && (updatedAt as Timestamp).toMillis(),
    slcs
  } as PowerGrid;
};

export const isNode = (value: any): value is Node =>
  isPlainObject(value) &&
  isString(value.id) &&
  Object.values(NodeType).includes(value.type);

export const isEdge = (value: any): value is Edge =>
  isPlainObject(value) &&
  isString(value.id) &&
  isString(value.source) &&
  isString(value.target);

export const isDenormalizedEdge = (value: any): value is DenormalizedEdge =>
  isPlainObject(value) && isNode(value.source) && isNode(value.target);
