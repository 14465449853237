// © ООО «Эдиспа», 2022

import React, { FunctionComponent, ReactNode } from 'react';
import styled, { css } from 'styled-components/macro';

interface ButtonProps {
  active: boolean;
}

const active = css`
  background: white;
  color: #1e052d;
`;

const inactive = css`
  color: white;
  background: none;
  cursor: pointer;
  :hover {
    background: #fc7456;
  }
  :active {
    background: #d05e4e;
  }
`;

const Button = styled.button<ButtonProps>`
  min-width: 84px;
  padding: 0 24px;
  border-radius: 6px;
  border: none;
  outline: none;
  line-height: 28px;
  ${props => (props.active ? active : inactive)};
`;

const Container = styled.div`
  background-color: #3f2251;
  border-radius: 8px;
  padding: 2px;
  display: inline-block;
  ${Button} + ${Button} {
    margin-left: 4px;
  }
`;

interface View {
  key: string;
  label: ReactNode;
}

export interface ActiveViewSelectorProps {
  views: View[];
  activeViewKey: string;
  onChange: (key: string) => void;
}

const ActiveViewSelector: FunctionComponent<ActiveViewSelectorProps> = ({
  activeViewKey,
  views,
  onChange
}) => {
  return (
    <Container>
      {views.map(view => {
        const { key, label } = view;
        const isActive = key === activeViewKey;
        const onClick = isActive ? undefined : () => onChange(key);
        return (
          <Button key={key} active={key === activeViewKey} onClick={onClick}>
            {label}
          </Button>
        );
      })}
    </Container>
  );
};

export default ActiveViewSelector;
