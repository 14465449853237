// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';

import ActiveView from 'auth/action/components/ActiveView';
import Provider from 'auth/action/components/Provider';
import Container from 'auth/components/Container';
import { useVerifyAction, VerifyActionResponse } from 'auth/hooks';
import Loading from 'components/loading/Loading';
import { useFormatMessage } from 'utils/format';

const ActionPage: FunctionComponent = () => {
  const { loading, data } = useVerifyAction();
  const formatMessage = useFormatMessage();

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      <Helmet>
        <title>{formatMessage('title.edispa')}</title>
      </Helmet>
      <Provider value={data as VerifyActionResponse}>
        <ActiveView />
      </Provider>
    </Container>
  );
};

export default ActionPage;
