// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';

import { useContext, useDispatch } from 'auth/action/context';
import { actions } from 'auth/action/store';
import Form from 'auth/components/form/Form';
import Alert from 'components/Alert';
import PasswordInput from 'auth/components/input/PasswordInput';
import { Title, Paragraph } from 'auth/components/Typography';
import { useConfirmPasswordReset, useQuery } from 'auth/hooks';
import Button from 'components/button/Button';
import SubmitButton from 'components/button/SubmitButton';
import MarkdownMessage from 'components/i18n/MarkdownMessage';
import { Route } from 'router';

const { Item } = Form;

const Container = styled.div`
  width: 400px;
  margin: 0 auto;
  ${Title}, ${Paragraph}, ${Alert} {
    margin-bottom: 32px;
  }
`;

const Footer = styled.div`
  display: flex;
  ${Button} {
    flex: 1;
  }
  ${Button} + ${Button} {
    margin-left: 16px;
  }
`;

const getRedirectUrl = (url?: string): string | undefined => {
  if (!url) {
    return;
  }
  const search = new URL(url).search;
  const params = new URLSearchParams(search);
  return params.get('redirectUrl') || undefined;
};

const NewPasswordView: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const query = useQuery();

  const context = useContext();
  const code = context.code as string;
  const email = context.email as string;
  const redirectUrl = getRedirectUrl(query.get('continueUrl') || undefined);

  const [confirmPasswordReset, { loading, error, data }] =
    useConfirmPasswordReset();

  const onFinish = (values: any) => {
    const { password } = values;
    // На мобильных устройствах приложение не поддерживается, поэтому аутентификация пользователя
    // после восстановления пароля не нужна.
    confirmPasswordReset({
      code,
      password,
      email: isMobileOnly ? undefined : email
    });
  };

  const onComplete = useCallback(() => {
    if (isMobileOnly) {
      // на мобильных устройствах показывается экран подтверждения восстановления пароля
      dispatch(actions.confirmNewPassword());
    } else {
      navigate(redirectUrl || Route.HOME);
    }
  }, [navigate, redirectUrl]);

  useEffect(() => {
    if (data) {
      onComplete();
    }
  }, [data, onComplete]);

  const onCancelClick = () => navigate(Route.HOME);

  return (
    <Container>
      <Title>
        <FormattedMessage id="auth.new.password.view.title" />
      </Title>
      <Paragraph>
        <MarkdownMessage
          id="auth.new.password.view.instructions"
          values={{ email }}
        />
      </Paragraph>
      <Alert error={error} />
      <Form
        onFinish={onFinish}
        initialValues={{
          password: ''
        }}
      >
        <Item
          name="password"
          rules={[{ min: 6 }, { required: true }]}
          label={<FormattedMessage id="form.label.password" />}
        >
          <PasswordInput />
        </Item>
        <Item noStyle>
          <Footer>
            <SubmitButton loading={loading}>
              <FormattedMessage id="button.send" />
            </SubmitButton>
            <Button onClick={onCancelClick}>
              <FormattedMessage id="button.cancel" />
            </Button>
          </Footer>
        </Item>
      </Form>
    </Container>
  );
};

export default NewPasswordView;
