// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

import Container from 'auth/components/Container';
import { Paragraph } from 'auth/components/Typography';
import { Route } from 'router';
import { PhoneCrossedSvg } from 'svg';

const Content = styled.div`
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${Paragraph} {
    margin-top: 24px;
  }
`;

const DeviceDetector: FunctionComponent = ({ children }) => {
  const { pathname } = useLocation();

  // Действия типа проверки почты или сброса пароля должны работать на мобильных устройствах,
  // так как пользователь может открывать ссылки, используя мобильные почтовые клиенты.
  if (Route.AUTH_ACTION === pathname || !isMobileOnly) {
    return <>{children}</>;
  }

  return (
    <Container>
      <Content>
        <PhoneCrossedSvg />
        <Paragraph>
          <FormattedMessage id="device.not.supported.view.instructions" />
        </Paragraph>
      </Content>
    </Container>
  );
};

export default DeviceDetector;
