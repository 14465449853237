// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';

import Container from 'auth/components/Container';
import { useUserContext } from 'auth/context';
import EmailVerificationView from 'auth/components/EmailVerificationView';
import { signOut } from 'auth/utils';

const EmailVerificationPage: FunctionComponent = () => {
  const { user } = useUserContext();

  // После подтверждения почтового адреса аутентифицированный пользователь должен выйти из сессии
  // чтобы при повторной аутентификации получить обновленный токен, содержащий информацию о проверенном
  // email.
  const onComplete = () => signOut();

  return (
    <Container>
      <EmailVerificationView user={user} onComplete={onComplete} />
    </Container>
  );
};

export default EmailVerificationPage;
