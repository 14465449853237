// © ООО «Эдиспа», 2022

import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components/macro';

import MenuItem from 'components/menu/MenuItem';
import { StyledProps } from 'themes';

const Container = styled.ul`
  padding: 8px 0;
  margin: 0;
`;

type IMenu<T> = FunctionComponent<T> & {
  Item: typeof MenuItem;
};

export type MenuProps = StyledProps & {
  onClick?: (event: Event) => void;
};

const Menu: IMenu<MenuProps> = ({ className, children, onClick }) => {
  return (
    <Container className={className}>
      {React.Children.map(children, child => {
        const element = child as ReactElement;
        const props = element.props;
        return React.cloneElement(element, {
          onClick: (event: Event) => {
            props.onClick && props.onClick();
            onClick && onClick(event);
          }
        });
      })}
    </Container>
  );
};

Menu.Item = MenuItem;

export default Menu;
