// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import styled, { ThemeProvider } from 'styled-components/macro';

import IconButton from 'components/button/IconButton';
import {
  IconCheckCircleSvg,
  IconCloseSvg,
  IconExclamationCircleSvg
} from 'svg';
import { Themeable } from 'themes';
import { message } from 'antd';

const Icon = styled.div`
  font-size: 24px;
  display: flex;
  margin-right: 12px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;

  ${IconButton} {
    margin-left: 12px;
    // TODO: rgba to hex
    color: rgba(255, 255, 255, 0.7);
    :hover {
      color: #ffffff;
    }
    :active {
      // TODO: rgba to hex
      color: rgba(255, 255, 255, 0.4);
    }
  }
`;

export interface MessageProps {
  type: 'info' | 'success' | 'error' | 'warning';
  messageKey?: React.Key;
  closable?: boolean;
  onClose?: () => void;
}

const Message: FunctionComponent<MessageProps & Partial<Themeable>> = ({
  type,
  closable,
  children,
  messageKey,
  onClose,
  theme = {}
}) => {
  const Svg =
    type === 'success' ? IconCheckCircleSvg : IconExclamationCircleSvg;

  const onCloseClick = () => {
    message.destroy(messageKey);
    if (onClose) {
      onClose();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Icon>
          <Svg />
        </Icon>
        {children}
        {(closable || onClose) && (
          <IconButton onClick={onCloseClick}>
            <IconCloseSvg />
          </IconButton>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default Message;
