// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useLayoutEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';

import Alert from 'components/Alert';
import Form from 'auth/components/form/Form';
import Input from 'auth/components/input/Input';
import Container from 'auth/components/Container';
import { Title, Paragraph } from 'auth/components/Typography';
import { actions, useDispatchUserContext } from 'auth/context';
import { useCreateUserProfile } from 'auth/hooks';
import { signOut } from 'auth/utils';
import Button from 'components/button/Button';
import SubmitButton from 'components/button/SubmitButton';

const { Item } = Form;

const Content = styled.div`
  max-width: 400px;
  margin: 0 auto;
`;

const Footer = styled.div`
  display: flex;
  ${Button} {
    flex: 1;
  }
  ${Button} + ${Button} {
    margin-left: 16px;
  }
`;

const CreateUserProfilePage: FunctionComponent = () => {
  const [createUserProfile, { loading, error, data }] = useCreateUserProfile();
  const dispatch = useDispatchUserContext();

  const onFinish = (values: any) => {
    const { organization, position, phone } = values;
    createUserProfile({ organization, position, phone });
  };

  useLayoutEffect(() => {
    if (data) {
      dispatch(actions.setUserProfile(data));
    }
  }, [data, dispatch]);

  const onCancelClick = () => signOut();

  return (
    <Container>
      <Content>
        <Title>
          <FormattedMessage id="auth.create.user.profile.view.title" />
        </Title>
        <Paragraph>
          <FormattedMessage id="auth.create.user.profile.view.instructions" />
        </Paragraph>
        <Alert error={error} />
        <Form
          onFinish={onFinish}
          initialValues={{
            organization: '',
            position: '',
            phone: ''
          }}
        >
          <Item
            name="organization"
            rules={[{ required: true }]}
            label={<FormattedMessage id="form.label.organization" />}
          >
            <Input />
          </Item>
          <Item
            name="position"
            rules={[{ required: true }]}
            label={<FormattedMessage id="form.label.position" />}
          >
            <Input />
          </Item>
          <Item
            name="phone"
            rules={[
              {
                pattern:
                  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
              }
            ]}
            label={<FormattedMessage id="form.label.phone.optional" />}
          >
            <Input />
          </Item>
          <Item noStyle>
            <Footer>
              <SubmitButton loading={loading}>
                <FormattedMessage id="button.done" />
              </SubmitButton>
              <Button onClick={onCancelClick}>
                <FormattedMessage id="button.cancel" />
              </Button>
            </Footer>
          </Item>
        </Form>
      </Content>
    </Container>
  );
};

export default CreateUserProfilePage;
