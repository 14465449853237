// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'antd';

import { PowerGridMode } from 'grid';
import IconButton from 'grid/components/toolbar/IconButton';
import UnsavedChangesDetector, {
  useDetectUnsavedChangesStore
} from 'grid/components/UnsavedChangesDetector';
import { usePowerGridContext, usePowerGridDispatch } from 'grid/context';
import { actions, PowerGridFeature } from 'grid/store';
import { IconConfigureSvg, IconOperateSvg } from 'svg';

const ToggleModeButton: FunctionComponent = () => {
  const { grid } = usePowerGridContext();
  const activateDetector = useDetectUnsavedChangesStore(
    state => state.activate
  );
  const { mode } = grid;
  const dispatch = usePowerGridDispatch();

  const activateFeature = () =>
    dispatch(actions.setActiveFeature(PowerGridFeature.MODE));

  const onClick = () => {
    if (mode === PowerGridMode.CONFIGURATION) {
      activateDetector(PowerGridFeature.MODE);
    } else {
      activateFeature();
    }
  };

  return mode === PowerGridMode.CONFIGURATION ? (
    <>
      <Tooltip
        mouseEnterDelay={1}
        title={<FormattedMessage id="button.switch.to.operation" />}
        placement="topLeft"
      >
        <IconButton onClick={onClick}>
          <IconOperateSvg />
        </IconButton>
      </Tooltip>
      <UnsavedChangesDetector feature={PowerGridFeature.MODE} />
    </>
  ) : (
    <Tooltip
      mouseEnterDelay={1}
      title={<FormattedMessage id="button.switch.to.configuration" />}
      placement="topLeft"
    >
      <IconButton onClick={onClick}>
        <IconConfigureSvg />
      </IconButton>
    </Tooltip>
  );
};

export default ToggleModeButton;
