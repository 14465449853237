// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components/macro';

import { PowerGrid } from 'grid';
import Alert from 'components/Alert';
import Button from 'components/button/Button';
import SubmitButton from 'components/button/SubmitButton';
import { FormValues } from 'components/form/Form';
import { Toolbar } from 'components/Layout';
import Modal, { ModalProps } from 'components/modal/Modal';
import PowerGridDetailsForm from 'grid/components/PowerGridDetailsForm';
import { useExportPowerGrid } from 'grid/hooks';
import { usePowerGridContext } from 'grid/context';

const Footer = styled(Toolbar)`
  justify-content: stretch;
  > * {
    flex: 1;
  }
`;

export interface ExportPowerGridModalProps extends ModalProps {
  onComplete: (grid: PowerGrid) => void;
}

const ExportPowerGridModal: FunctionComponent<ExportPowerGridModalProps> = ({
  onComplete,
  onCancel,
  ...otherProps
}) => {
  const intl = useIntl();
  const { grid } = usePowerGridContext();
  const [exportPowerGrid, { loading, error, data }] = useExportPowerGrid();

  const ref = useRef(onComplete);

  useEffect(() => {
    if (data) {
      const onComplete = ref.current;
      onComplete(data);
    }
  }, [data, ref]);

  if (ref.current !== onComplete) {
    ref.current = onComplete;
  }

  const onFinish = (values: FormValues) => {
    const { title, description } = values;
    exportPowerGrid({
      title,
      description,
      schema: grid.schema
    });
  };

  return (
    <Modal
      {...otherProps}
      title={<FormattedMessage id="modal.export.power.grid.title" />}
    >
      <Alert error={error} />
      <PowerGridDetailsForm
        requiredMark={false}
        initialValues={{
          title: intl.formatMessage(
            {
              id: 'text.power.grid.title.copy'
            },
            {
              title: grid.title
            }
          ),
          description: grid.description || ''
        }}
        onFinish={onFinish}
        footer={
          <Footer>
            <SubmitButton loading={loading}>
              <FormattedMessage id="button.done" />
            </SubmitButton>
            <Button type="secondary" onClick={onCancel}>
              <FormattedMessage id="button.cancel" />
            </Button>
          </Footer>
        }
      />
    </Modal>
  );
};

export default ExportPowerGridModal;
