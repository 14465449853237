// © ООО «Эдиспа», 2022

import styled from 'styled-components/macro';

export const Title = styled.h1`
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
`;

export const Paragraph = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  line-height: normal;
`;
