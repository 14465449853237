// © ООО «Эдиспа», 2022

import React, {
  ButtonHTMLAttributes,
  FunctionComponent,
  ReactNode
} from 'react';
import styled, { css } from 'styled-components/macro';

import style, { StyledProps } from 'themes';

const ButtonMixin = css`
  outline: none;
  border: none;
  font-weight: 500;
  position: relative;
  line-height: 100%;
  border-radius: 8px;
  padding: 0 48px;
  :enabled {
    cursor: pointer;
  }
  height: 40px;
  font-size: 14px;
  :disabled {
    opacity: 0.8;
  }
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const PrimaryButton = styled.button`
  ${ButtonMixin};
  color: ${style('button.primary.color')};
  background: ${style('button.primary.background')};
  :hover {
    color: ${style('button.primary.hover.color')};
    background: ${style('button.primary.hover.background')};
  }
  :active {
    color: ${style('button.primary.active.color')};
    background: ${style('button.primary.active.background')};
  }
`;

export const DefaultButton = styled.button`
  ${ButtonMixin};
  color: ${style('button.default.color')};
  background: ${style('button.default.background')};
  :hover {
    color: ${style('button.default.hover.color')};
    background: ${style('button.default.hover.background')};
  }
  :active {
    color: ${style('button.default.active.color')};
    background: ${style('button.default.active.background')};
  }
`;

export const SecondaryButton = styled.button`
  ${ButtonMixin};
  color: ${style('button.secondary.color')};
  background: ${style('button.secondary.background')};
  :hover {
    color: ${style('button.secondary.hover.color')};
    background: ${style('button.secondary.hover.background')};
  }
  :active {
    color: ${style('button.secondary.active.color')};
    background: ${style('button.secondary.active.background')};
  }
`;

export const DangerButton = styled.button`
  ${ButtonMixin};
  color: ${style('button.danger.color')};
  background: ${style('button.danger.background')};
  :hover {
    color: ${style('button.danger.hover.color')};
    background: ${style('button.danger.hover.background')};
  }
  :active {
    color: ${style('button.danger.active.color')};
    background: ${style('button.danger.active.background')};
  }
`;

interface OwnButtonProps {
  type?: 'primary' | 'default' | 'secondary' | 'danger';
  htmlType?: 'submit' | 'reset' | 'button';
  icon?: ReactNode;
}

export type ButtonProps = StyledProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'type'> &
  OwnButtonProps;

const Icon = styled.div`
  font-size: 24px;
  position: absolute;
  top: 8px;
  left: 12px;
`;

const Button: FunctionComponent<ButtonProps> = ({
  type = 'default',
  htmlType = 'button',
  icon,
  children,
  ...otherProps
}) => {
  let Component;
  if (type === 'primary') {
    Component = PrimaryButton;
  } else if (type === 'secondary') {
    Component = SecondaryButton;
  } else if (type === 'danger') {
    Component = DangerButton;
  } else {
    Component = DefaultButton;
  }
  return (
    <Component type={htmlType} {...otherProps}>
      {icon ? <Icon>{icon}</Icon> : null}
      {children}
    </Component>
  );
};

const StyledButton = styled(Button)``;

export default StyledButton;
