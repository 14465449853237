// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useEffect, useMemo, useRef } from 'react';
import { message } from 'antd';

import LoadingMask from 'components/loading/LoadingMask';
import Message from 'components/message/Message';
import { PowerGrid, Schema } from 'grid';
import { usePowerGridContext, usePowerGridDispatch } from 'grid/context';
import { useUpdateSchema } from 'grid/hooks';
import { actions } from 'grid/store';
import { useFormatMessage } from 'utils/format';

const SaveSchemaFeature: FunctionComponent = () => {
  const formatMessage = useFormatMessage();
  const { grid } = usePowerGridContext();
  const ref = useRef(grid);
  const dispatch = usePowerGridDispatch();

  const [update, state] = useUpdateSchema();

  useMemo(() => {
    const { id, schema } = ref.current;
    message.destroy();
    update({ id, schema });
  }, [ref, update]);

  useEffect(() => {
    const { error, data } = state;
    if (error) {
      message.error({
        duration: 0,
        content: (
          <Message type="error">
            {formatMessage('message.update.schema.failure')}
          </Message>
        )
      });
      console.error(error);
    } else if (data) {
      const { status, details } = data;
      if (status === 'success') {
        message.info({
          content: (
            <Message type="info">
              {formatMessage('message.update.schema.success')}
            </Message>
          )
        });
        dispatch(actions.setPowerGrid(details as PowerGrid));
      } else if (status === 'failure') {
        message.error({
          duration: 0,
          content: (
            <Message type="error" closable>
              {formatMessage('message.update.schema.validation.failure')}
            </Message>
          )
        });
        dispatch(actions.updateSchema(details as Schema));
      }
    }
    if (error || data) {
      dispatch(actions.setActiveFeature());
    }
  }, [state, dispatch]);

  return <>{state.loading && <LoadingMask />}</>;
};

export default SaveSchemaFeature;
