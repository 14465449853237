// © ООО «Эдиспа», 2022

import React, { PureComponent } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import styled from 'styled-components/macro';

const Container = styled.div`
  position: fixed;
  background: rgba(30, 5, 45, 0.4);
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
`;

export default class Mask extends PureComponent {
  mask = React.createRef<HTMLDivElement>();

  onKeyDown(event: React.KeyboardEvent<HTMLDivElement> | KeyboardEvent) {
    event.preventDefault();
    return false;
  }

  componentDidMount() {
    disableBodyScroll(this.mask.current as HTMLElement);
    window.addEventListener('keydown', this.onKeyDown, true);
  }

  componentWillUnmount() {
    enableBodyScroll(this.mask.current as HTMLElement);
    window.removeEventListener('keydown', this.onKeyDown, true);
  }

  render() {
    const { children } = this.props;
    return (
      <Container ref={this.mask} onKeyDown={this.onKeyDown}>
        {children}
      </Container>
    );
  }
}
