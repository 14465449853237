// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';

import Modal, { Footer, ModalProps } from 'components/modal/Modal';
import { Markdown } from 'auth/components/Typography';
import Button from 'components/button/Button';
import SubmitButton from 'components/button/SubmitButton';
import MarkdownMessage from 'components/i18n/MarkdownMessage';

type TroubleshootEmailModalProps = Omit<
  ModalProps,
  'closable' | 'footer' | 'destroyOnClose'
> & {
  loading: boolean;
  onSubmit: () => void;
};

const TroubleshootEmailModal: FunctionComponent<TroubleshootEmailModalProps> =
  ({ onSubmit, onCancel, loading, ...otherProps }) => {
    return (
      <Modal
        {...otherProps}
        width={560}
        title={<FormattedMessage id="auth.troubleshoot.email.modal.title" />}
        footer={
          <Footer>
            <SubmitButton loading={loading} onClick={onSubmit}>
              <FormattedMessage id="button.send.again" />
            </SubmitButton>
            <Button type="secondary" onClick={onCancel}>
              <FormattedMessage id="button.close" />
            </Button>
          </Footer>
        }
      >
        <MarkdownMessage id="auth.troubleshoot.email.modal.instructions" />
      </Modal>
    );
  };

export default styled(TroubleshootEmailModal)`
  ${Markdown} {
    font-weight: 400;
  }
`;
