// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components/macro';

import Container from 'auth/components/Container';
import { Title, Paragraph } from 'auth/components/Typography';
import { signOut } from 'auth/utils';
import Button from 'components/button/Button';
import { AuthUserFailureSvg } from 'svg';

const Content = styled.div`
  max-width: 640px;
  margin: 0 auto;
  ${Title} {
    margin-top: 24px;
    margin-bottom: 32px;
  }
  ${Paragraph} {
    margin-bottom: 32px;
  }
`;

const DisabledUserPage: FunctionComponent = () => {
  const intl = useIntl();

  const onClick = () => signOut();

  return (
    <Container>
      <Content>
        <AuthUserFailureSvg />
        <Title>
          <FormattedMessage id="auth.disabled.user.view.title" />
        </Title>
        <Paragraph>
          {intl.formatMessage({
            id: 'auth.disabled.user.view.instructions'
          })}
        </Paragraph>
        <Button type="primary" onClick={onClick}>
          <FormattedMessage id="button.go.to.sign.in" />
        </Button>
      </Content>
    </Container>
  );
};

export default DisabledUserPage;
