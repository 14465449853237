// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useReducer } from 'react';

import { Context, DispatchContext } from 'auth/ui/context';
import { reducer, ViewType } from 'auth/ui/store';

const Provider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    view: ViewType.LOGIN
  });

  return (
    <Context.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </Context.Provider>
  );
};

export default Provider;
