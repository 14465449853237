// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'antd';

import IconButton from 'grid/components/toolbar/IconButton';
import UnsavedChangesDetector, {
  useDetectUnsavedChangesStore
} from 'grid/components/UnsavedChangesDetector';
import { PowerGridFeature } from 'grid/store';
import { IconCloneSvg } from 'svg';

const ClonePowerGridButton: FunctionComponent = () => {
  const activateDetector = useDetectUnsavedChangesStore(
    state => state.activate
  );

  const onClick = () => activateDetector(PowerGridFeature.CLONE);

  return (
    <>
      <Tooltip
        mouseEnterDelay={1}
        title={<FormattedMessage id="button.clone" />}
      >
        <IconButton onClick={onClick}>
          <IconCloneSvg />
        </IconButton>
      </Tooltip>
      <UnsavedChangesDetector feature={PowerGridFeature.CLONE} />
    </>
  );
};

export default ClonePowerGridButton;
