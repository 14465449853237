// © ООО «Эдиспа», 2022

import React, { Dispatch } from 'react';

import { Action, State } from 'auth/action/store';

export const Context = React.createContext<State | undefined>(undefined);

export const useContext = (): State => {
  const context = React.useContext(Context);
  if (context === undefined) {
    throw new Error(
      'useContext хук должен быть использован в контексте провайдера'
    );
  }
  return context;
};

export const DispatchContext = React.createContext<
  Dispatch<Action> | undefined
>(undefined);

export const useDispatch = (): Dispatch<Action> => {
  const context = React.useContext(DispatchContext);
  if (context === undefined) {
    throw new Error(
      'useDispatch хук должен быть использован в контексте провайдера'
    );
  }
  return context;
};
