// © ООО «Эдиспа», 2022

import styled from 'styled-components/macro';

import LinkButton from 'components/button/LinkButton';
import style from 'themes';

const StyledLinkButton = styled(LinkButton)`
  color: ${style('link.color')};
  font-weight: 300;
  :hover {
    color: ${style('link.hover.color')};
    text-decoration: none;
  }
`;

export default StyledLinkButton;
