// © ООО «Эдиспа», 2022

import { initializeApp } from 'firebase/app';

import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

const configureFirebase = async (): Promise<void> => {
  const response = await fetch('/__/firebase/init.json');
  const config = await response.json();
  initializeApp(config);
};

export default configureFirebase;
