// © ООО «Эдиспа», 2022

import React, { CSSProperties, FunctionComponent, memo } from 'react';
import {
  getMarkerEnd,
  EdgeProps as ReactFlowEdgeProps
} from 'react-flow-renderer';
import styled, { css } from 'styled-components/macro';

import { DenormalizedEdge, FlowDirection } from 'grid';
import {
  getNodeCustomColor,
  getFlowDirection,
  isTransformer
} from 'grid/utils';
import style from 'themes';

interface TextProps {
  invalid: boolean;
  selected: boolean;
}

const Text = styled.text<TextProps>`
  cursor: pointer;
  ${props => {
    if (props.selected) {
      return css`
        fill: ${style('graph.selected.color')};
      `;
    } else if (props.invalid) {
      return css`
        fill: ${style('graph.error.color')};
        font-weight: bold;
      `;
    }
  }}
`;

const TextPath = styled.textPath.attrs({
  startOffset: '50%',
  textAnchor: 'middle'
})`
  font-size: 12px;
`;

interface EdgeProps {
  selected: boolean;
  invalid: boolean;
  disabled?: boolean;
  customColor?: string;
}

const StyleMixin = css<EdgeProps>`
  ${props => {
    if (props.selected) {
      return css`
        stroke: ${style('graph.selected.color')};
      `;
    } else if (props.invalid) {
      return css`
        stroke: ${style('graph.error.color')};
        stroke-width: 2;
      `;
    } else if (props.customColor) {
      return css`
        stroke: ${props.customColor};
      `;
    } else {
      return css`
        stroke: ${style('graph.color')};
      `;
    }
  }}

  ${props =>
    props.disabled &&
    css`
      stroke-dasharray: 5;
    `}
:hover {
    cursor: pointer;
  }
  fill: none;
`;

const Path = styled.path<EdgeProps>`
  ${StyleMixin};
`;

const Circle = styled.circle<EdgeProps>`
  ${StyleMixin};
`;

const Selector = styled.path`
  fill: none;
  stroke: transparent;
  stroke-width: 8;

  :hover {
    cursor: pointer;
    & + ${Path} {
      cursor: pointer;
    }
  }
`;

const createScale = (
  sourceX: number,
  targetX: number,
  sourceY: number,
  targetY: number
) => {
  const angle = Math.atan2(targetY - sourceY, targetX - sourceX);
  return (value: number) => {
    return {
      x: sourceX + Math.cos(angle) * value,
      y: sourceY + Math.sin(angle) * value
    };
  };
};

interface AnimatedProps {
  attribute: 'stroke' | 'fill';
}

const Animated: React.FC<AnimatedProps> = ({ attribute }) => (
  <animate
    attributeType="XML"
    attributeName={attribute}
    values="#FF0909;#FFDADA;#FFDADA;#FF0909;"
    dur="1s"
    repeatCount="indefinite"
  />
);

const FlowEdge: FunctionComponent<ReactFlowEdgeProps<DenormalizedEdge>> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  label,
  style,
  arrowHeadType,
  markerEndId,
  data,
  selected
}) => {
  const edge = data as DenormalizedEdge;
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

  const shouldRenderTransformer = isTransformer(edge);
  const flowDirection = getFlowDirection(edge);

  const shouldRotateLabel = targetX < sourceX;
  let arrow: string | undefined;
  if (flowDirection === FlowDirection.FORWARD) {
    arrow = shouldRotateLabel ? '←' : '→';
  } else if (flowDirection === FlowDirection.BACKWARD) {
    arrow = shouldRotateLabel ? '→' : '←';
  } else {
    arrow = undefined;
  }

  const sourceCustomColor = getNodeCustomColor(edge.source);
  const targetCustomColor = getNodeCustomColor(edge.target);

  const showInvalid = !!edge.messages && !selected;

  return (
    <>
      <Selector id={id} d={`M ${sourceX},${sourceY}L ${targetX},${targetY}`} />
      {shouldRenderTransformer ? (
        <Transformer
          sourceX={sourceX}
          sourceY={sourceY}
          sourceCustomColor={sourceCustomColor}
          targetCustomColor={targetCustomColor}
          targetX={targetX}
          targetY={targetY}
          disabled={!!edge.disabled}
          invalid={showInvalid}
          selected={!!selected}
          markerEnd={markerEnd}
          style={style}
        />
      ) : (
        <Path
          selected={!!selected}
          invalid={!!edge.messages}
          disabled={edge.disabled}
          style={style}
          className="react-flow__edge-path"
          d={`M ${sourceX},${sourceY}L ${targetX},${targetY}`}
          markerEnd={markerEnd}
          customColor={
            sourceCustomColor === targetCustomColor
              ? sourceCustomColor
              : undefined
          }
        >
          {showInvalid && <Animated attribute="stroke" />}
        </Path>
      )}
      {label && (
        <Text
          dy={shouldRenderTransformer ? -17 : -4}
          transform={
            targetX < sourceX
              ? `rotate(180, ${sourceX + (targetX - sourceX) / 2}, ${
                  sourceY + (targetY - sourceY) / 2
                })`
              : undefined
          }
          selected={!!selected}
          invalid={showInvalid}
        >
          <TextPath href={`#${id}`}>
            {arrow}
            {label}
          </TextPath>
        </Text>
      )}
    </>
  );
};

interface TransformerProps {
  sourceX: number;
  targetX: number;
  sourceY: number;
  targetY: number;
  sourceCustomColor?: string;
  targetCustomColor?: string;
  selected: boolean;
  invalid: boolean;
  disabled: boolean;
  markerEnd: string;
  style?: CSSProperties;
}

interface Point {
  x: number;
  y: number;
}

type LineSegment = {
  type: 'line';
  start: Point;
  end: Point;
  hasMarker?: boolean;
  customColor?: string;
};

type CircleSegment = {
  type: 'circle';
  center: Point;
  radius: number;
  customColor?: string;
};

type Segment = LineSegment | CircleSegment;

const Transformer: FunctionComponent<TransformerProps> = ({
  sourceX,
  targetX,
  sourceY,
  targetY,
  sourceCustomColor,
  targetCustomColor,
  selected,
  invalid,
  disabled,
  markerEnd,
  style
}) => {
  const scale = createScale(sourceX, targetX, sourceY, targetY);
  const length = ((targetX - sourceX) ** 2 + (targetY - sourceY) ** 2) ** 0.5;

  const showInvalid = invalid && !selected;

  const segments: Segment[] = [
    {
      start: {
        x: sourceX,
        y: sourceY
      },
      end: scale(length / 2 - 19),
      type: 'line',
      customColor: sourceCustomColor
    },
    {
      type: 'circle',
      center: scale(length / 2 - 7),
      radius: 12,
      customColor: sourceCustomColor
    },
    {
      type: 'circle',
      center: scale(length / 2 + 7),
      radius: 12,
      customColor: targetCustomColor
    },
    {
      start: scale(length / 2 + 19),
      end: {
        x: targetX,
        y: targetY
      },
      type: 'line',
      hasMarker: true,
      customColor: targetCustomColor
    }
  ];

  return (
    <g>
      {segments.map((segment, index) => {
        const { type } = segment;
        if (type === 'line') {
          const { start, end, hasMarker, customColor } = segment;
          return (
            <Path
              key={index}
              selected={selected}
              invalid={invalid}
              disabled={disabled}
              style={style}
              className="react-flow__edge-path"
              d={`M ${start.x},${start.y}L ${end.x},${end.y}`}
              markerEnd={hasMarker ? markerEnd : undefined}
              customColor={customColor}
            >
              {showInvalid && <Animated attribute="stroke" />}
            </Path>
          );
        } else {
          const { center, radius, customColor } = segment;
          return (
            <Circle
              key={index}
              cx={center.x}
              cy={center.y}
              r={radius}
              style={style}
              className="react-flow__edge-path"
              selected={selected}
              invalid={invalid}
              disabled={disabled}
              customColor={customColor}
            >
              {showInvalid && <Animated attribute="stroke" />}
            </Circle>
          );
        }
      })}
    </g>
  );
};

export default memo(FlowEdge);
