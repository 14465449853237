// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormItemProps } from 'antd/lib/form';
import { isNil, omit, omitBy } from 'lodash';
import { v4 } from 'uuid';

import Form, { FormProps, Row } from 'components/form/Form';
import Label from 'components/form/Label';
import { useRules } from 'components/form/rules';
import Input from 'components/input/Input';
import InputNumber from 'components/input/InputNumber';
import { Spacer } from 'components/Layout';
import Select, { Option } from 'components/select/Select';
import { Node, NodeType, PowerGridMode } from 'grid';
import { usePowerGridContext } from 'grid/context';

const { Item } = Form;

export interface NodeDetailsFormProps {
  /**
   * узел
   */
  node?: Node;
  /**
   * функция обратного вызова для сохранения свойств узла
   */
  onSubmit: (node: Node) => void;
  /**
   * футер
   */
  footer: FormItemProps['children'];
}

const NodeDetailsForm: FunctionComponent<NodeDetailsFormProps & FormProps> = ({
  node,
  onSubmit,
  footer,
  ...otherProps
}) => {
  const [nodeType, setNodeType] = useState(node ? node.type : undefined);
  const initialValues = node && omit(node, 'messages');
  const rules = useRules();
  const intl = useIntl();
  const [form] = Form.useForm();
  const { grid } = usePowerGridContext();
  const { mode } = grid;

  const onFinish = (values: any) => {
    const node = omitBy(
      initialValues
        ? {
            ...initialValues,
            ...values
          }
        : {
            ...values,
            id: v4()
          },
      isNil
    );
    onSubmit(node as Node);
  };

  const onValuesChange = (changedValues: any) => {
    if (changedValues.hasOwnProperty('type')) {
      const { type } = changedValues;
      if (type === NodeType.LOAD) {
        form.setFields(
          ['uGiv', 'pMin', 'pMax', 'pGen', 'qMin', 'qMax', 'qGen'].map(
            name => ({
              name,
              touched: false,
              value: undefined,
              errors: []
            })
          )
        );
      }
      setNodeType(type || undefined);
    }
  };

  const isLoadNode = nodeType === NodeType.LOAD;

  const items = {
    label: (
      <Item
        name="label"
        label={<Label value={<FormattedMessage id="node.prop.label" />} />}
        rules={[{ required: true }, rules.string]}
      >
        <Input />
      </Item>
    ),
    type: (
      <Item
        name="type"
        label={<Label value={<FormattedMessage id="node.prop.type" />} />}
        rules={[{ required: true }]}
      >
        <Select>
          <Option value="">{intl.formatMessage({ id: 'label.empty' })}</Option>
          <Option value={NodeType.GENERATION}>
            {intl.formatMessage({ id: 'node.type.generation' })}
          </Option>
          <Option value={NodeType.LOAD}>
            {intl.formatMessage({ id: 'node.type.load' })}
          </Option>
          <Option value={NodeType.BALANCE}>
            {intl.formatMessage({ id: 'node.type.balance' })}
          </Option>
        </Select>
      </Item>
    ),
    uNom: (
      <Item
        name="uNom"
        label={
          <Label
            value={<FormattedMessage id="node.prop.u.nom.label" />}
            info={<FormattedMessage id="node.prop.u.nom.description" />}
          />
        }
        rules={[{ required: true }, rules.number, rules.greater()]}
      >
        <InputNumber />
      </Item>
    ),
    uGiv: (
      <Item
        name="uGiv"
        label={
          <Label
            value={<FormattedMessage id="node.prop.u.giv.label" />}
            info={<FormattedMessage id="node.prop.u.giv.description" />}
          />
        }
        rules={[
          rules.requiredBy({ name: 'type', value: NodeType.GENERATION }),
          rules.requiredBy({ name: 'type', value: NodeType.BALANCE }),
          rules.number,
          rules.greater()
        ]}
      >
        <InputNumber disabled={isLoadNode} />
      </Item>
    ),
    pNom: (
      <Item
        name="pNom"
        label={
          <Label
            value={<FormattedMessage id="node.prop.p.nom.label" />}
            info={<FormattedMessage id="node.prop.p.nom.description" />}
          />
        }
        dependencies={['qNom']}
        rules={[rules.number, rules.min(), rules.requiredBy('qNom')]}
      >
        <InputNumber />
      </Item>
    ),
    qNom: (
      <Item
        name="qNom"
        label={
          <Label
            value={<FormattedMessage id="node.prop.q.nom.label" />}
            info={<FormattedMessage id="node.prop.q.nom.description" />}
          />
        }
        dependencies={['pNom']}
        rules={[rules.number, rules.min(), rules.requiredBy('pNom')]}
      >
        <InputNumber />
      </Item>
    ),
    pGen: (
      <Item
        name="pGen"
        label={
          <Label
            value={<FormattedMessage id="node.prop.p.gen.label" />}
            info={<FormattedMessage id="node.prop.p.gen.description" />}
          />
        }
        dependencies={['pMin', 'pMax']}
        rules={[
          rules.requiredBy({ name: 'type', value: NodeType.GENERATION }),
          rules.number,
          rules.range('pMin', 'pMax')
        ]}
      >
        <InputNumber disabled={isLoadNode} />
      </Item>
    ),
    qGen: (
      <Item
        name="qGen"
        label={
          <Label
            value={<FormattedMessage id="node.prop.q.gen.label" />}
            info={<FormattedMessage id="node.prop.q.gen.description" />}
          />
        }
        dependencies={['qMin', 'qMax']}
        rules={[
          rules.requiredBy({ name: 'type', value: NodeType.GENERATION }),
          rules.number,
          rules.range('qMin', 'qMax')
        ]}
      >
        <InputNumber disabled={isLoadNode} />
      </Item>
    ),
    pMin: (
      <Item
        name="pMin"
        label={
          <Label
            value={<FormattedMessage id="node.prop.p.min.label" />}
            info={<FormattedMessage id="node.prop.p.min.description" />}
          />
        }
        dependencies={['pMax']}
        rules={[
          rules.requiredBy(
            { name: 'type', value: NodeType.GENERATION },
            'pMax'
          ),
          rules.number,
          rules.max('pMax')
        ]}
      >
        <InputNumber disabled={isLoadNode} />
      </Item>
    ),
    pMax: (
      <Item
        name="pMax"
        label={
          <Label
            value={<FormattedMessage id="node.prop.p.max.label" />}
            info={<FormattedMessage id="node.prop.p.max.description" />}
          />
        }
        dependencies={['pMin']}
        rules={[
          rules.requiredBy(
            { name: 'type', value: NodeType.GENERATION },
            'pMin'
          ),
          rules.number,
          rules.min('pMin')
        ]}
      >
        <InputNumber disabled={isLoadNode} />
      </Item>
    ),
    qMin: (
      <Item
        name="qMin"
        label={
          <Label
            value={<FormattedMessage id="node.prop.q.min.label" />}
            info={<FormattedMessage id="node.prop.q.min.description" />}
          />
        }
        dependencies={['qMax']}
        rules={[
          rules.requiredBy(
            { name: 'type', value: NodeType.GENERATION },
            'qMax'
          ),
          rules.number,
          rules.max('qMax')
        ]}
      >
        <InputNumber disabled={isLoadNode} />
      </Item>
    ),
    qMax: (
      <Item
        name="qMax"
        label={
          <Label
            value={<FormattedMessage id="node.prop.q.max.label" />}
            info={<FormattedMessage id="node.prop.q.max.description" />}
          />
        }
        dependencies={['qMin']}
        rules={[
          rules.requiredBy(
            { name: 'type', value: NodeType.GENERATION },
            'qMin'
          ),
          rules.number,
          rules.min('qMin')
        ]}
      >
        <InputNumber disabled={isLoadNode} />
      </Item>
    ),
    gSh: (
      <Item
        name="gSh"
        label={
          <Label
            value={<FormattedMessage id="node.prop.g.sh.label" />}
            info={<FormattedMessage id="node.prop.g.sh.description" />}
          />
        }
        rules={[rules.number]}
      >
        <InputNumber />
      </Item>
    ),
    bSh: (
      <Item
        name="bSh"
        label={
          <Label
            value={<FormattedMessage id="node.prop.b.sh.label" />}
            info={<FormattedMessage id="node.prop.b.sh.description" />}
          />
        }
        rules={[rules.number]}
      >
        <InputNumber />
      </Item>
    )
  };

  const layout =
    mode === PowerGridMode.CONFIGURATION ? (
      <>
        {items.label}
        {items.type}
        <Row>
          {items.uNom}
          {items.uGiv}
        </Row>
        <Row>
          {items.pNom}
          {items.qNom}
        </Row>
        <Row>
          {items.pGen}
          {items.qGen}
        </Row>
        <Row>
          {items.pMin}
          {items.pMax}
        </Row>
        <Row>
          {items.qMin}
          {items.qMax}
        </Row>
        <Row>
          {items.gSh}
          {items.bSh}
        </Row>
      </>
    ) : (
      <>
        <Row>
          {items.pNom}
          {items.qNom}
        </Row>
        <Row>
          {items.pGen}
          {items.qGen}
        </Row>
        <Row>
          {items.pMin}
          {items.pMax}
        </Row>
        <Row>
          {items.qMin}
          {items.qMax}
        </Row>
        <Row>
          {items.uGiv}
          <Spacer />
        </Row>
      </>
    );

  return (
    <Form
      form={form}
      requiredMark={false}
      layout="vertical"
      onFinish={onFinish}
      onValuesChange={onValuesChange}
      initialValues={initialValues}
      {...otherProps}
    >
      {layout}
      {footer && <Item noStyle>{footer}</Item>}
    </Form>
  );
};

export default NodeDetailsForm;
