// © ООО «Эдиспа», 2022

import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  table: {
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    borderStyle: 'solid',
    borderColor: '#F3F3F4',
    borderWidth: 1,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    color: '#A298A8'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderStyle: 'solid',
    borderColor: '#F3F3F4',
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1
  },
  cell: {
    margin: 8
  },
  lastRow: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8
  }
});
