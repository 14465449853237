// © ООО «Эдиспа», 2022

import { Reducer } from 'react';
import { User } from 'firebase/auth';

import { AbstractAction, PayloadAction } from 'utils/reducer';

export enum ViewType {
  LOGIN = 'AUTH_VIEW/LOGIN',
  SIGN_UP = 'AUTH_VIEW/SIGN_UP',
  EMAIL_VERIFICATION = 'AUTH_VIEW/EMAIL_VERIFICATION',
  PASSWORD_RESET = 'AUTH_VIEW/PASSWORD_RESET',
  PASSWORD_RESET_CONFIRMATION = 'AUTH_VIEW/PASSWORD_RESET_CONFIRMATION'
}

enum ActionType {
  SET_VIEW = 'AUTH/SET_VIEW',
  SET_CONTEXT = 'AUTH/SET_CONTEXT',
  VERIFY_EMAIL = 'AUTH/VERIFY_EMAIL',
  RESET_PASSWORD = 'AUTH/RESET_PASSWORD',
  CONFIRM_PASSWORD_RESET = 'AUTH/CONFIRM_PASSWORD_RESET',
  RESET = 'AUTH/RESET'
}

type SetViewAction = PayloadAction<ActionType.SET_VIEW, ViewType>;

type SetContextAction = PayloadAction<ActionType.SET_CONTEXT, State>;

type VerifyEmailAction = PayloadAction<ActionType.VERIFY_EMAIL, User>;

type ConfirmPasswordResetAction = PayloadAction<
  ActionType.CONFIRM_PASSWORD_RESET,
  string
>;

type ResetAction = AbstractAction<ActionType.RESET>;

export type Action =
  | SetViewAction
  | SetContextAction
  | VerifyEmailAction
  | ConfirmPasswordResetAction
  | ResetAction;

export interface State {
  email?: string;
  user?: User;
  view: ViewType;
}

export const reducer: Reducer<State, Action> = (
  state: State,
  action: Action
): State => {
  switch (action.type) {
    case ActionType.SET_VIEW: {
      return {
        view: action.payload
      };
    }
    case ActionType.VERIFY_EMAIL: {
      return {
        user: action.payload,
        view: ViewType.EMAIL_VERIFICATION
      };
    }
    case ActionType.CONFIRM_PASSWORD_RESET: {
      return {
        email: action.payload,
        view: ViewType.PASSWORD_RESET_CONFIRMATION
      };
    }
    case ActionType.SET_CONTEXT: {
      return action.payload;
    }
    case ActionType.RESET: {
      const { email } = state;
      return {
        email,
        view: ViewType.LOGIN
      };
    }
    default: {
      return state;
    }
  }
};

export const actions = {
  setView: (payload: ViewType): Action => ({
    type: ActionType.SET_VIEW,
    payload
  }),
  setContext: (payload: State): Action => ({
    type: ActionType.SET_CONTEXT,
    payload
  }),
  verifyEmail: (payload: User): Action => ({
    type: ActionType.VERIFY_EMAIL,
    payload
  }),
  confirmPasswordReset: (payload: string): Action => ({
    type: ActionType.CONFIRM_PASSWORD_RESET,
    payload
  }),
  reset: (): Action => ({
    type: ActionType.RESET
  })
};
