// © ООО «Эдиспа», 2022

import React, { AnchorHTMLAttributes, FunctionComponent } from 'react';
import styled, { css } from 'styled-components/macro';

import style, { StyledProps } from 'themes';

const AnchorMixin = css`
  outline: none;
  border: none;
  display: inline-block;
  font-weight: 500;
  position: relative;
  line-height: 40px;
  border-radius: 8px;
  padding: 0 48px;
  :enabled {
    cursor: pointer;
  }
  font-size: 14px;
  :disabled {
    opacity: 0.8;
  }
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const PrimaryLink = styled.a`
  ${AnchorMixin};
  color: ${style('button.primary.color')};
  background: ${style('button.primary.background')};
  :hover {
    color: ${style('button.primary.hover.color')};
    background: ${style('button.primary.hover.background')};
  }
  :active {
    color: ${style('button.primary.active.color')};
    background: ${style('button.primary.active.background')};
  }
`;

export const DefaultLink = styled.a``;

interface OwnButtonProps {
  type?: 'primary' | 'default';
  htmlType?: AnchorHTMLAttributes<HTMLAnchorElement>['type'];
}

export type LinkProps = StyledProps &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'type'> &
  OwnButtonProps;

const Link: FunctionComponent<LinkProps> = ({
  type,
  htmlType,
  children,
  ...otherProps
}) => {
  let Component;
  if (type === 'primary') {
    Component = PrimaryLink;
  } else {
    Component = DefaultLink;
  }
  return (
    <Component type={htmlType} {...otherProps}>
      {children}
    </Component>
  );
};

export default Link;
