// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'antd';
import { FormProps as AntFormProps } from 'antd/lib/form';
import styled from 'styled-components/macro';

import style from 'themes';

const StyledForm = styled(Form)`
  .ant-form-item-has-error .ant-form-item-explain {
    color: ${style('form.error.color')};
  }
  .ant-form-item-has-error input {
    border-color: ${style('form.error.color')};
  }
  .ant-form-item {
    margin-bottom: 24px;
    .ant-form-item-label {
      label {
        display: flex;
        /*
          При наведении курсора мышки на область лэйбла границы инпута подсвечиваются.
          Чтобы избежать этого, блокируем все события с указателем.
        */
        pointer-events: none;
      }
    }
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  > * + * {
    margin-left: 16px;
  }
  > * {
    flex: 1;
  }
`;

export type FormProps = AntFormProps;

export type FormValues = { [key: string]: any };

type IEdispaForm<T> = FunctionComponent<T> & {
  Item: typeof Form.Item;
  useForm: typeof Form.useForm;
};

const FormAdapter: IEdispaForm<FormProps> = props => {
  const intl = useIntl();
  const validateMessages = useMemo(
    () => ({
      required: intl.formatMessage({
        id: 'form.validation.required'
      }),
      string: {
        min: intl.formatMessage({
          id: 'form.validation.min.length'
        }),
        max: intl.formatMessage({
          id: 'form.validation.max.length'
        })
      },
      pattern: {
        mismatch: intl.formatMessage({
          id: 'form.validation.pattern'
        })
      },
      types: {
        email: intl.formatMessage({
          id: 'form.validation.email'
        })
      }
    }),
    [intl]
  );
  return <StyledForm validateMessages={validateMessages} {...props} />;
};

FormAdapter.Item = Form.Item;
FormAdapter.useForm = Form.useForm;

export default FormAdapter;
