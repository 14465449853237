// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useMemo } from 'react';
import { usePDF } from '@react-pdf/renderer';
import { FormattedMessage } from 'react-intl';
import { Table } from 'antd';
import { DateTime } from 'luxon';
import slug from 'slug';
import styled from 'styled-components/macro';

import Button from 'components/button/Button';
import { Toolbar } from 'components/Layout';
import Link from 'components/link/Link';
import Modal, { ModalProps } from 'components/modal/Modal';
import {
  getActionTypeLabel,
  EmergencyLiquidationReport,
  buildDeviations
} from 'emergency/report';
import { usePowerGridContext, usePowerGridDispatch } from 'grid/context';
import { actions } from 'grid/store';
import EmergencyLiquidationReportDocument from 'emergency/components/EmergencyLiquidationReportDocument';
import { useFormatMessage } from 'utils/format';

const ROW_HEIGHT = 39;
const MAX_VISIBLE_ROW_COUNT = 8;
const SCROLL_HEIGHT = ROW_HEIGHT * MAX_VISIBLE_ROW_COUNT;

const Container = styled.div`
  display: block;
  > * {
    margin-bottom: 24px;
  }
`;

const SubTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
`;

const StyledTable = styled(Table)`
  border: 1px solid #f3f3f4;
  border-radius: 8px;
  overflow: hidden;

  .ant-table-tbody > tr:last-child > td {
    border: unset;
  }
  .ant-table-small .ant-table-thead > tr > th {
    background-color: #fff;
    :hover {
      background: #fafafa;
    }
  }
`;

export interface LiquidationReportModalProps {
  report: Pick<
    EmergencyLiquidationReport,
    'generators' | 'edges' | 'phases' | 'summary' | 'date'
  >;
}

const EmergencyLiquidationReportModal: FunctionComponent<
  LiquidationReportModalProps & ModalProps
> = ({ report: partialReport, ...otherProps }) => {
  const formatMessage = useFormatMessage();
  const { grid, original } = usePowerGridContext();

  const report = useMemo(() => {
    return {
      ...partialReport,
      title: grid.title,
      deviations: buildDeviations(original.schema, grid.schema)
    };
  }, [original.schema, grid.schema]);

  const { title } = grid;
  const { date } = report;
  const fileName = useMemo(() => {
    const titleFragment = slug(title).slice(0, 30);
    const dateFragment = DateTime.fromJSDate(report.date).toFormat(
      'ddLLyyyyHHmmss'
    );
    return slug(`ликвидация-аварии-${titleFragment}-${dateFragment}`);
  }, [title, date]);

  const [instance] = usePDF({
    document: (
      <EmergencyLiquidationReportDocument
        report={report}
        formatMessage={formatMessage}
      />
    )
  });

  const dispatch = usePowerGridDispatch();

  const onCloseClick = () => dispatch(actions.setActiveFeature());

  const { summary, phases } = report;

  const hasEmergencyResponse = phases.some(phase => phase.actions.length > 0);

  return (
    <Modal
      {...otherProps}
      width={850}
      title={<FormattedMessage id="title.summary.report" />}
      footer={
        <Toolbar>
          {instance.url && (
            <Link type="primary" href={instance.url} download={fileName}>
              <FormattedMessage id="button.download.report" />
            </Link>
          )}
          <Button type="secondary" onClick={onCloseClick}>
            <FormattedMessage id="button.close" />
          </Button>
        </Toolbar>
      }
    >
      {instance.loading && 'Формируется отчет'}
      {instance.error && 'Ошибка формирования отчета!'}
      {instance.url && (
        <Container>
          {hasEmergencyResponse ? (
            <>
              <SubTitle>
                <FormattedMessage id="title.emergency.response" />
              </SubTitle>
              <StyledTable
                dataSource={summary.actions.map((action, index) => ({
                  ...action,
                  key: index
                }))}
                size="small"
                pagination={false}
                scroll={{ y: SCROLL_HEIGHT }}
                style={{
                  overflow: 'auto'
                }}
                columns={[
                  {
                    dataIndex: 'label',
                    title: <FormattedMessage id="label.name" />
                  },
                  {
                    dataIndex: 'type',
                    title: <FormattedMessage id="label.operation" />,
                    render: value => getActionTypeLabel(value)
                  },
                  {
                    dataIndex: 'pGen',
                    title: <FormattedMessage id="node.prop.p.gen.label" />
                  }
                ]}
              />
              <SubTitle>
                <FormattedMessage id="title.result" />
              </SubTitle>
              <StyledTable
                rowKey="id"
                dataSource={summary.edges}
                pagination={false}
                size="small"
                style={{
                  overflow: 'auto'
                }}
                scroll={{ y: SCROLL_HEIGHT }}
                columns={[
                  {
                    dataIndex: 'label',
                    title: <FormattedMessage id="label.edge" />
                  },
                  {
                    dataIndex: 'iSrc',
                    title: <FormattedMessage id="edge.prop.i.src.label" />
                  },
                  {
                    dataIndex: 'iMaxSrc',
                    title: <FormattedMessage id="edge.prop.i.max.src.label" />
                  },
                  {
                    dataIndex: 'iTgt',
                    title: <FormattedMessage id="edge.prop.i.tgt.label" />
                  },
                  {
                    dataIndex: 'iMaxTgt',
                    title: <FormattedMessage id="edge.prop.i.max.tgt.label" />
                  },
                  {
                    dataIndex: 'loadFactorSrc',
                    title: (
                      <FormattedMessage id="edge.prop.load.factor.source.label" />
                    )
                  },
                  {
                    dataIndex: 'loadFactorTgt',
                    title: (
                      <FormattedMessage id="edge.prop.load.factor.target.label" />
                    )
                  }
                ]}
              />
            </>
          ) : (
            <p>{formatMessage('title.emergency.response.not.found')}</p>
          )}
        </Container>
      )}
    </Modal>
  );
};

export default EmergencyLiquidationReportModal;
