// © ООО «Эдиспа», 2022

import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef
} from 'react';
import { message } from 'antd';

import LoadingMask from 'components/loading/LoadingMask';
import Message from 'components/message/Message';
import { usePowerGridContext, usePowerGridDispatch } from 'grid/context';
import { useExportToJson } from 'grid/hooks';
import { actions } from 'grid/store';
import { useFormatMessage } from 'utils/format';

const ExportToJsonFeature: FunctionComponent = () => {
  const formatMessage = useFormatMessage();
  const { grid } = usePowerGridContext();
  const { schema } = grid;
  const ref = useRef(schema);
  const dispatch = usePowerGridDispatch();
  const [exportToJson, state] = useExportToJson();

  useEffect(() => {
    message.destroy();
    exportToJson(ref.current);
  }, [exportToJson, ref]);

  const deactivate = useCallback(
    () => dispatch(actions.setActiveFeature()),
    [dispatch]
  );

  useEffect(() => {
    const { error, data } = state;
    if (error) {
      message.error({
        duration: 0,
        content: (
          <Message type="error" onClose={deactivate}>
            {formatMessage(error)}
          </Message>
        )
      });
      deactivate();
    } else if (data) {
      deactivate();
    }
  }, [state, deactivate]);

  return state.loading ? <LoadingMask /> : null;
};

export default ExportToJsonFeature;
