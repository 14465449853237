// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';

import Message from 'components/message/Message';
import ClonePowerGridModal from 'grid/components/ClonePowerGridModal';
import { usePowerGridDispatch } from 'grid/context';
import { actions } from 'grid/store';
import { PowerGrid } from 'grid';

const ClonePowerGridFeature: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = usePowerGridDispatch();

  const onComplete = (grid: PowerGrid) => {
    dispatch(actions.setActiveFeature());
    navigate(`/grids/${grid.id}`);
    setTimeout(() => {
      message.info({
        content: (
          <Message type="info">
            Созданная копия открыта для редактирования.
          </Message>
        )
      });
    }, 100);
  };

  const onCancel = () => dispatch(actions.setActiveFeature());

  return (
    <ClonePowerGridModal
      onComplete={onComplete}
      onCancel={onCancel}
      visible={true}
    />
  );
};

export default ClonePowerGridFeature;
