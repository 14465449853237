// © ООО «Эдиспа», 2022

import React, {
  FunctionComponent,
  useCallback,
  useLayoutEffect,
  useMemo
} from 'react';
import { FormattedMessage } from 'react-intl';
import { isEqual } from 'lodash';
import styled from 'styled-components/macro';
import create from 'zustand';

import Button from 'components/button/Button';
import { Toolbar } from 'components/Layout';
import { Paragraph } from 'components/Typography';
import Modal from 'components/modal/Modal';
import { usePowerGridContext, usePowerGridDispatch } from 'grid/context';
import { actions, PowerGridFeature } from 'grid/store';

const Content = styled.div`
  ${Paragraph} {
    margin-bottom: 32px;
  }
`;

interface DetectUnsavedChangesState {
  feature?: PowerGridFeature;
  activate: (feature: PowerGridFeature) => void;
  deactivate: VoidFunction;
}

export const useDetectUnsavedChangesStore = create<DetectUnsavedChangesState>(
  set => ({
    activate: (feature: PowerGridFeature) => {
      set({ feature });
    },
    deactivate: () => set({ feature: undefined })
  })
);

interface UnsavedChangesDetectorProps {
  feature: PowerGridFeature;
}

const UnsavedChangesDetector: FunctionComponent<UnsavedChangesDetectorProps> =
  props => {
    const { grid, original } = usePowerGridContext();
    const dispatch = usePowerGridDispatch();
    const feature = useDetectUnsavedChangesStore(state => state.feature);

    const isActiveDetector = props.feature === feature;

    const hasUnsavedChanges = useMemo(
      () => (isActiveDetector ? !isEqual(original.schema, grid.schema) : false),
      [original.schema, grid.schema, isActiveDetector]
    );

    const deactivate = useDetectUnsavedChangesStore(state => state.deactivate);

    const activateFeature = useCallback(() => {
      deactivate();
      dispatch(actions.setActiveFeature(feature));
    }, [deactivate, dispatch, feature]);

    useLayoutEffect(() => {
      if (isActiveDetector && !hasUnsavedChanges) {
        activateFeature();
      }
    }, [hasUnsavedChanges, activateFeature, isActiveDetector]);

    if (!isActiveDetector) {
      return null;
    }

    return (
      <Modal
        visible={hasUnsavedChanges}
        onCancel={deactivate}
        title={<FormattedMessage id="modal.unsaved.changes.title" />}
      >
        <Content>
          <Paragraph>
            <FormattedMessage id="modal.unsaved.changes.instructions" />
          </Paragraph>
          <Toolbar>
            <Button type="primary" onClick={activateFeature}>
              <FormattedMessage id="button.continue" />
            </Button>
            <Button type="secondary" onClick={deactivate}>
              <FormattedMessage id="button.cancel" />
            </Button>
          </Toolbar>
        </Content>
      </Modal>
    );
  };

export default UnsavedChangesDetector;
