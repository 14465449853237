// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';

import DeviceDetector from 'components/DeviceDetector';
import MessageGlobalStyle from 'components/message/GlobalStyle';
import GlobalStyle from 'components/style/GlobalStyle';
import IntlProvider from 'i18n/IntlProvider';
import Routes from 'router/Routes';
import defaultTheme from 'themes/data/default.json';

const App: FunctionComponent = () => {
  return (
    <BrowserRouter>
      <IntlProvider>
        <ThemeProvider theme={defaultTheme}>
          <MessageGlobalStyle />
          <GlobalStyle />
          <DeviceDetector>
            <Routes />
          </DeviceDetector>
        </ThemeProvider>
      </IntlProvider>
    </BrowserRouter>
  );
};

export default App;
