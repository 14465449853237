// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';

import Loading from 'components/loading/Loading';
import Mask from 'components/loading/Mask';
import style from 'themes';

const StyledLoading = styled(Loading)`
  color: ${style('color.white')};
`;

const LoadingMask: FunctionComponent = () => (
  <Mask>
    <StyledLoading />
  </Mask>
);

export default LoadingMask;
