// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Empty } from 'antd';
import { ColumnProps, TableProps } from 'antd/lib/table';
import { identity } from 'lodash';

import Label from 'components/form/Label';
import { NodeType, Node, PowerGridMode } from 'grid';
import Table, {
  DEFAULT_PAGE_SIZE,
  useRenderWithMessage
} from 'grid/components/Table';
import { usePowerGridContext } from 'grid/context';
import { getNodeTypeLabel } from 'grid/utils';
import formatters from 'utils/format';
import { sorter } from 'utils/table';

export interface NodeTableProps {
  extraColumns: ColumnProps<Node>[];
}

interface PaginationProps {
  page: number;
}

const NodeTable: FunctionComponent<NodeTableProps & TableProps<Node>> = ({
  extraColumns,
  ...otherProps
}) => {
  const renderWithMessage = useRenderWithMessage<Node>();
  const [paginationProps, setPaginationProps] = useState<PaginationProps>({
    page: 1
  });
  const { grid } = usePowerGridContext();
  const { schema, mode } = grid;
  const { nodes } = schema;

  const onPaginationChange = (page: number) => {
    setPaginationProps({
      page
    });
  };

  const columns = useMemo<ColumnProps<Node>[]>(() => {
    const layout =
      mode === PowerGridMode.CONFIGURATION
        ? [
            'index',
            'label',
            'type',
            'uNom',
            'uGiv',
            'pNom',
            'qNom',
            'pGen',
            'qGen',
            'pMin',
            'pMax',
            'qMin',
            'qMax',
            'gSh',
            'bSh'
          ]
        : [
            'index',
            'label',
            'type',
            'uNom',
            'uGiv',
            'u',
            'delta',
            'pNom',
            'qNom',
            'pGen',
            'qGen',
            'pMin',
            'pMax',
            'qMin',
            'qMax',
            'gSh',
            'bSh'
          ];

    const onInvalidCell = (path: string) => (record: Node) => {
      const { messages } = record;
      return messages && messages[path] ? { className: 'invalid' } : {};
    };

    const columns: { [key: string]: ColumnProps<Node> } = {
      index: {
        key: 'index',
        render: (_: string, __: Node, index: number) => {
          return (paginationProps.page - 1) * DEFAULT_PAGE_SIZE + index + 1;
        },
        align: 'right',
        width: 40,
        fixed: 'left'
      },
      label: {
        dataIndex: 'label',
        title: <FormattedMessage id="node.prop.label" />,
        ellipsis: true,
        render: renderWithMessage(identity, 'label'),
        sorter: sorter<Node>('label'),
        width: 150,
        fixed: 'left',
        onCell: onInvalidCell('label')
      },
      type: {
        dataIndex: 'type',
        title: <FormattedMessage id="node.prop.type" />,
        render: (value: NodeType) => getNodeTypeLabel(value),
        sorter: sorter<Node>('type'),
        width: 90
      },
      uNom: {
        dataIndex: 'uNom',
        title: (
          <Label
            value={<FormattedMessage id="node.prop.u.nom.label" />}
            info={<FormattedMessage id="node.prop.u.nom.description" />}
          />
        ),
        render: renderWithMessage(formatters.number, 'uNom'),
        sorter: sorter<Node>('uNom'),
        align: 'right',
        width: 100,
        onCell: onInvalidCell('uNom')
      },
      uGiv: {
        dataIndex: 'uGiv',
        title: (
          <Label
            value={<FormattedMessage id="node.prop.u.giv.label" />}
            info={<FormattedMessage id="node.prop.u.giv.description" />}
          />
        ),
        render: renderWithMessage(formatters.number, 'uGiv'),
        sorter: sorter<Node>('uGiv'),
        align: 'right',
        width: 100,
        onCell: onInvalidCell('uGiv')
      },
      pNom: {
        dataIndex: 'pNom',
        title: (
          <Label
            value={<FormattedMessage id="node.prop.p.nom.label" />}
            info={<FormattedMessage id="node.prop.p.nom.description" />}
          />
        ),
        render: renderWithMessage(formatters.number, 'pNom'),
        sorter: sorter<Node>('pNom'),
        align: 'right',
        width: 90,
        onCell: onInvalidCell('pNom')
      },
      qNom: {
        dataIndex: 'qNom',
        title: (
          <Label
            value={<FormattedMessage id="node.prop.q.nom.label" />}
            info={<FormattedMessage id="node.prop.q.nom.description" />}
          />
        ),
        render: renderWithMessage(formatters.number, 'qNom'),
        sorter: sorter<Node>('qNom'),
        align: 'right',
        width: 90,
        onCell: onInvalidCell('qNom')
      },
      pGen: {
        dataIndex: 'pGen',
        title: (
          <Label
            value={<FormattedMessage id="node.prop.p.gen.label" />}
            info={<FormattedMessage id="node.prop.p.gen.description" />}
          />
        ),
        render: renderWithMessage(formatters.number, 'pGen'),
        sorter: sorter<Node>('pGen'),
        align: 'right',
        width: 90,
        onCell: onInvalidCell('pGen')
      },
      qGen: {
        dataIndex: 'qGen',
        title: (
          <Label
            value={<FormattedMessage id="node.prop.q.gen.label" />}
            info={<FormattedMessage id="node.prop.q.gen.description" />}
          />
        ),
        render: renderWithMessage(formatters.number, 'qGen'),
        sorter: sorter<Node>('qGen'),
        align: 'right',
        width: 90,
        onCell: onInvalidCell('qGen')
      },
      pMin: {
        dataIndex: 'pMin',
        title: (
          <Label
            value={<FormattedMessage id="node.prop.p.min.label" />}
            info={<FormattedMessage id="node.prop.p.min.description" />}
          />
        ),
        render: renderWithMessage(formatters.number, 'pMin'),
        sorter: sorter<Node>('pMin'),
        align: 'right',
        width: 100,
        onCell: onInvalidCell('pMin')
      },
      pMax: {
        dataIndex: 'pMax',
        title: (
          <Label
            value={<FormattedMessage id="node.prop.p.max.label" />}
            info={<FormattedMessage id="node.prop.p.max.description" />}
          />
        ),
        render: renderWithMessage(formatters.number, 'pMax'),
        sorter: sorter<Node>('pMax'),
        align: 'right',
        width: 100,
        onCell: onInvalidCell('pMax')
      },
      qMin: {
        dataIndex: 'qMin',
        title: (
          <Label
            value={<FormattedMessage id="node.prop.q.min.label" />}
            info={<FormattedMessage id="node.prop.q.min.description" />}
          />
        ),
        render: renderWithMessage(formatters.number, 'qMin'),
        sorter: sorter<Node>('qMin'),
        align: 'right',
        width: 100,
        onCell: onInvalidCell('qMin')
      },
      qMax: {
        dataIndex: 'qMax',
        title: (
          <Label
            value={<FormattedMessage id="node.prop.q.max.label" />}
            info={<FormattedMessage id="node.prop.q.max.description" />}
          />
        ),
        render: renderWithMessage(formatters.number, 'qMax'),
        sorter: sorter<Node>('qMax'),
        align: 'right',
        width: 100,
        onCell: onInvalidCell('qMax')
      },
      gSh: {
        dataIndex: 'gSh',
        title: (
          <Label
            value={<FormattedMessage id="node.prop.g.sh.label" />}
            info={<FormattedMessage id="node.prop.g.sh.description" />}
          />
        ),
        render: renderWithMessage(formatters.number, 'gSh'),
        sorter: sorter<Node>('gSh'),
        align: 'right',
        width: 90,
        onCell: onInvalidCell('gSh')
      },
      bSh: {
        dataIndex: 'bSh',
        title: (
          <Label
            value={<FormattedMessage id="node.prop.b.sh.label" />}
            info={<FormattedMessage id="node.prop.b.sh.description" />}
          />
        ),
        render: renderWithMessage(formatters.number, 'bSh'),
        sorter: sorter<Node>('bSh'),
        align: 'right',
        width: 90,
        onCell: onInvalidCell('bSh')
      },
      u: {
        dataIndex: 'u',
        title: (
          <Label
            value={<FormattedMessage id="node.prop.u.label" />}
            info={<FormattedMessage id="node.prop.u.description" />}
          />
        ),
        render: formatters.number,
        sorter: sorter<Node>('u'),
        align: 'right',
        width: 90
      },
      delta: {
        dataIndex: 'delta',
        title: (
          <Label
            value={<FormattedMessage id="node.prop.delta.label" />}
            info={<FormattedMessage id="node.prop.delta.description" />}
          />
        ),
        render: formatters.number,
        sorter: sorter<Node>('delta'),
        align: 'right',
        width: 90
      }
    };
    return [...layout.map(id => columns[id]), ...extraColumns];
  }, [extraColumns, paginationProps]);

  return (
    <Table
      showSorterTooltip={false}
      dataSource={nodes}
      rowKey="id"
      size="small"
      pagination={{
        current: paginationProps.page,
        pageSize: DEFAULT_PAGE_SIZE,
        onChange: onPaginationChange,
        showSizeChanger: false,
        showTotal: (total, range) => `${range[0]}-${range[1]} из ${total}`
      }}
      columns={columns}
      scroll={{
        x: 'max-content'
      }}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<FormattedMessage id="label.empty" />}
          />
        )
      }}
      rowClassName={record => (record.messages ? 'invalid' : '')}
      {...otherProps}
    />
  );
};

export default NodeTable;
