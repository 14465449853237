// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { Route as ReactRoute } from 'react-router-dom';
import { RouteProps } from 'react-router';
import { getAuth } from 'firebase/auth';

import { useUserContext } from 'auth/context';
import CreateUserProfilePage from 'auth/components/CreateUserProfilePage';
import DisabledUserPage from 'auth/components/DisabledUserPage';
import EmailVerificationPage from 'auth/components/EmailVerificationPage';
import InactiveUserPage from 'auth/components/InactiveUserPage';
import UserAgreementPage from 'auth/components/UserAgreementPage';
import Container from 'page/components/Container';
import { PageContextProvider } from 'page/context';

const UserContextRoute: FunctionComponent<RouteProps> = props => {
  const { acceptedTerms, active, disabled, profile } = useUserContext();
  const currentUser = getAuth().currentUser;

  if (currentUser && !currentUser.emailVerified) {
    props = {
      ...props,
      element: <EmailVerificationPage />
    };
  } else if (!profile) {
    props = {
      ...props,
      element: <CreateUserProfilePage />
    };
  } else if (!active) {
    props = {
      ...props,
      element: <InactiveUserPage />
    };
  } else if (!acceptedTerms) {
    props = {
      ...props,
      element: <UserAgreementPage />
    };
  } else if (disabled) {
    props = {
      ...props,
      element: <DisabledUserPage />
    };
  } else {
    // TODO: review
    return (
      <PageContextProvider value={{}}>
        <Container>
          <ReactRoute {...props} />
        </Container>
      </PageContextProvider>
    );
  }

  return <ReactRoute {...props} />;
};

export default UserContextRoute;
