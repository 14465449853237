// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useCallback } from 'react';
import { message } from 'antd';

import Message from 'components/message/Message';
import { Schema } from 'grid';
import { usePowerGridDispatch } from 'grid/context';
import { actions } from 'grid/store';
import ImportFromJsonModal from 'import/components/ImportFromJsonModal';
import { useFormatMessage } from 'utils/format';

const ImportFromJsonFeature: FunctionComponent = () => {
  const dispatch = usePowerGridDispatch();
  const formatMessage = useFormatMessage();

  const deactivate = useCallback(
    () => dispatch(actions.setActiveFeature()),
    [dispatch]
  );

  const onComplete = useCallback(
    (schema: Schema) => {
      const { nodes, edges } = schema;
      dispatch(
        actions.updateSchema({
          nodes,
          edges
        })
      );
      const isValid =
        nodes.every(node => !node.messages) &&
        edges.every(edge => !edge.messages);
      if (isValid) {
        message.success({
          content: (
            <Message type="success">
              {formatMessage('message.import.from.json.success')}
            </Message>
          )
        });
      } else {
        message.error({
          duration: 0,
          content: (
            <Message type="error" closable>
              {formatMessage('message.import.from.json.failure')}
            </Message>
          )
        });
      }
      deactivate();
    },
    [dispatch, deactivate]
  );

  return (
    <ImportFromJsonModal
      onCancel={deactivate}
      onComplete={onComplete}
      visible={true}
    />
  );
};

export default ImportFromJsonFeature;
