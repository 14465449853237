// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import Alert from 'components/Alert';
import ErrorPage from 'components/ErrorPage';
import Loading from 'components/loading/Loading';
import { PowerGrid } from 'grid';
import PowerGridActiveFeature from 'grid/feature/ActiveFeature';
import PowerGridMainView from 'grid/components/PowerGridMainView';
import PowerGridSettingsView from 'grid/components/PowerGridSettingsView';
import { PowerGridProvider } from 'grid/context';
import { useReadPowerGrid } from 'grid/hooks';
import { PowerGridView } from 'grid/store';
import { isCodeError } from 'utils/CodeError';

const PowerGridDetailsPage: FunctionComponent = () => {
  const { id } = useParams();
  const { loading, error, data } = useReadPowerGrid(id);

  const context = useMemo(
    () =>
      data
        ? {
            grid: data as PowerGrid,
            original: data as PowerGrid,
            view: PowerGridView.MAIN
          }
        : undefined,
    [data]
  );

  if (loading) {
    return <Loading />;
  } else if (error) {
    if (isCodeError(error)) {
      const { code } = error;
      if (code === 'edispa/power-grid-not-found') {
        return <ErrorPage type="not-found" />;
      } else if (code === 'permission-denied') {
        return <ErrorPage type="permission-denied" />;
      }
    }
    return <Alert error={error} />;
  } else if (!context) {
    return <ErrorPage type="not-found" />;
  }

  const { grid } = context;

  return (
    <PowerGridProvider value={context}>
      <Helmet>
        <title>{grid.title}</title>
      </Helmet>
      <PowerGridMainView />
      <PowerGridSettingsView />
      <PowerGridActiveFeature />
    </PowerGridProvider>
  );
};

export default PowerGridDetailsPage;
