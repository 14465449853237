// © ООО «Эдиспа», 2022

import styled from 'styled-components/macro';

// TODO: theme
const IconButton = styled.button.attrs({
  type: 'button'
})`
  font-size: 24px;
  line-height: 24px;
  width: 24px;
  height: 24px;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  color: #1e052d;
  :hover {
    color: #fc7456;
  }
  :active {
    color: #d05e4e;
  }
`;

export default IconButton;
