// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'antd';
import styled from 'styled-components/macro';

import { Toolbar } from 'components/Layout';
import { PowerGridMode } from 'grid';
import IconButton from 'grid/components/toolbar/IconButton';
import { usePowerGridContext, usePowerGridDispatch } from 'grid/context';
import { actions } from 'grid/store';
import { IconGearSvg } from 'svg';

const Container = styled(Toolbar)`
  justify-content: flex-end;
  flex: 1;
`;

const SecondaryToolbar: FunctionComponent = () => {
  const { grid } = usePowerGridContext();
  const dispatch = usePowerGridDispatch();
  const { mode } = grid;

  const onClick = () => dispatch(actions.openSettingsView());

  return (
    <Container>
      {mode === PowerGridMode.CONFIGURATION && (
        <Tooltip
          title={<FormattedMessage id="button.settings" />}
          mouseEnterDelay={1}
          placement="topRight"
        >
          <IconButton onClick={onClick}>
            <IconGearSvg />
          </IconButton>
        </Tooltip>
      )}
    </Container>
  );
};

export default SecondaryToolbar;
