// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useMemo, useReducer } from 'react';

import { EmailActionType, ViewType } from 'auth/action/store';
import { Context, DispatchContext } from 'auth/action/context';
import { reducer } from 'auth/action/store';
import { VerifyActionResponse } from 'auth/hooks';

export interface ProviderProps {
  value: VerifyActionResponse;
}

const getViewByType = (type: EmailActionType): ViewType => {
  switch (type) {
    case EmailActionType.VERIFY_EMAIL:
      return ViewType.EMAIL_VERIFICATION_CONFIRMATION;
    case EmailActionType.RESET_PASSWORD:
      return ViewType.NEW_PASSWORD;
    default:
      return ViewType.ERROR;
  }
};

const Provider: FunctionComponent<ProviderProps> = ({ children, value }) => {
  const initialValue = useMemo(() => {
    const { code, email, type, error } = value;
    return {
      code,
      email,
      type,
      view: getViewByType(type),
      error
    };
  }, [value]);

  const [state, dispatch] = useReducer(reducer, initialValue);

  return (
    <Context.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </Context.Provider>
  );
};

export default Provider;
