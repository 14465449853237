// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from 'antd';
import styled from 'styled-components/macro';

import { useUserContext } from 'auth/context';
import { signOut } from 'auth/utils';
import AboutModal from 'components/app/AboutModal';
import Menu, { MenuProps } from 'components/menu/Menu';
import {
  IconGearSvg,
  IconInfoCircleSvg,
  QuestionSquareSvg,
  SignOutSvg,
  UserLightSvg
} from 'svg';

const { Paragraph } = Typography;

const UserLightIcon = styled(UserLightSvg)`
  margin-right: 16px;
  flex-shrink: 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  width: 248px;
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
`;

const Header = styled.div`
  margin: 16px 16px 0 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #f3f3f4;
  display: flex;
  align-items: center;
`;

const DisplayName = styled(Paragraph).attrs({
  ellipsis: { rows: 2 }
})`
  &.ant-typography {
    margin-bottom: 0;
  }
`;

const UserMenu: FunctionComponent<MenuProps> = props => {
  const { user } = useUserContext();
  const [modalVisible, setModalVisible] = useState(false);

  const onSignOutClick = () => signOut();

  const onAboutClick = () => {
    setModalVisible(true);
  };

  const onCloseModal = () => setModalVisible(false);

  return (
    <Container>
      <Header>
        <UserLightIcon />
        <DisplayName>{user.displayName}</DisplayName>
      </Header>
      <Menu {...props}>
        <Menu.Item href="/docs" newTab icon={<QuestionSquareSvg />}>
          <FormattedMessage id="menu.item.help" />
        </Menu.Item>
        <Menu.Item onClick={onAboutClick} icon={<IconInfoCircleSvg />}>
          <FormattedMessage id="menu.item.about" />
        </Menu.Item>
        <Menu.Item href="/settings" icon={<IconGearSvg />}>
          <FormattedMessage id="menu.item.settings" />
        </Menu.Item>
        <Menu.Item onClick={onSignOutClick} icon={<SignOutSvg />}>
          <FormattedMessage id="menu.item.sign.out" />
        </Menu.Item>
      </Menu>
      <AboutModal visible={modalVisible} onCancel={onCloseModal} />
    </Container>
  );
};

export default UserMenu;
