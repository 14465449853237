// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import 'firebase/auth';

import Container from 'auth/components/Container';
import Provider from 'auth/ui/Provider';
import ActiveView from 'auth/ui/ActiveView';
import { useFormatMessage } from 'utils/format';

const LoginPage: FunctionComponent = () => {
  const formatMessage = useFormatMessage();
  return (
    <Container>
      <Helmet>
        <title>{formatMessage('title.edispa')}</title>
      </Helmet>
      <Provider>
        <ActiveView />
      </Provider>
    </Container>
  );
};

export default LoginPage;
