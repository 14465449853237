// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from 'components/button/Button';
import { Toolbar } from 'components/Layout';
import Modal, { ModalProps } from 'components/modal/Modal';
import { Edge } from 'grid';
import EdgeDetailsForm from 'grid/components/edge/DetailsForm';

export interface EdgeDetailsModalProps extends ModalProps {
  edge?: Edge;
  onSubmit: (edge: Edge) => void;
}

const EdgeDetailsModal: FunctionComponent<EdgeDetailsModalProps> = ({
  edge,
  onSubmit,
  onCancel,
  ...otherProps
}) => {
  return (
    <Modal
      {...otherProps}
      width={448}
      title={edge ? 'Свойства ветви' : 'Новая ветвь'}
      style={{
        top: 64
      }}
    >
      <EdgeDetailsForm
        edge={edge}
        onSubmit={onSubmit}
        footer={
          <Toolbar>
            <Button type="primary" htmlType="submit">
              <FormattedMessage id="button.done" />
            </Button>
            <Button type="secondary" onClick={onCancel}>
              <FormattedMessage id="button.cancel" />
            </Button>
          </Toolbar>
        }
      />
    </Modal>
  );
};

export default EdgeDetailsModal;
