// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';

import { Title, Paragraph } from 'auth/components/Typography';
import { AuthLockSuccessSvg } from 'svg';

const Image = styled(AuthLockSuccessSvg)``;

const Container = styled.div`
  max-width: 640px;
  margin: 0 auto;
  ${Image} {
    margin-bottom: 24px;
  }
  ${Title}, ${Paragraph} {
    margin-bottom: 32px;
  }
`;

const NewPasswordConfirmationView: FunctionComponent = () => {
  return (
    <Container>
      <Image />
      <Title>
        <FormattedMessage id="auth.new.password.confirmation.view.title" />
      </Title>
    </Container>
  );
};

export default NewPasswordConfirmationView;
