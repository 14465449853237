// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'antd';

import IconButton from 'grid/components/toolbar/IconButton';
import { usePowerGridDispatch } from 'grid/context';
import { actions, PowerGridFeature } from 'grid/store';
import { IconCloneSvg } from 'svg';

const ExportPowerGridButton: FunctionComponent = () => {
  const dispatch = usePowerGridDispatch();

  const onClick = () =>
    dispatch(actions.setActiveFeature(PowerGridFeature.EXPORT));

  return (
    <Tooltip mouseEnterDelay={1} title={<FormattedMessage id="button.clone" />}>
      <IconButton onClick={onClick}>
        <IconCloneSvg />
      </IconButton>
    </Tooltip>
  );
};

export default ExportPowerGridButton;
