// © ООО «Эдиспа», 2022

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  applyActionCode,
  getAuth,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  verifyPasswordResetCode,
  updateProfile,
  User,
  UserCredential
} from 'firebase/auth';
import {
  doc,
  getDoc,
  getFirestore,
  serverTimestamp,
  setDoc
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { convert, UserContext, UserProfile } from 'auth';
import { EmailActionType } from 'auth/action/store';
import { CodeError } from 'utils/CodeError';
import {
  actions as authActions,
  State,
  useFetch,
  useReducer
} from 'utils/hooks';
import { compact } from 'utils/object';

interface AuthState {
  authenticating: boolean;
  context?: UserContext;
}

enum ActionType {
  SIGN_IN = 'SIGN_IN',
  SIGN_OUT = 'SIGN_OUT'
}

interface SignInAction {
  type: ActionType.SIGN_IN;
  payload: UserContext;
}

const signIn = (payload: UserContext): SignInAction => ({
  type: ActionType.SIGN_IN,
  payload
});

interface SignOutAction {
  type: ActionType.SIGN_OUT;
}

const signOut = (): SignOutAction => ({
  type: ActionType.SIGN_OUT
});

type AuthAction = SignInAction | SignOutAction;

const reducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case ActionType.SIGN_IN: {
      return {
        authenticating: false,
        context: action.payload
      };
    }
    case ActionType.SIGN_OUT: {
      return {
        authenticating: false
      };
    }
    default: {
      return state;
    }
  }
};

const actions = {
  signIn,
  signOut
};

const initialState: AuthState = {
  authenticating: true
};

export const useCurrentUser = (): AuthState => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  useEffect(() => {
    const unregister = getAuth().onAuthStateChanged(async user => {
      if (user) {
        const context: UserContext = {
          user,
          acceptedTerms: false,
          active: false,
          disabled: false
        };

        if (user.emailVerified) {
          const db = getFirestore();
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            Object.assign(context, convert.toUserState(userDoc));
          }

          const userProfileRef = doc(db, 'profiles', user.uid);
          const userProfileDoc = await getDoc(userProfileRef);

          if (userProfileDoc.exists()) {
            context.profile = convert.toUserProfile(userProfileDoc);
          }
        }

        dispatch(actions.signIn(context));
      } else {
        dispatch(actions.signOut());
      }
    });
    return () => {
      unregister();
    };
  }, [dispatch]);

  return state;
};

export const useQuery = () => {
  const { search } = useLocation();
  return new URLSearchParams(search);
};

interface SignUpUserParams {
  displayName: string;
  email: string;
  password: string;
}

export const useSignUpUser = () =>
  useFetch<User, SignUpUserParams>(async params => {
    const { displayName, email, password } = params;

    const auth = getAuth();
    const credential: UserCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );

    const user = credential.user as User;
    await updateProfile(user, {
      displayName
    });

    await sendEmailVerification(user);

    return user;
  });

interface SignInUserParams {
  email: string;
  password: string;
}

export const useSignInUser = () =>
  useFetch<User, SignInUserParams>(async params => {
    const { email, password } = params;

    const auth = getAuth();
    const credential = await signInWithEmailAndPassword(auth, email, password);

    return credential.user as User;
  });

export const useSendEmailVerification = () =>
  useFetch<boolean, User>(async user => {
    await sendEmailVerification(user);
    return true;
  });

interface SendPasswordResetEmailParams {
  email: string;
  continueUrl: string;
}

export const useSendPasswordResetEmail = () =>
  useFetch<string, SendPasswordResetEmailParams>(async params => {
    const { email, continueUrl } = params;
    const auth = getAuth();
    await sendPasswordResetEmail(auth, email, {
      url: continueUrl
    });
    return email;
  });

interface ConfirmPasswordResetParams {
  code: string;
  password: string;
  email?: string;
}

export const useConfirmPasswordReset = () =>
  useFetch<true, ConfirmPasswordResetParams>(async params => {
    const { code, email, password } = params;

    const auth = getAuth();
    await confirmPasswordReset(auth, code, password);

    if (email) {
      await signInWithEmailAndPassword(auth, email, password);
    }

    return true;
  });

export const useAcceptTerms = () =>
  useFetch<boolean, void>(async () => {
    const functions = getFunctions();
    const acceptTerms = httpsCallable(functions, 'acceptTerms');
    await acceptTerms();
    return true;
  });

interface CreateUserProfileParams {
  organization: string;
  position: string;
  phone?: string;
}

export const useCreateUserProfile = () =>
  useFetch<UserProfile, CreateUserProfileParams>(async params => {
    const { uid } = getAuth().currentUser as User;

    const { organization, position, phone } = params;

    const props = compact({
      organization,
      position,
      phone
    });

    const db = getFirestore();
    const userProfileRef = doc(db, 'profiles', uid);

    await setDoc(userProfileRef, {
      ...props,
      createdAt: serverTimestamp()
    });

    return props;
  });

export interface VerifyActionRequest {
  type: EmailActionType;
  code: string;
}

const useVerifyActionRequest = (): VerifyActionRequest => {
  const query = useQuery();
  const mode = query.get('mode');
  const oobCode = query.get('oobCode');
  return {
    type:
      mode === EmailActionType.RESET_PASSWORD ||
      mode === EmailActionType.VERIFY_EMAIL
        ? mode
        : EmailActionType.UNKNOWN,
    code: oobCode as string
  };
};

export interface VerifyActionResponse {
  type: EmailActionType;
  code: string;
  email?: string;
  error?: Error;
}

export const useVerifyAction = (): State<VerifyActionResponse> => {
  const { type, code } = useVerifyActionRequest();

  const [state, dispatch] = useReducer<VerifyActionResponse>({
    loading: true
  });

  useEffect(() => {
    const verifyAction = async () => {
      const response: VerifyActionResponse = {
        type,
        code
      };
      try {
        const auth = getAuth();
        if (type === EmailActionType.RESET_PASSWORD) {
          response.email = await verifyPasswordResetCode(auth, code);
        } else if (type === EmailActionType.VERIFY_EMAIL) {
          await applyActionCode(auth, code);
          const currentUser = auth.currentUser;
          if (currentUser) {
            // обновляем токен, чтобы он получил emailVerified=true
            await currentUser.getIdToken(true);
          }
        } else {
          throw new CodeError('auth/edispa/invalid-action-url');
        }
      } catch (error: any) {
        response.error = error;
      }
      // это особый случай, когда ошибки требуют дополнительный контекст, и поэтому упаковываются в общий результат
      dispatch(authActions.onSuccess(response));
    };

    verifyAction();
  }, [dispatch]);

  return state;
};
