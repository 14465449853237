// © ООО «Эдиспа», 2022

import { createGlobalStyle } from 'styled-components/macro';

import style from 'themes';

export default createGlobalStyle`
  .ant-form-item-label > label {
    color: ${style('form.label.color')};
  }
`;
