// © ООО «Эдиспа», 2022

import { Switch } from 'antd';
import styled from 'styled-components/macro';

import style from 'themes';

export default styled(Switch)`
  &.ant-switch {
    background-color: ${style('switch.background.color')};
    :focus {
      box-shadow: none;
    }
    .ant-click-animating-node {
      animation: none;
    }
  }
  &.ant-switch-checked {
    background-color: ${style('switch.checked.background.color')};
  }
`;
