// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useLayoutEffect, useMemo } from 'react';

import TabPanel from 'components/tab/TabPanel';
import { PowerGridMode } from 'grid';
import PageTitle from 'grid/components/PageTitle';
import PowerGridCommonSettingsView from 'grid/components/PowerGridCommonSettingsView';
import PowerGridAccessControlView from 'grid/components/PowerGridAccessControlView';
import PowerGridDeleteView from 'grid/components/PowerGridDeleteView';
import { usePowerGridContext, usePowerGridDispatch } from 'grid/context';
import { actions, PowerGridView } from 'grid/store';
import { actions as page, useDispatchPageContext } from 'page/context';

const PowerGridSettingsView: FunctionComponent = () => {
  const { grid, view } = usePowerGridContext();
  const powerGridDispatch = usePowerGridDispatch();
  const pageDispatch = useDispatchPageContext();

  const { title, mode } = grid;

  useLayoutEffect(() => {
    if (view !== PowerGridView.SETTINGS) {
      return;
    }
    pageDispatch(
      page.setContext({
        callback: () => powerGridDispatch(actions.openMaingView()),
        title: (
          <PageTitle
            id="page.power.grid.details.title.settings"
            values={{
              title: title
            }}
          />
        )
      })
    );
  }, [title, view, powerGridDispatch, pageDispatch]);

  const tabs = useMemo(
    () => [
      {
        key: 'common',
        title: 'Общие',
        content: <PowerGridCommonSettingsView />
      },
      {
        key: 'accessControl',
        title: 'Управление доступом',
        content: <PowerGridAccessControlView />
      },
      {
        key: 'deletePowerGrid',
        title: 'Удаление сети',
        content: <PowerGridDeleteView />
      }
    ],
    []
  );

  return mode === PowerGridMode.CONFIGURATION ? (
    <TabPanel
      tabs={tabs}
      layout="settings"
      style={view === PowerGridView.SETTINGS ? undefined : { display: 'none' }}
    />
  ) : null;
};

export default PowerGridSettingsView;
