// © ООО «Эдиспа», 2022

import React, {
  forwardRef,
  ForwardRefRenderFunction,
  HTMLAttributes
} from 'react';
import styled from 'styled-components/macro';

import { IconArrowDownSvg } from 'svg';
import style from 'themes';

const StyledIcon = styled(IconArrowDownSvg)`
  font-size: 24px;
`;

const StyledSelect = styled.select`
  width: 100%;
  appearance: none;
  padding: 0 8px;
  color: ${style('color')};
  height: 40px;
  border: 1px solid transparent;
  border-radius: 8px;
  background-color: ${style('input.background')};
  font-size: 16px;
  :hover {
    border: 1px solid ${style('input.hover.border.color')};
  }
  :focus,
  :active {
    outline: none;
    border: 1px solid ${style('input.focus.border.color')};
  }
`;

export const Option = styled.option``;

const Container = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  ${StyledIcon} {
    position: absolute;
    pointer-events: none;
    top: 8px;
    right: 4px;
  }
`;

const Select: ForwardRefRenderFunction<
  HTMLSelectElement,
  HTMLAttributes<HTMLSelectElement>
> = (props, ref) => (
  <Container>
    <StyledSelect ref={ref} {...props} />
    <StyledIcon />
  </Container>
);

export default forwardRef(Select);
