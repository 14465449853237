// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';

import { Toolbar } from 'components/Layout';

import { useUserContext } from 'auth/context';
import { PowerGridMode } from 'grid';
import CalcPowerFlowButton from 'grid/components/toolbar/CalcPowerFlowButton';
import ClonePowerGridButton from 'grid/components/toolbar/ClonePowerGridButton';
import ExportPowerGridButton from 'grid/components/toolbar/ExportPowerGridButton';
import ExportToJsonButton from 'grid/components/toolbar/ExportToJsonButton';
import ImportFromJsonButton from 'grid/components/toolbar/ImportFromJsonButton';
import ToggleModeButton from 'grid/components/toolbar/ToggleModeButton';
import { usePowerGridContext } from 'grid/context';
import SaveButton from 'grid/components/toolbar/SaveButton';

const Container = styled(Toolbar)`
  flex: 1;
`;

const PrimaryToolbar: FunctionComponent = () => {
  const { user } = useUserContext();
  const { grid } = usePowerGridContext();
  const { mode, owner } = grid;

  return (
    <Container>
      {mode === PowerGridMode.CONFIGURATION && (
        <>
          <ToggleModeButton />
          <ImportFromJsonButton />
          <ExportToJsonButton />
          <SaveButton />
          <ClonePowerGridButton />
        </>
      )}
      {mode === PowerGridMode.OPERATION && (
        <>
          {user.uid === owner.id && <ToggleModeButton />}
          <ExportToJsonButton />
          <ExportPowerGridButton />
          <CalcPowerFlowButton />
        </>
      )}
    </Container>
  );
};

export default PrimaryToolbar;
