// © ООО «Эдиспа», 2022

import styled, { keyframes } from 'styled-components/macro';

import { IconLoadingSvg } from 'svg';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Icon = styled(IconLoadingSvg)`
  animation: ${spin} infinite 2s linear;
`;

export default Icon;
