// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { NodeProps } from 'react-flow-renderer';

import { Node } from 'grid';

import NodeShape from 'grid/components/graph/NodeShape';
import { getNodeCustomColor } from 'grid/utils';

const BalanceNode: FunctionComponent<NodeProps<Node>> = ({
  data,
  selected
}) => (
  <NodeShape
    type={data.type}
    label={data.label}
    selected={selected}
    invalid={!!data.messages}
    customColor={getNodeCustomColor(data)}
  />
);

export default BalanceNode;
