/* eslint-disable @typescript-eslint/ban-types */
// © ООО «Эдиспа», 2022

import get from 'lodash/get';

export const angleRenderer = (value?: number): string | undefined =>
  value && Number.isFinite(value)
    ? ((value / Math.PI) * 180).toFixed(2)
    : undefined;

export function compare<T extends object, K extends keyof T>(
  a: T,
  b: T,
  key: K
): number {
  const first = get<T, K>(a, key);
  const second = get<T, K>(b, key);
  return compareValues(first, second);
}

export function compareValues(first: any, second: any): number {
  if (first === undefined) {
    return -1;
  } else if (second === undefined) {
    return 1;
  }
  if (first < second) {
    return -1;
  } else if (first > second) {
    return 1;
  }
  return 0;
}

export function sorter<T extends object>(key: keyof T) {
  return (a: T, b: T): number => compare<T, typeof key>(a, b, key);
}
