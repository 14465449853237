// © ООО «Эдиспа», 2022

import React, { CSSProperties, FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useResizeDetector } from 'react-resize-detector';
import { isNumber } from 'lodash';
import styled from 'styled-components/macro';

import Button from 'components/button/Button';
import IconButton from 'components/button/IconButton';
import { Toolbar } from 'components/Layout';
import { Node, PowerGridMode } from 'grid';
import NodeDetailsModal, {
  NodeDetailsModalProps
} from 'grid/components/node/DetailsModal';
import NodeTable from 'grid/components/node/Table';
import { usePowerGridContext, usePowerGridDispatch } from 'grid/context';
import { actions } from 'grid/store';
import { IconEditSvg, IconTrashSvg } from 'svg';

const ActionToolbar = styled(Toolbar)`
  justify-content: center; ;
`;

const StyledToolbar = styled(Toolbar)`
  grid-area: toolbar;
  margin-bottom: 24px;
`;

const Content = styled.div`
  grid-area: table;
  overflow: auto;
`;

const Container = styled.div`
  display: grid;
  grid-area: content;
  padding: 24px 24px 0 24px;
  grid-template-areas: 'toolbar' 'table';
  grid-template-rows: max-content 1fr;
  overflow: auto;
`;

export interface NodeListViewProps {
  style?: CSSProperties;
}

const NodeListView: FunctionComponent<NodeListViewProps> = ({ style }) => {
  const { grid } = usePowerGridContext();
  const { schema, mode } = grid;
  const dispatch = usePowerGridDispatch();
  const { height, ref } = useResizeDetector();

  const [modalProps, setModalProps] = useState<Partial<NodeDetailsModalProps>>({
    visible: false
  });

  const onAddNodeClick = () => setModalProps({ visible: true });

  const onSubmitModal = (node: Node) => {
    dispatch(
      modalProps.node ? actions.updateNode(node) : actions.addNode(node)
    );
    setModalProps({ visible: false });
  };

  const onCancelModal = () => setModalProps({ visible: false });

  const renderActionsColumn = (_: any, record: Node) => {
    const onEditClick = () =>
      setModalProps({
        node: record,
        visible: true
      });
    const onDeleteClick = () => dispatch(actions.removeNode(record));
    return (
      <ActionToolbar>
        <IconButton onClick={onEditClick}>
          <IconEditSvg />
        </IconButton>
        {mode === PowerGridMode.CONFIGURATION && (
          <IconButton onClick={onDeleteClick}>
            <IconTrashSvg />
          </IconButton>
        )}
      </ActionToolbar>
    );
  };

  return (
    <Container style={style}>
      {mode === PowerGridMode.CONFIGURATION && (
        <StyledToolbar>
          <Button type="secondary" onClick={onAddNodeClick}>
            <FormattedMessage id="button.add" />
          </Button>
        </StyledToolbar>
      )}
      <Content ref={ref}>
        {isNumber(height) && (
          <NodeTable
            dataSource={schema.nodes}
            scroll={{ y: height }}
            extraColumns={[
              {
                key: 'actions',
                width: 100,
                align: 'center',
                render: renderActionsColumn,
                fixed: 'right'
              }
            ]}
          />
        )}
      </Content>
      <NodeDetailsModal
        {...modalProps}
        onSubmit={onSubmitModal}
        onCancel={onCancelModal}
      />
    </Container>
  );
};

export default NodeListView;
