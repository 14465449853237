// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from 'components/button/Button';
import { Toolbar } from 'components/Layout';
import Modal, { ModalProps } from 'components/modal/Modal';
import { Node } from 'grid';
import NodeDetailsForm from 'grid/components/node/DetailsForm';

export interface NodeDetailsModalProps extends ModalProps {
  node?: Node;
  onSubmit: (node: Node) => void;
}

const NodeDetailsModal: FunctionComponent<NodeDetailsModalProps> = ({
  node,
  onSubmit,
  onCancel,
  ...otherProps
}) => {
  return (
    <Modal
      {...otherProps}
      width={448}
      title={node ? 'Свойства узла' : 'Новый узел'}
      style={{
        top: 64
      }}
    >
      <NodeDetailsForm
        node={node}
        onSubmit={onSubmit}
        footer={
          <Toolbar>
            <Button type="primary" htmlType="submit">
              <FormattedMessage id="button.done" />
            </Button>
            <Button type="secondary" onClick={onCancel}>
              <FormattedMessage id="button.cancel" />
            </Button>
          </Toolbar>
        }
      />
    </Modal>
  );
};

export default NodeDetailsModal;
