// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';

import GlobalStyle from 'auth/components/GlobalStyle';
import Tag from 'auth/components/Tag';
import Logo from 'components/Logo';
import style, { StyledProps } from 'themes';

const Content = styled.div``;

const Container = styled.div`
  background-color: ${style('auth.background')};
  height: 100%;
  color: ${style('auth.color')};
  h2 {
    color: ${style('auth.color')};
  }
  // TODO: проверить использование
  .ant-typography {
    color: ${style('auth.color')};
  }
  .ant-form,
  .ant-form-item-label > label {
    color: ${style('auth.form.label.color')};
    font-size: 16px;
    font-weight: 300;
  }
  position: relative;
  padding: 0 16px 120px 16px;
  min-width: 320px;
  overflow: auto;
  ${Content} {
    margin-top: 100px;
  }
`;

const Header = styled.div`
  margin-left: 120px;
  @media (max-width: 880px) {
    margin: 0 auto;
    max-width: 640px;
  }
  @media (max-width: 672px) {
    max-width: 640px;
  }
`;

const AuthContainer: FunctionComponent<StyledProps> = ({
  children,
  className
}) => (
  <Container className={className}>
    <GlobalStyle />
    <Header>
      <Tag>
        <Logo />
      </Tag>
    </Header>
    <Content>{children}</Content>
  </Container>
);

export default AuthContainer;
