// © ООО «Эдиспа», 2022

import React, {
  CSSProperties,
  FunctionComponent,
  HTMLAttributes,
  ReactNode,
  useState
} from 'react';
import classNames from 'classnames';
import styled, { css } from 'styled-components/macro';

import style, { StyledProps } from 'themes';
import { CodeError } from 'utils/CodeError';

const Header = styled.div.attrs({
  className: 'edispa-tab-panel-header'
})``;

const Content = styled.div.attrs({
  className: 'edispa-tab-panel-content'
})``;

const Footer = styled.div.attrs({
  className: 'edispa-tab-panel-footer'
})``;

const TabContent = styled.span``;

const active = css`
  ${TabContent} {
    border-color: ${style('tab.selected.color')};
    color: ${style('tab.selected.color')};
  }
`;

const inactive = css`
  cursor: pointer;
  :hover {
    ${TabContent} {
      color: ${style('tab.hover.color')};
    }
  }
  :active {
    ${TabContent} {
      color: ${style('tab.active.color')};
    }
  }
`;

interface TabLayoutProps {
  active: boolean;
}

const DefaultTabLayout = styled.button.attrs({
  type: 'button'
})<TabLayoutProps>`
  border: none;
  outline: none;
  background: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  ${TabContent} {
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    border-bottom: 1px solid #f4f3f5;
    flex: 1;
    padding: 16px 0;
    ${props =>
      props.active
        ? css`
            border-bottom-color: #fc7456;
          `
        : css`
            cursor: pointer;
            color: #a298a8;
          `}
  }
`;

const SettingsTabLayout = styled.button.attrs({
  type: 'button'
})<TabLayoutProps>`
  border: none;
  outline: none;
  background: none;
  display: flex;
  text-align: left;
  padding: 8px 0;
  ${TabContent} {
    padding-left: 20px;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    border-left: 3px solid transparent;
  }
  ${props => (props.active ? active : inactive)}
`;

const DefaultTabPanelLayout = styled.div`
  display: flex;
  flex-direction: column;
  ${Header} {
    display: flex;
  }
  ${Footer} {
    display: none;
  }
`;

const SettingsTabPanelLayout = styled.div`
  padding-top: 48px;
  display: grid;
  grid-template-columns:
    minmax(16px, 1fr) 248px minmax(min-content, 640px)
    minmax(0px, 248px) minmax(16px, 1fr);
  grid-template-areas: '. tabs content sider .';
  ${Header} {
    grid-area: tabs;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  ${Content} {
    grid-area: content;
  }
  ${Footer} {
    grid-area: sider;
  }
`;

interface TabProps {
  key: string;
  title: ReactNode;
  content: ReactNode;
}

interface TabPanelProps {
  tabs: TabProps[];
  layout?: 'default' | 'settings';
  style?: CSSProperties;
}

const TabPanel: FunctionComponent<TabPanelProps & StyledProps> = ({
  tabs,
  layout = 'default',
  style,
  className
}) => {
  const [firstTab] = tabs;
  const [activeTabKey, setActiveTabKey] = useState(firstTab.key);

  const activeTab = tabs.find(tab => tab.key === activeTabKey) as TabProps;

  let TabPanelLayout;
  let TabLayout: FunctionComponent<
    HTMLAttributes<HTMLButtonElement> & TabLayoutProps
  >;
  if (layout === 'default') {
    TabPanelLayout = DefaultTabPanelLayout;
    TabLayout = DefaultTabLayout;
  } else if (layout === 'settings') {
    TabPanelLayout = SettingsTabPanelLayout;
    TabLayout = SettingsTabLayout;
  } else {
    throw new CodeError('edispa/unknown-error');
  }

  return (
    <TabPanelLayout
      className={classNames(className, 'edispa-tab-panel')}
      style={style}
    >
      <Header>
        {tabs.map(({ key, title }) => {
          const isActive = key === activeTabKey;
          const handler = isActive ? undefined : () => setActiveTabKey(key);

          return (
            <TabLayout key={key} onClick={handler} active={isActive}>
              <TabContent>{title}</TabContent>
            </TabLayout>
          );
        })}
      </Header>
      <Content>{activeTab.content}</Content>
      <Footer />
    </TabPanelLayout>
  );
};

export default TabPanel;
