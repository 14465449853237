// © ООО «Эдиспа», 2022

import React, { FunctionComponent, memo, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import TabPanel from 'components/tab/TabPanel';
import { actions as page, useDispatchPageContext } from 'page/context';
import { Route } from 'router';
import DeleteAccountView from 'components/DeleteAccountView';
import { useFormatMessage } from 'utils/format';

const SettingsPage: FunctionComponent = () => {
  const dispatch = useDispatchPageContext();
  const location = useLocation();
  const formatMessage = useFormatMessage();
  const { state } = location;

  const route = useMemo(() => {
    let route: string | undefined;
    if (state) {
      const { pathname } = state as any;
      route = pathname as string;
    }
    return route || Route.HOME;
  }, [state]);

  useEffect(() => {
    const title = () => <FormattedMessage id="page.settings.title" />;
    dispatch(
      page.setContext({
        route,
        title
      })
    );
  }, [dispatch, route]);

  const tabs = [
    {
      key: 'deleteAccount',
      title: <FormattedMessage id="settings.delete.account.view.title" />,
      content: <DeleteAccountView />
    }
  ];
  return (
    <>
      <Helmet>
        <title>{formatMessage('title.edispa')}</title>
      </Helmet>
      <TabPanel tabs={tabs} layout="settings" />;
    </>
  );
};

export default memo(SettingsPage);
