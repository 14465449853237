// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { Options } from 'react-markdown';
import styled from 'styled-components/macro';

import { Markdown } from 'auth/components/Typography';

type ReactMarkdownProps = Omit<Options, 'children'>;

type MarkdownMessageProps = Pick<MessageDescriptor, 'id'> & {
  values?: Record<string, any>;
};

const MarkdownMessage: FunctionComponent<
  MarkdownMessageProps & ReactMarkdownProps
> = ({ id, values, ...otherProps }) => {
  const intl = useIntl();
  return (
    <Markdown {...otherProps}>{intl.formatMessage({ id }, values)}</Markdown>
  );
};

export default styled(MarkdownMessage)``;
