// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';

import Button from 'components/button/Button';
import { Title, Paragraph } from 'components/Typography';
import { Route } from 'router';
import { LockFailureSvg, SadFaceFailureSvg, UfoSvg } from 'svg';
import { useFormatMessage } from 'utils/format';

const Container = styled.div`
  display: block;
  margin: 140px auto 24px auto;
  text-align: center;
  ${Title} {
    margin-top: 24px;
    margin-bottom: 0;
    font-size: 32px;
    line-height: 38px;
    font-weight: normal;
  }
  ${Paragraph} {
    margin-top: 16px;
    margin-bottom: 0;
  }
  ${Button} {
    margin-top: 32px;
    margin-bottom: 64px;
  }
`;

export interface ErrorPageProps {
  type: 'not-found' | 'permission-denied' | 'unknown';
}

const GoHomeButton: FunctionComponent = () => {
  const navigate = useNavigate();

  const onClick = () => navigate(Route.HOME);

  return (
    <Button type="primary" onClick={onClick}>
      <FormattedMessage id="button.go.home" />
    </Button>
  );
};

const NotFoundPage: FunctionComponent = () => (
  <Container>
    <UfoSvg />
    <Title>
      <FormattedMessage id="page.not.found.error.title" />
    </Title>
    <Paragraph>
      <FormattedMessage id="page.not.found.error.description" />
    </Paragraph>
    <GoHomeButton />
  </Container>
);

const PermissionDeniedPage: FunctionComponent = () => (
  <Container>
    <LockFailureSvg />
    <Title>
      <FormattedMessage id="page.permission.denied.error.title" />
    </Title>
    <GoHomeButton />
  </Container>
);

const UnknownErrorPage: FunctionComponent = () => (
  <Container>
    <SadFaceFailureSvg />
    <Title>
      <FormattedMessage id="page.unknown.error.title" />
    </Title>
    <Paragraph>
      <FormattedMessage id="page.unknown.error.description" />
    </Paragraph>
    <GoHomeButton />
  </Container>
);

const ErrorPage: FunctionComponent<ErrorPageProps> = ({ type }) => {
  const formatMessage = useFormatMessage();
  return (
    <>
      <Helmet>
        <title>{formatMessage('title.edispa')}</title>
      </Helmet>
      {type === 'not-found' && <NotFoundPage />}
      {type === 'permission-denied' && <PermissionDeniedPage />}
      {type === 'unknown' && <UnknownErrorPage />}
    </>
  );
};

export default ErrorPage;
