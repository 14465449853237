// © ООО «Эдиспа», 2022
import { Reducer } from 'react';

import { AbstractAction } from 'utils/reducer';

export enum ViewType {
  EMAIL_VERIFICATION_CONFIRMATION = 'AUTH_VIEW/EMAIL_VERIFICATION_CONFIRMATION',
  ERROR = 'AUTH_VIEW/ERROR',
  NEW_PASSWORD = 'AUTH_VIEW/NEW_PASSWORD',
  NEW_PASSWORD_CONFIRMATION = 'AUTH_VIEW/NEW_PASSWORD_CONFIRMATION'
}

export interface State {
  view: ViewType;
  type: EmailActionType;
  email?: string;
  code?: string;
  error?: Error;
}

export enum EmailActionType {
  RESET_PASSWORD = 'resetPassword',
  VERIFY_EMAIL = 'verifyEmail',
  UNKNOWN = 'unknown'
}

export enum ActionType {
  CONFIRM_NEW_PASSWORD
}

type ConfirmNewPasswordAction = AbstractAction<ActionType.CONFIRM_NEW_PASSWORD>;

export type Action = ConfirmNewPasswordAction;

export const reducer: Reducer<State, Action> = (
  state: State,
  action: Action
): State => {
  switch (action.type) {
    case ActionType.CONFIRM_NEW_PASSWORD: {
      return {
        ...state,
        view: ViewType.NEW_PASSWORD_CONFIRMATION
      };
    }
    default: {
      return state;
    }
  }
};

export const actions = {
  confirmNewPassword: (): Action => ({
    type: ActionType.CONFIRM_NEW_PASSWORD
  })
};
