// © ООО «Эдиспа», 2022

import React, { DragEvent, FunctionComponent } from 'react';
import styled from 'styled-components/macro';

import { Node } from 'grid';
import NodeShape from 'grid/components/graph/NodeShape';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 16px;
  > * {
    margin-top: 16px;
  }
`;

export interface NodeListProps {
  nodes: Node[];
}

const onDragStart = (event: DragEvent<HTMLDivElement>, id: string) => {
  event.dataTransfer.setData('application/reactflow', id);
  event.dataTransfer.effectAllowed = 'move';
};

const NodeList: FunctionComponent<NodeListProps> = ({ nodes }) => {
  return (
    <Container>
      {nodes.map(node => (
        <NodeShape
          key={node.id}
          type={node.type}
          label={node.label}
          className="dndnode"
          onDragStart={event => onDragStart(event, node.id)}
          draggable
        />
      ))}
    </Container>
  );
};

export default NodeList;
