// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { User } from 'firebase/auth';

import { useContext, useDispatch } from 'auth/ui/context';
import { actions, ViewType } from 'auth/ui/store';

import EmailVerificationView from 'auth/components/EmailVerificationView';
import SignInView from 'auth/components/SignInView';
import SignUpView from 'auth/components/SignUpView';
import PasswordResetView from 'auth/components/PasswordResetView';
import PasswordResetConfirmationView from 'auth/components/PasswordResetConfirmationView';

const ActiveView: FunctionComponent = () => {
  const { view, user } = useContext();
  const dispatch = useDispatch();

  const onEmailVerificationComplete = () => dispatch(actions.reset());

  return (
    <>
      {view === ViewType.LOGIN && <SignInView />}
      {view === ViewType.SIGN_UP && <SignUpView />}
      {view === ViewType.EMAIL_VERIFICATION && (
        <EmailVerificationView
          user={user as User}
          onComplete={onEmailVerificationComplete}
        />
      )}
      {view === ViewType.PASSWORD_RESET && <PasswordResetView />}
      {view === ViewType.PASSWORD_RESET_CONFIRMATION && (
        <PasswordResetConfirmationView />
      )}
    </>
  );
};

export default ActiveView;
