// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';

import { Title } from 'components/Typography';
import MarkdownMessage from 'components/i18n/MarkdownMessage';

const Container = styled.div`
  ${Title} {
    margin-bottom: 32px;
  }
`;

const DeleteAccountView: FunctionComponent = () => {
  return (
    <Container>
      <Title>
        <FormattedMessage id="settings.delete.account.view.title" />
      </Title>
      <MarkdownMessage id="settings.delete.account.view.instructions" />
    </Container>
  );
};

export default DeleteAccountView;
