// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { FormItemProps } from 'antd/lib/form';
import { Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';

import Button from 'components/button/Button';
import Form, { FormProps } from 'components/form/Form';

const { Item } = Form;

export interface ImportFromJsonFormProps {
  /**
   * футер
   */
  footer: FormItemProps['children'];
}

interface FileUploadProps {
  value?: UploadFile[];
  onChange?: (value: UploadFile[]) => void;
}

const FileUpload: FunctionComponent<FileUploadProps> = ({
  value,
  onChange
}) => {
  const beforeUpload = (file: UploadFile) => {
    onChange && onChange([file]);
    return false;
  };
  const onRemove = () => {
    onChange && onChange([]);
  };
  return (
    <Upload
      beforeUpload={beforeUpload}
      fileList={value}
      onRemove={onRemove}
      accept=".json"
    >
      <Button type="secondary">Выберите файл</Button>
    </Upload>
  );
};

const ImportFromJsonForm: FunctionComponent<
  ImportFromJsonFormProps & FormProps
> = ({ footer, ...otherProps }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} requiredMark={false} layout="vertical" {...otherProps}>
      <Item name="fileList" rules={[{ required: true }]}>
        <FileUpload />
      </Item>
      <Item noStyle>{footer}</Item>
    </Form>
  );
};

export default ImportFromJsonForm;
