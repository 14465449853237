// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useLayoutEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';

import Alert from 'components/Alert';
import Button from 'components/button/Button';
import SubmitButton from 'components/button/SubmitButton';
import { Toolbar } from 'components/Layout';
import { Paragraph } from 'components/Typography';
import Modal, { ModalProps } from 'components/modal/Modal';
import { usePowerGridContext } from 'grid/context';
import { useDeletePowerGrid } from 'grid/hooks';

const Content = styled.div`
  ${Alert}, ${Paragraph} {
    margin-bottom: 32px;
  }
`;

type DeletePowerGridModalProps = ModalProps & {
  onComplete: VoidFunction;
  onCancel: VoidFunction;
};

const DeletePowerGridModal: FunctionComponent<DeletePowerGridModalProps> = ({
  onComplete,
  onCancel,
  ...otherProps
}) => {
  const { grid } = usePowerGridContext();

  const ref = useRef(onComplete);

  const [deletePowerGrid, { loading, error, data }] = useDeletePowerGrid();

  const onSubmit = () => deletePowerGrid(grid.id);

  useLayoutEffect(() => {
    if (data) {
      const onComplete = ref.current;
      onComplete();
    }
  }, [data, ref]);

  if (ref.current !== onComplete) {
    ref.current = onComplete;
  }

  return (
    <Modal
      {...otherProps}
      title={<FormattedMessage id="modal.delete.power.grid.title" />}
    >
      <Content>
        <Alert error={error} />
        <Paragraph>
          <FormattedMessage id="modal.delete.power.grid.instructions" />
        </Paragraph>
        <Toolbar>
          <SubmitButton
            type="danger"
            loading={loading}
            htmlType="button"
            onClick={onSubmit}
          >
            <FormattedMessage id="button.delete" />
          </SubmitButton>
          <Button type="secondary" onClick={onCancel}>
            <FormattedMessage id="button.cancel" />
          </Button>
        </Toolbar>
      </Content>
    </Modal>
  );
};

export default DeletePowerGridModal;
