// © ООО «Эдиспа», 2022

import { useMemo } from 'react';
import { isBoolean, isNil, isNumber, isPlainObject, isString } from 'lodash';

import { Rule, RuleRender } from 'antd/lib/form';
import { useFormatMessage } from 'utils/format';

const isEmpty = (value: any) =>
  value === undefined || value === null || value === '';

interface Rules {
  min: (min?: number | string) => Rule;
  max: (max?: number | string) => Rule;
  greater: (min?: number) => Rule;
  requiredBy: (
    ...fields: (string | { name: string; value: any })[]
  ) => RuleRender;
  range: (min: string, max: string) => RuleRender;
  number: Rule;
  string: Rule;
  boolean: Rule;
}

export const useRules = (): Rules => {
  const formatMessage = useFormatMessage();

  return useMemo(() => {
    return {
      min: min => {
        return ({ getFieldValue }) => ({
          validator: async (rule: Rule, value: any) => {
            let minValue;
            if (isString(min)) {
              minValue = getFieldValue(min);
            } else if (isNumber(min)) {
              minValue = min;
            }
            if (isNumber(value) && isNumber(minValue) && value < minValue) {
              const message = formatMessage('form.validation.number.min', {
                min: minValue
              });
              throw new Error(message);
            }
          }
        });
      },
      max: max => {
        return ({ getFieldValue }) => ({
          validator: async (rule: Rule, value: any) => {
            let maxValue;
            if (isString(max)) {
              maxValue = getFieldValue(max);
            } else if (isNumber(max)) {
              maxValue = max;
            }
            if (isNumber(value) && isNumber(maxValue) && value > maxValue) {
              const message = formatMessage('form.validation.number.max', {
                max: maxValue
              });
              throw new Error(message);
            }
          }
        });
      },
      greater: (min = 0) => ({
        validator: async (rule: Rule, value: any) => {
          if (Number.isFinite(value) && value <= min) {
            const message = formatMessage('form.validation.number.greater', {
              min
            });
            throw new Error(message);
          }
        }
      }),
      requiredBy:
        (...fields) =>
        ({ getFieldValue }) => ({
          validator: async (rule, value) => {
            fields.forEach(field => {
              let required = false;
              if (isString(field)) {
                const dependency = getFieldValue(field);
                required = !isEmpty(dependency) && isEmpty(value);
              } else if (isPlainObject(field)) {
                const dependencyValue = getFieldValue(field.name);
                required = dependencyValue === field.value && isEmpty(value);
              }
              if (required) {
                const message = formatMessage('form.validation.required');
                throw new Error(message);
              }
            });
          }
        }),
      range:
        (min, max) =>
        ({ getFieldValue }) => ({
          validator: async (rule: Rule, value: any) => {
            if (Number.isFinite(value)) {
              const minValue = getFieldValue(min);
              const maxValue = getFieldValue(max);
              if (Number.isFinite(minValue) && Number.isFinite(maxValue)) {
                if (value < minValue) {
                  const message = formatMessage('form.validation.number.min', {
                    min: minValue
                  });
                  throw new Error(message);
                } else if (value > maxValue) {
                  const message = formatMessage('form.validation.number.max', {
                    max: maxValue
                  });
                  throw new Error(message);
                }
              }
            }
          }
        }),
      number: () => ({
        validator: async (rule: Rule, value: any) => {
          if (!isNil(value) && !isNumber(value)) {
            const message = formatMessage('validation/value/invalid-format');
            throw new Error(message);
          }
        }
      }),
      string: () => ({
        validator: async (rule: Rule, value: any) => {
          if (!isNil(value) && !isString(value)) {
            const message = formatMessage('validation/value/invalid-format');
            throw new Error(message);
          }
        }
      }),
      boolean: () => ({
        validator: async (rule: Rule, value: any) => {
          if (!isNil(value) && !isBoolean(value)) {
            const message = formatMessage('validation/value/invalid-format');
            throw new Error(message);
          }
        }
      })
    };
  }, []);
};
