// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';

import LinkButton from 'auth/components/button/LinkButton';
import ErrorView from 'auth/components/ErrorView';
import TroubleshootEmailModal from 'auth/components/TroubleshootEmailModal';
import { Title } from 'auth/components/Typography';
import { useSendPasswordResetEmail } from 'auth/hooks';
import { useContext, useDispatch } from 'auth/ui/context';
import { actions, ViewType } from 'auth/ui/store';
import Button from 'components/button/Button';
import MarkdownMessage from 'components/i18n/MarkdownMessage';
import { Row } from 'components/Layout';
import { AuthEmailSentSvg } from 'svg';

const Container = styled.div`
  max-width: 640px;
  margin: 0 auto;
  ${LinkButton} {
    margin-bottom: 32px;
    font-size: 16px;
  }
`;

const PasswordResetConfirmationView: FunctionComponent = () => {
  const { email } = useContext();
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [sendPasswordResetEmail, { loading, error, data }] =
    useSendPasswordResetEmail();

  const onReset = () => dispatch(actions.setView(ViewType.LOGIN));

  const onTroubleshootClick = () => setModalVisible(true);

  const onTroubleshootCancel = () => setModalVisible(false);

  const onTroubleshoot = () =>
    sendPasswordResetEmail({
      email: email as string,
      continueUrl: window.location.href
    });

  useEffect(() => {
    if (data) {
      setModalVisible(false);
    }
  }, [setModalVisible, data]);

  if (error) {
    return (
      <Container>
        <ErrorView error={error} />
      </Container>
    );
  }

  return (
    <Container>
      <AuthEmailSentSvg />
      <Title>
        <FormattedMessage id="auth.password.reset.confirmation.view.title" />
      </Title>
      <MarkdownMessage
        id="auth.password.reset.confirmation.view.instructions"
        values={{ email: email as string }}
      />
      <Row>
        <LinkButton onClick={onTroubleshootClick}>
          <FormattedMessage id="auth.troubleshoot.email.modal.title" />
        </LinkButton>
      </Row>
      <Row>
        <Button type="primary" onClick={onReset}>
          <FormattedMessage id="button.go.to.sign.in" />
        </Button>
      </Row>
      <TroubleshootEmailModal
        visible={modalVisible}
        loading={loading}
        onSubmit={onTroubleshoot}
        onCancel={onTroubleshootCancel}
      />
    </Container>
  );
};

export default PasswordResetConfirmationView;
