// © ООО «Эдиспа», 2022

import { isNumber, isString } from 'lodash';

import {
  DenormalizedEdge,
  DenormalizedSchema,
  Edge,
  FlowDirection,
  isDenormalizedEdge,
  isEdge,
  isNode,
  Node,
  NodeType,
  Schema
} from 'grid';
import formatters from 'utils/format';

export function getEdgeId(edge: Edge): string;
export function getEdgeId(edge: DenormalizedEdge): string;
export function getEdgeId(source: string, target: string): string;
export function getEdgeId(source: Node, target: Node): string;
export function getEdgeId(...args: any[]): string {
  const [first, second] = args;
  if (isEdge(first)) {
    return first.id || `${first.source}_${first.target}`;
  } else if (isDenormalizedEdge(first)) {
    return `${first.source.id}_${first.target.id}`;
  } else if (isString(first) && isString(second)) {
    return `${first}_${second}`;
  } else if (isNode(first) && isNode(second)) {
    return `${first.id}_${first.id}`;
  }
  throw new TypeError('Invalid argument');
}

export const getNodeTypeLabel = (type?: NodeType): string | undefined => {
  switch (type) {
    case NodeType.GENERATION: {
      return 'Генерация';
    }
    case NodeType.LOAD: {
      return 'Нагрузка';
    }
    case NodeType.BALANCE: {
      return 'Баланс';
    }
    default: {
      return;
    }
  }
};

export const hasFlow = (edge: Edge | DenormalizedEdge): boolean => {
  const { pSrc, qSrc } = edge;
  return typeof pSrc === 'number' && typeof qSrc === 'number';
};

export const getFlowDirection = (
  edge: Edge | DenormalizedEdge
): FlowDirection => {
  if (hasFlow(edge)) {
    const pSrc = edge.pSrc as number;
    return pSrc < 0 ? FlowDirection.BACKWARD : FlowDirection.FORWARD;
  }
  return FlowDirection.NONE;
};

export const getGraphEdgeLabel = (
  edge: Edge | DenormalizedEdge
): string | undefined => {
  if (hasFlow(edge)) {
    const pSrc = edge.pSrc as number;
    const qSrc = edge.qSrc as number;
    const sign = (pSrc >= 0 && qSrc >= 0) || (pSrc < 0 && qSrc < 0) ? '+' : '-';
    const re = formatters.number(Math.abs(pSrc));
    const im = formatters.number(Math.abs(qSrc));
    return `${re} ${sign} i${im}`;
  }
};

export const isTransformer = (edge: DenormalizedEdge) => {
  const { source, target } = edge;
  return (
    isNumber(source.uNom) &&
    isNumber(target.uNom) &&
    source.uNom !== target.uNom
  );
};

/**
 * Размер узла по умолчанию
 */
const DEFAULT_NODE_SIZE = 72;

interface NodeSize {
  width: number;
  height: number;
}

/**
 * Для заданного типа узла возвращает его размеры в пикселах.
 * @param node Узел
 * @returns размеры узла в пикселах
 */
export const getNodeSize = (type: NodeType): NodeSize => {
  return type === NodeType.BALANCE
    ? {
        width: DEFAULT_NODE_SIZE,
        height: 46
      }
    : {
        width: DEFAULT_NODE_SIZE,
        height: DEFAULT_NODE_SIZE
      };
};

export const hasLayout = (schema: Schema) => {
  const { nodes } = schema;
  return nodes.some((node: Node) => node.layout);
};

export const isGenerator = (node: Node) => {
  const { pGen, type } = node;
  return isNumber(pGen) && pGen > 0 && type !== NodeType.BALANCE;
};

export const getLoadFactorSrc = (edge: Edge | DenormalizedEdge) => {
  const { iSrc, iMaxSrc } = edge;
  return isNumber(iSrc) && isNumber(iMaxSrc)
    ? (iSrc / iMaxSrc) * 100
    : undefined;
};

export const getLoadFactorTgt = (edge: Edge | DenormalizedEdge) => {
  const { iTgt, iMaxTgt } = edge;
  return isNumber(iTgt) && isNumber(iMaxTgt)
    ? (iTgt / iMaxTgt) * 100
    : undefined;
};

type NodeMap = { [key: string]: Node };

export const denormalize = (
  schema: Pick<Schema, 'nodes' | 'edges'>
): DenormalizedSchema => {
  const { nodes, edges } = schema;
  const nodeMap = nodes.reduce<NodeMap>((acc, node) => {
    acc[node.id] = node;
    return acc;
  }, {});
  return {
    ...schema,
    edges: edges.map<DenormalizedEdge>(edge => ({
      ...edge,
      source: nodeMap[edge.source],
      target: nodeMap[edge.target]
    }))
  };
};

export const getEdgeLabel = (edge: DenormalizedEdge): string => {
  const { source, target } = edge;
  return `${source.label} - ${target.label}`;
};

export const normalize = (edge: DenormalizedEdge): Edge => {
  const { source, target, ...otherProps } = edge;
  return {
    ...otherProps,
    source: source.id,
    target: target.id
  };
};

// TODO: replace hardcoded values by configurable settings
// В зависимости от класса напряжения узлы должны быть раскрашены определенными цветами.
export const getNodeCustomColor = (node: Node) => {
  const { uNom } = node;
  if (uNom === 110) {
    return '#20C9FF';
  } else if (uNom === 220) {
    return '#FFC120';
  } else if (uNom === 500) {
    return '#FF208B';
  }
};
