// © ООО «Эдиспа», 2022

import React, {
  forwardRef,
  ForwardRefRenderFunction,
  FunctionComponent,
  HTMLAttributes,
  useState
} from 'react';
import styled from 'styled-components/macro';

import Input from 'auth/components/input/Input';
import { IconEyeSvg, IconEyeInvisibleSvg } from 'svg';
import style, { StyledProps } from 'themes';

type PasswordInputProps = Omit<HTMLAttributes<HTMLInputElement>, 'type'>;

const StyledInput = styled(Input)`
  padding-right: 46px;
`;

interface IconProps {
  visible: boolean;
  onClick: () => void;
}

const Icon: FunctionComponent<IconProps & StyledProps> = ({
  visible,
  onClick,
  className
}) => {
  const Component = visible ? IconEyeSvg : IconEyeInvisibleSvg;
  return <Component className={className} onClick={onClick} />;
};

const StyledIcon = styled(Icon)`
  color: ${style('auth.icon.color')};
  cursor: pointer;
  font-size: 20px;
  :hover,
  :focus {
    color: ${style('auth.icon.hover.color')};
  }
`;

const Container = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  ${StyledIcon} {
    position: absolute;
    top: 14px;
    right: 14px;
  }
`;

const PasswordInput: ForwardRefRenderFunction<
  HTMLInputElement,
  PasswordInputProps & HTMLAttributes<HTMLInputElement>
> = (props, ref) => {
  const [visible, setVisible] = useState(false);
  const onIconClick = () => setVisible(!visible);
  return (
    <Container>
      <StyledInput ref={ref} type={visible ? 'input' : 'password'} {...props} />
      <StyledIcon visible={visible} onClick={onIconClick} />
    </Container>
  );
};

const StyledPasswordInput = styled(forwardRef(PasswordInput))``;

export default StyledPasswordInput;
