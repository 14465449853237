// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';

import Spinner from 'svg/components/Spinner';
import { StyledProps } from 'themes';

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${Spinner} {
    font-size: 40px;
  }
`;

const Loading: FunctionComponent<StyledProps> = ({ className }) => (
  <Container className={className}>
    <Spinner />
  </Container>
);

export default Loading;
