// © ООО «Эдиспа», 2022

import styled from 'styled-components/macro';

import { LogoSvg } from 'svg';
import style from 'themes';

const Logo = styled(LogoSvg)`
  color: ${style('logo.color')};
`;

export default Logo;
