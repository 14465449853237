// © ООО «Эдиспа», 2022

import { merge } from 'lodash';
import { DeepPartial } from 'simplytyped';

import { PowerGrid, Schema, Node, Edge } from 'grid';
import { AbstractAction, PayloadAction } from 'utils/reducer';

export enum PowerGridView {
  MAIN = 'GRID_VIEW/MAIN',
  SETTINGS = 'GRID_VIEW/SETTINGS'
}

export enum PowerGridFeature {
  POWER_FLOW = 'GRID_FEATURE/POWER_FLOW',
  EMERGENCY = 'GRID_FEATURE/EMERGENCY',
  CLONE = 'GRID_FEATURE/CLONE',
  EXPORT = 'GRID_FEATURE/EXPORT',
  MODE = 'GRID_FEATURE/MODE',
  EXPORT_TO_JSON = 'GRID_FEATURE/EXPORT_TO_JSON',
  IMPORT_FROM_JSON = 'GRID_FEATURE/IMPORT_FROM_JSON',
  UPDATE_SCHEMA = 'GRID_FEATURE/UPDATE_SCHEMA'
}

export enum ActionType {
  SET_POWER_GRID = 'GRID/SET',
  ADD_NODE = 'GRID/ADD_NODE',
  UPDATE_NODE = 'GRID/UPDATE_NODE',
  REMOVE_NODE = 'GRID/REMOVE_NODE',
  ADD_EDGE = 'GRID/ADD_EDGE',
  UPDATE_EDGE = 'GRID/UPDATE_EDGE',
  REMOVE_EDGE = 'GRID/REMOVE_EDGE',
  UPDATE_SCHEMA = 'GRID/UPDATE_SCHEMA',
  OPEN_SETTINGS_VIEW = 'GRID/OPEN_SETTINGS_VIEW',
  OPEN_MAIN_VIEW = 'GRID/OPEN_MAIN_VIEW',
  UPDATE = 'GRID/UPDATE',
  SET_ACTIVE_FEATURE = 'GRID/SET_ACTIVE_FEATURE',
  SET_CONTEXT = 'GRID/SET_CONTEXT'
}

type SetPowerGridAction = PayloadAction<ActionType.SET_POWER_GRID, PowerGrid>;

type AddNodeAction = PayloadAction<ActionType.ADD_NODE, Node>;

interface UpdateNodeAction {
  type: ActionType.UPDATE_NODE;
  node: Node;
}

type RemoveNodeAction = PayloadAction<ActionType.REMOVE_NODE, Node>;

type AddEdgeAction = PayloadAction<ActionType.ADD_EDGE, Edge>;

interface UpdateEdgeAction {
  type: ActionType.UPDATE_EDGE;
  edge: Edge;
}

type RemoveEdgeAction = PayloadAction<ActionType.REMOVE_EDGE, Edge>;

type UpdateSchemaAction = PayloadAction<
  ActionType.UPDATE_SCHEMA,
  Partial<Schema>
>;

type OpenSettingsViewAction = AbstractAction<ActionType.OPEN_SETTINGS_VIEW>;

type OpenMainViewAction = AbstractAction<ActionType.OPEN_MAIN_VIEW>;

type UpdateAction = PayloadAction<ActionType.UPDATE, DeepPartial<PowerGrid>>;

type SetActiveFeatureAction = PayloadAction<
  ActionType.SET_ACTIVE_FEATURE,
  PowerGridFeature | undefined
>;

type SetContextAction = PayloadAction<ActionType.SET_CONTEXT, State>;

export type Action =
  | SetPowerGridAction
  | AddNodeAction
  | UpdateNodeAction
  | RemoveNodeAction
  | AddEdgeAction
  | UpdateEdgeAction
  | RemoveEdgeAction
  | UpdateSchemaAction
  | OpenSettingsViewAction
  | OpenMainViewAction
  | UpdateAction
  | SetActiveFeatureAction
  | SetContextAction;

export interface State {
  grid: PowerGrid;
  original: PowerGrid;
  view: PowerGridView;
  feature?: PowerGridFeature;
}

export const actions = {
  setPowerGrid: (payload: PowerGrid): Action => ({
    type: ActionType.SET_POWER_GRID,
    payload
  }),
  addNode: (payload: Node): Action => ({ type: ActionType.ADD_NODE, payload }),
  updateNode: (node: Node): Action => ({
    type: ActionType.UPDATE_NODE,
    node
  }),
  removeNode: (payload: Node): Action => ({
    type: ActionType.REMOVE_NODE,
    payload
  }),
  addEdge: (payload: Edge): Action => ({ type: ActionType.ADD_EDGE, payload }),
  updateEdge: (edge: Edge): Action => ({
    type: ActionType.UPDATE_EDGE,
    edge
  }),
  removeEdge: (payload: Edge): Action => ({
    type: ActionType.REMOVE_EDGE,
    payload
  }),
  updateSchema: (payload: Partial<Schema>): Action => ({
    type: ActionType.UPDATE_SCHEMA,
    payload
  }),
  openSettingsView: (): Action => ({
    type: ActionType.OPEN_SETTINGS_VIEW
  }),
  openMaingView: (): Action => ({
    type: ActionType.OPEN_MAIN_VIEW
  }),
  onUpdate: (payload: DeepPartial<PowerGrid>): Action => ({
    type: ActionType.UPDATE,
    payload
  }),
  setActiveFeature: (payload?: PowerGridFeature): Action => ({
    type: ActionType.SET_ACTIVE_FEATURE,
    payload
  }),
  setContext: (payload: State): Action => ({
    type: ActionType.SET_CONTEXT,
    payload
  })
};

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.SET_POWER_GRID: {
      return {
        ...state,
        grid: action.payload,
        original: action.payload
      };
    }
    case ActionType.ADD_NODE: {
      const { grid } = state;
      const { schema } = grid;
      const { nodes } = schema;
      const node = action.payload;
      return {
        ...state,
        grid: {
          ...grid,
          schema: {
            ...schema,
            nodes: [...nodes, node]
          }
        }
      };
    }
    case ActionType.UPDATE_NODE: {
      const { grid } = state;
      const { schema } = grid;
      const { nodes } = schema;
      const { node } = action;
      return {
        ...state,
        grid: {
          ...grid,
          schema: {
            ...schema,
            nodes: nodes.map(item => (item.id === node.id ? node : item))
          }
        }
      };
    }
    case ActionType.REMOVE_NODE: {
      const { grid } = state;
      const { schema } = grid;
      const { nodes, edges } = schema;
      const node = action.payload;
      return {
        ...state,
        grid: {
          ...grid,
          schema: {
            ...schema,
            nodes: nodes.filter(item => item.id !== node.id),
            edges: edges.filter(
              item => item.source !== node.id && item.target !== node.id
            )
          }
        }
      };
    }
    case ActionType.ADD_EDGE: {
      const { grid } = state;
      const { schema } = grid;
      const { edges } = schema;
      const edge = action.payload;
      return {
        ...state,
        grid: {
          ...grid,
          schema: {
            ...schema,
            edges: [...edges, edge]
          }
        }
      };
    }
    case ActionType.UPDATE_EDGE: {
      const { grid } = state;
      const { schema } = grid;
      const { edges } = schema;
      const { edge } = action;
      return {
        ...state,
        grid: {
          ...grid,
          schema: {
            ...schema,
            edges: edges.map(item => (item.id === edge.id ? edge : item))
          }
        }
      };
    }
    case ActionType.REMOVE_EDGE: {
      const { grid } = state;
      const { schema } = grid;
      const { edges } = schema;
      const edge = action.payload;
      return {
        ...state,
        grid: {
          ...grid,
          schema: {
            ...schema,
            edges: edges.filter(item => item.id !== edge.id)
          }
        }
      };
    }
    case ActionType.UPDATE_SCHEMA: {
      const { grid } = state;
      const schema = action.payload;
      return {
        ...state,
        grid: {
          ...grid,
          schema: {
            ...grid.schema,
            ...schema
          }
        }
      };
    }
    case ActionType.OPEN_SETTINGS_VIEW: {
      return {
        ...state,
        view: PowerGridView.SETTINGS
      };
    }
    case ActionType.OPEN_MAIN_VIEW: {
      return {
        ...state,
        view: PowerGridView.MAIN
      };
    }
    case ActionType.UPDATE: {
      const { grid } = state;
      return {
        ...state,
        grid: merge(grid, action.payload)
      };
    }
    case ActionType.SET_ACTIVE_FEATURE: {
      return {
        ...state,
        feature: action.payload
      };
    }
    case ActionType.SET_CONTEXT: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};
