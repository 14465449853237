// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';

import ClonePowerGridFeature from 'grid/feature/ClonePowerGridFeature';
import ExportPowerGridFeature from 'grid/feature/ExportPowerGridFeature';
import ExportToJsonFeature from 'grid/feature/ExportToJsonFeature';
import EmergencyFeature from 'grid/feature/EmergencyFeature';
import ImportFromJsonFeature from 'grid/feature/ImportFromJsonFeature';
import PowerGridModeFeature from 'grid/feature/PowerGridModeFeature';
import PowerFlowFeature from 'grid/feature/PowerFlowFeature';
import UpdateSchemaFeature from 'grid/feature/UpdateSchemaFeature';
import { usePowerGridContext } from 'grid/context';
import { PowerGridFeature } from 'grid/store';

const PowerGridActiveFeature: FunctionComponent = () => {
  const { feature } = usePowerGridContext();
  return (
    <>
      {feature === PowerGridFeature.CLONE && <ClonePowerGridFeature />}
      {feature === PowerGridFeature.EXPORT && <ExportPowerGridFeature />}
      {feature === PowerGridFeature.EMERGENCY && <EmergencyFeature />}
      {feature === PowerGridFeature.POWER_FLOW && <PowerFlowFeature />}
      {feature === PowerGridFeature.MODE && <PowerGridModeFeature />}
      {feature === PowerGridFeature.EXPORT_TO_JSON && <ExportToJsonFeature />}
      {feature === PowerGridFeature.UPDATE_SCHEMA && <UpdateSchemaFeature />}
      {feature === PowerGridFeature.IMPORT_FROM_JSON && (
        <ImportFromJsonFeature />
      )}
    </>
  );
};

export default PowerGridActiveFeature;
