// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { message } from 'antd';
import styled, { ThemeContext } from 'styled-components/macro';

import Button from 'components/button/Button';
import { Toolbar } from 'components/Layout';
import LoadingMask from 'components/loading/LoadingMask';
import Message from 'components/message/Message';
import { useLiquidateEmergency } from 'emergency/hooks';
import { usePowerGridContext, usePowerGridDispatch } from 'grid/context';
import { actions } from 'grid/store';
import EmergencyLiquidationReportModal from 'emergency/components/EmergencyLiquidationReportModal';
import { clearEmergency } from 'emergency/utils';

const EmergencyButton = styled(Button)`
  height: 32px;
  padding: 0 12px;
`;

const EmergencyFeature: FunctionComponent = () => {
  const theme = useContext(ThemeContext);
  const intl = useIntl();
  const { grid } = usePowerGridContext();
  const dispatch = usePowerGridDispatch();
  const [liquidateEmergency, { loading, error, data }] =
    useLiquidateEmergency();

  const onLiquidateEmergencyClick = () => {
    message.destroy();
    liquidateEmergency(grid.schema);
  };

  const onCancelLiquidationClick = () => {
    const schema = clearEmergency(grid.schema);
    dispatch(actions.updateSchema(schema));
    dispatch(actions.setActiveFeature());
  };

  useEffect(() => {
    message.error({
      duration: 0,
      content: (
        <Message type="error" theme={theme} onClose={onCancelLiquidationClick}>
          <Toolbar>
            <p className="edispa-body-four-light">
              {intl.formatMessage({
                id: 'message.emergency'
              })}
            </p>
            <EmergencyButton onClick={onLiquidateEmergencyClick}>
              {intl.formatMessage({
                id: 'button.liquidate'
              })}
            </EmergencyButton>
          </Toolbar>
        </Message>
      )
    });
  }, []);

  useEffect(() => {
    if (error) {
      message.error({
        duration: 0,
        content: (
          <Message type="error" closable onClose={onCancelLiquidationClick}>
            Произошла непредвиденная ошибка.
          </Message>
        )
      });
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      dispatch(actions.updateSchema(data.schema));
    }
  }, [data]);

  return (
    <>
      {loading && <LoadingMask />}
      {data && (
        <EmergencyLiquidationReportModal report={data.report} visible={true} />
      )}
    </>
  );
};

export default EmergencyFeature;
