// © ООО «Эдиспа», 2022

import { createGlobalStyle } from 'styled-components/macro';

import 'components/style/global.css';
import style from 'themes';

export default createGlobalStyle`
  .ant-form-item:last-child {
    margin-bottom: 0;
  }

  body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
  }

  #root {
    height: calc(100vh - calc(100vh - 100%));
  }

  div.ant-typography, .ant-typography p {
    margin-bottom: 0;
  }

  p.edispa-body-one-light {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: ${style('text.body.one.light.color')};
  }

  p.edispa-body-two-dark {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
  }

  p.edispa-body-four-light {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: ${style('text.body.four.light.color')};
  }

  p.edispa-label-one-dark {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: ${style('color.gray.one')};
  }

  p.edispa-caption-text-one-light, pre.edispa-caption-text-one-light {
    font-size: 14px;
    font-weight: 400;
    color: ${style('color.gray.two')}
  }
`;
