// © ООО «Эдиспа», 2022

import { ReactNode } from 'react';
import { createMutableContext } from 'utils/context';
import { PayloadAction } from 'utils/reducer';

enum ActionType {
  SET_CONTEXT = 'PAGE/SET_CONTEXT'
}

type RenderFunction = () => ReactNode;

export interface PageContext {
  route?: string;
  callback?: () => void;
  title?: ReactNode | RenderFunction;
}

type SetContextAction = PayloadAction<ActionType.SET_CONTEXT, PageContext>;

type Action = SetContextAction;

const reducer = (state: PageContext, action: Action): PageContext => {
  switch (action.type) {
    case ActionType.SET_CONTEXT: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

const { useContext, useDispatch, Provider } = createMutableContext<
  PageContext,
  Action
>(reducer);

export const usePageContext = useContext;

// TODO: rename to usePageContextDispatch and make sure other places follow the same pattern
export const useDispatchPageContext = useDispatch;

export const PageContextProvider = Provider;

export const actions = {
  setContext: (payload: PageContext): Action => ({
    type: ActionType.SET_CONTEXT,
    payload
  })
};
