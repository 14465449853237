// © ООО «Эдиспа», 2022

import React, { FunctionComponent, ReactNode } from 'react';
import { Tooltip } from 'antd';
import styled from 'styled-components/macro';

import { Toolbar } from 'components/Layout';
import { IconInfoCircleSmallSvg } from 'svg';

export interface LabelProps {
  value: ReactNode;
  info?: ReactNode;
}

const Icon = styled(IconInfoCircleSmallSvg)`
  // см. стили в components/form/Form
  pointer-events: all;
  margin-right: 4px;
  font-size: 16px;
`;

const Container = styled(Toolbar)`
  flex: 1;
  justify-content: space-between;
`;

const Label: FunctionComponent<LabelProps> = ({ value, info }) => (
  <Container>
    {value}
    {info && (
      <Tooltip title={info}>
        <Icon />
      </Tooltip>
    )}
  </Container>
);

export default Label;
