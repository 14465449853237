// © ООО «Эдиспа», 2022

import React, { FunctionComponent, memo } from 'react';
import { NodeProps } from 'react-flow-renderer';
import styled from 'styled-components/macro';

import { Node } from 'grid';
import NodeShape from 'grid/components/graph/NodeShape';
import { getNodeCustomColor } from 'grid/utils';
import style from 'themes';
import formatters from 'utils/format';

const PropName = styled.td`
  text-align: right;
  color: ${style('color.gray.two')};
`;

const PropValue = styled.td`
  text-align: left;
`;

const PropTable = styled.table`
  border-collapse: collapse;
  border-style: hidden;
  font-size: 10px;
`;

const GenerationNode: FunctionComponent<NodeProps<Node>> = ({
  data,
  selected
}) => (
  <NodeShape
    type={data.type}
    label={data.label}
    selected={selected}
    invalid={!!data.messages}
    customColor={getNodeCustomColor(data)}
  >
    <PropTable>
      <tbody>
        <tr>
          <PropName>U</PropName>
          <PropValue>{formatters.number(data.u)}</PropValue>
        </tr>
        <tr>
          <PropName>Pг</PropName>
          <PropValue>{formatters.number(data.pGen)}</PropValue>
        </tr>
        <tr>
          <PropName>Qг</PropName>
          <PropValue>{formatters.number(data.qGen)}</PropValue>
        </tr>
      </tbody>
    </PropTable>
  </NodeShape>
);

export default memo(GenerationNode);
