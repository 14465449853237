// © ООО «Эдиспа», 2022

import styled from 'styled-components/macro';

export const SettingsViewContainer = styled.div`
  padding: 0 24px;
  > * {
    margin-bottom: 24px;
  }
`;
