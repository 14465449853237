// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';

import { ViewType } from 'auth/action/store';
import EmailVerificationConfirmationView from 'auth/action/components/EmailVerificationConfirmationView';
import ErrorView from 'auth/action/components/ErrorView';
import NewPasswordView from 'auth/action/components/NewPasswordView';
import NewPasswordConfirmationView from 'auth/action/components/NewPasswordConfirmationView';
import { useContext } from 'auth/action/context';

const ActiveView: FunctionComponent = () => {
  const { view, type, error } = useContext();

  if (error) {
    return <ErrorView type={type} error={error} />;
  }

  return (
    <>
      {view === ViewType.EMAIL_VERIFICATION_CONFIRMATION && (
        <EmailVerificationConfirmationView />
      )}
      {view === ViewType.NEW_PASSWORD && <NewPasswordView />}
      {view === ViewType.NEW_PASSWORD_CONFIRMATION && (
        <NewPasswordConfirmationView />
      )}
    </>
  );
};

export default ActiveView;
