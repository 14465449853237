// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { RouteProps } from 'react-router';

import { UserContextProvider } from 'auth/context';
import { useCurrentUser } from 'auth/hooks';
import Loading from 'components/loading/Loading';
import { Route } from 'router';
import UserContextRoute from 'router/UserContextRoute';

const PrivateRoute: FunctionComponent<RouteProps> = props => {
  const { authenticating, context } = useCurrentUser();
  const { pathname } = useLocation();

  if (authenticating) {
    return <Loading />;
  } else if (!context) {
    return (
      <Navigate
        to={
          pathname === Route.HOME
            ? Route.LOGIN
            : `${Route.LOGIN}?redirectUrl=${pathname}`
        }
      />
    );
  }

  return (
    <UserContextProvider value={context}>
      <UserContextRoute {...props} />
    </UserContextProvider>
  );
};

export default PrivateRoute;
