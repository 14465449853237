// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';

import messages from 'i18n/messages/ru.json';

const IntlProvider: FunctionComponent = ({ children }) => {
  return (
    <ReactIntlProvider locale="ru" messages={messages}>
      {children}
    </ReactIntlProvider>
  );
};

export default IntlProvider;
