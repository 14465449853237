// © ООО «Эдиспа», 2022

import { useMemo } from 'react';
import { DocumentSnapshot, Timestamp } from 'firebase/firestore';
import { v4 } from 'uuid';

import { useUserContext } from 'auth/context';
import { PowerGrid, PowerGridMode } from 'grid';
import { CodeError } from 'utils/CodeError';

const useDocumentConverter = () => {
  const { user } = useUserContext();
  const { uid } = user;
  return useMemo(() => {
    return {
      toPowerGrid: (doc: DocumentSnapshot): PowerGrid => {
        const data = doc.data();
        if (!data) {
          throw new CodeError('edispa/power-grid-not-found');
        }
        const {
          title,
          description,
          schema,
          createdAt,
          updatedAt,
          owner,
          scope,
          mode
        } = data;
        // TODO: temporary - remove after data migration
        const { edges } = schema;
        edges.forEach((edge: any) => {
          if (!edge.id) {
            edge.id = v4();
          }
        });
        return {
          id: doc.id,
          title,
          description,
          schema,
          owner,
          scope,
          mode: owner.id === uid ? mode : PowerGridMode.OPERATION,
          createdAt: (createdAt as Timestamp).toMillis(),
          updatedAt: updatedAt && (updatedAt as Timestamp).toMillis()
        } as PowerGrid;
      }
    };
  }, [uid]);
};

export default useDocumentConverter;
