// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { Style } from '@react-pdf/types';

import Summary from 'components/pdf/summary/Summary';
import { OverloadedEdge } from 'emergency/report';

interface OverloadedEdgeSummaryProps {
  edge: OverloadedEdge;
  style?: Style;
}

const OverloadedEdgeSummary: FunctionComponent<OverloadedEdgeSummaryProps> = ({
  edge,
  style
}) => (
  <Summary<OverloadedEdge>
    style={style}
    record={edge}
    columns={[
      {
        dataIndex: 'iSrc',
        title: 'Iнач',
        align: 'left'
      },
      {
        dataIndex: 'iMaxSrc',
        title: 'Iмакс.н',
        align: 'left'
      },
      {
        dataIndex: 'iTgt',
        title: 'Iкон',
        align: 'left'
      },
      {
        dataIndex: 'iMaxTgt',
        title: 'Iмакс.к',
        align: 'left'
      },
      {
        dataIndex: 'loadFactorSrc',
        title: 'Кзагр.н',
        align: 'left'
      },
      {
        dataIndex: 'loadFactorTgt',
        title: 'Kзагр.к',
        align: 'left'
      }
    ]}
  />
);

export default OverloadedEdgeSummary;
