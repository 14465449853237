// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useLayoutEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';

import { signOut } from 'auth/utils';
import Alert from 'components/Alert';
import Container from 'auth/components/Container';
import { Markdown } from 'auth/components/Typography';
import { useDispatchUserContext, actions } from 'auth/context';
import { useAcceptTerms } from 'auth/hooks';
import userAgreementFile from 'auth/userAgreement.md';
import Button from 'components/button/Button';
import SubmitButton from 'components/button/SubmitButton';
import style from 'themes';

const Footer = styled.div`
  ${Button} + ${Button} {
    margin-left: 16px;
  }
  white-space: nowrap;
`;

const Content = styled.div`
  height: 100%; // фикс для Safari
  position: absolute;
  padding: 228px 0 80px 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: grid;
  justify-content: center;
  grid-template-columns: minmax(16px, 1fr) minmax(min-content, 640px) minmax(
      16px,
      1fr
    );
  grid-template-areas: '. error .' '. content .' '. footer .';

  ${Alert} {
    grid-area: error;
    margin-bottom: 40px;
  }
  ${Markdown} {
    grid-area: content;
    h1 {
      color: ${style('auth.color')};
    }
    overflow: auto;
  }
  ${Footer} {
    grid-area: footer;
    margin-top: 40px;
  }
`;

const UserAgreementPage: FunctionComponent = () => {
  const [acceptTerms, { loading, error, data }] = useAcceptTerms();
  const dispatch = useDispatchUserContext();
  const [userAgreement, setUserAgreement] = useState('');

  const onDeclineClick = () => signOut();

  const onAcceptClick = () => acceptTerms();

  useLayoutEffect(() => {
    if (data) {
      dispatch(actions.acceptTerms());
    }
  }, [data, dispatch]);

  useLayoutEffect(() => {
    const loadUserAgrementFile = async () => {
      try {
        const response = await fetch(userAgreementFile);
        const text = await response.text();
        setUserAgreement(text);
      } catch (error: any) {
        // TODO: show generic error page
      }
    };

    loadUserAgrementFile();
  }, [setUserAgreement]);

  return (
    <Container>
      {userAgreement && (
        <Content>
          <Alert error={error} />
          <Markdown>{userAgreement}</Markdown>
          <Footer>
            <SubmitButton
              htmlType="button"
              onClick={onAcceptClick}
              loading={loading}
            >
              <FormattedMessage id="button.accept" />
            </SubmitButton>
            <Button onClick={onDeclineClick}>
              <FormattedMessage id="button.decline" />
            </Button>
          </Footer>
        </Content>
      )}
    </Container>
  );
};

export default UserAgreementPage;
