// © ООО «Эдиспа», 2022

import React, {
  CSSProperties,
  DragEvent,
  FunctionComponent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import ReactFlow, {
  Connection,
  ConnectionLineType,
  ConnectionMode,
  Controls,
  FlowElement,
  isEdge as isReactFlowEdge,
  isNode as isReactFlowNode,
  Node as ReactFlowNode,
  Edge as ReactFlowEdge,
  OnLoadParams,
  Elements,
  useZoomPanHelper
} from 'react-flow-renderer';
import styled, { css } from 'styled-components/macro';
import { isNumber } from 'lodash';
// TODO: use factory
import { v4 } from 'uuid';

import {
  Edge,
  EdgeLayout,
  isNodeType,
  Node,
  NodeLayout,
  NodeType,
  PortPosition,
  PowerGridMode
} from 'grid';
import BalanceNode from 'grid/components/graph/BalanceNode';
import FlowEdge from 'grid/components/graph/FlowEdge';
import GenerationNode from 'grid/components/graph/GenerationNode';
import LoadNode from 'grid/components/graph/LoadNode';
import NodeListView from 'grid/components/graph/NodeListView';
import PropertiesView from 'grid/components/PropertiesView';
import { usePowerGridContext, usePowerGridDispatch } from 'grid/context';
import { actions } from 'grid/store';
import {
  denormalize,
  getGraphEdgeLabel,
  hasLayout,
  normalize
} from 'grid/utils';
import style from 'themes';

interface ContainerProps {
  mode: PowerGridMode;
}

const Container = styled.div<ContainerProps>`
  display: grid;
  grid-area: content;
  grid-template-rows: 1fr;
  ${props =>
    props.mode === PowerGridMode.CONFIGURATION
      ? css`
          grid-template-areas: 'node-list graph properties';
          grid-template-columns: 248px 1fr 248px;
          .react-flow__node {
            cursor: move;
          }
        `
      : css`
          grid-template-areas: 'graph properties';
          grid-template-columns: 1fr 248px;
          // в экскплуатации любые изменения лэйаута запрещены
          .react-flow__edgeupdater,
          .react-flow__handle {
            pointer-events: none;
          }
          .react-flow__node {
            cursor: pointer;
          }
        `}
  overflow: auto;

  // Для более удобного выделения неактивных ветвей нам необходимо
  // переопределить поведение по умолчанию (pointer-events: none).
  // Это может помешать выбору конца ветви для его перемещения в
  // случае, если в один порт узла сходятся несколько ветвей. С другой
  // стороны, поведение по умолчанию блокирует курсор мыши, что
  // затрудняет выбор неактивной ветви. Так как лэйаут ветвей
  // автоматически перестраивается при перетаскивании узлов, а в
  // эксплуатации не доступен к редактированию, и выбор ветви для
  // просмотра и редактирования ее свойств является единственно
  // доступным действием, то мы выбираем удобство выделения ветви
  // в ущерб ее перетаскиванию.
  .react-flow__edge.inactive {
    pointer-events: all;
  }
  // Перетаскивание концов неактивной ветви происходит без ее выбора.
  // Чтобы запретить это поведение, мы блокируем концы ветви до ее
  // выделения.
  .react-flow__edge.inactive .react-flow__edgeupdater {
    pointer-events: none;
  }
  .react-flow__edge.selected .react-flow__edge-path {
    stroke: ${style('graph.selected.color')};
  }
  .react-flow__edge-textbg {
    fill: #f4f3f5;
    cursor: pointer;
  }
`;

const ReactFlowWrapper = styled.div`
  grid-area: graph;
  position: relative;
`;

const nodeTypes = {
  [NodeType.GENERATION]: GenerationNode,
  [NodeType.LOAD]: LoadNode,
  [NodeType.BALANCE]: BalanceNode
};

enum SelectionType {
  NODE = 'node',
  EDGE = 'edge'
}

interface Selection {
  id: string;
  type: SelectionType;
}

enum EdgeType {
  FLOW = 'flow'
}

const edgeTypes = {
  [EdgeType.FLOW]: FlowEdge
};

export interface GraphViewProps {
  style?: CSSProperties;
}

const GraphView: FunctionComponent<GraphViewProps> = ({ style }) => {
  const wrapper = useRef<HTMLDivElement>(null);
  const [reactFlowInstance, setReactFlowInstance] = useState<OnLoadParams>();
  const { grid } = usePowerGridContext();
  const dispatch = usePowerGridDispatch();
  const { schema, mode } = grid;
  const { fitView } = useZoomPanHelper();
  const [selection, setSelection] = useState<Selection>();

  useEffect(() => {
    const handle = setTimeout(() => {
      fitView({
        padding: 0.1
      });
    }, 10);
    return () => {
      clearTimeout(handle);
    };
  }, [mode]);

  const getEdgeClassName = useCallback(
    (id: string) => {
      const isSelected =
        selection &&
        selection.type === SelectionType.EDGE &&
        selection.id === id;
      return isSelected ? 'active' : 'inactive';
    },
    [selection]
  );

  const onLoad = (reactFlowInstance: OnLoadParams) => {
    setReactFlowInstance(reactFlowInstance);
    fitView({
      padding: 0.1
    });
  };

  const elements = useMemo(() => {
    if (!hasLayout(schema)) {
      return [];
    }
    const { nodes, edges } = denormalize(schema);
    return nodes
      .reduce<any[]>((acc, node) => {
        const { id, layout, type } = node;
        if (layout) {
          acc.push({
            id: `node_${id}`,
            position: layout.position,
            width: 120,
            data: node,
            type
          });
        }
        return acc;
      }, [])
      .concat(
        edges.reduce<any[]>((acc, edge) => {
          const { id, source, target, pSrc, qSrc, layout, disabled } = edge;
          if (layout) {
            acc.push({
              id: `edge_${id}`,
              source: `node_${source.id}`,
              target: `node_${target.id}`,
              sourceHandle: layout.source,
              targetHandle: layout.target,
              label:
                !disabled &&
                isNumber(pSrc) &&
                isNumber(qSrc) &&
                mode === PowerGridMode.OPERATION
                  ? getGraphEdgeLabel(edge)
                  : null,
              data: edge,
              type: EdgeType.FLOW,
              className: getEdgeClassName(id)
            });
          }
          return acc;
        }, [])
      );
  }, [schema, mode, getEdgeClassName]);

  const onFitView = () => {
    fitView({
      padding: 0.1
    });
  };

  const onNodeDragStop = (
    _: MouseEvent,
    reactFlowNode: ReactFlowNode<Node>
  ): void => {
    const { data, position } = reactFlowNode;
    if (data) {
      dispatch(
        actions.updateNode({
          ...data,
          layout: {
            ...data.layout,
            position
          }
        })
      );
    }
  };

  const onNodeDrag = (
    _: MouseEvent,
    reactFlowNode: ReactFlowNode<Node>
  ): void => {
    const { data, position } = reactFlowNode;
    if (data) {
      const { id } = data;
      const { nodes, edges } = denormalize(schema);
      const newSchema = {
        ...schema,
        nodes: nodes.map(node =>
          node.id === id
            ? {
                ...node,
                layout: {
                  position
                }
              }
            : node
        ),
        edges: edges
          .map(edge => {
            const { source, target } = edge;
            if ((source.id === id || target.id === id) && !!edge.layout) {
              const sourcePosition = (source.layout as NodeLayout).position;
              const targetPosition = (target.layout as NodeLayout).position;
              // рассчитываем угол наклона прямой, соединяющей узлы начала и конца ветви [-π, π]
              const angle = Math.atan2(
                targetPosition.y - sourcePosition.y,
                targetPosition.x - sourcePosition.x
              );
              let layout: EdgeLayout;
              if (angle > -Math.PI / 4 && angle < Math.PI / 4) {
                layout = {
                  source: PortPosition.RIGHT,
                  target: PortPosition.LEFT
                };
              } else if (angle >= Math.PI / 4 && angle <= (3 * Math.PI) / 4) {
                layout = {
                  source: PortPosition.BOTTOM,
                  target: PortPosition.TOP
                };
              } else if (angle >= -(3 * Math.PI) / 4 && angle <= -Math.PI / 4) {
                layout = {
                  source: PortPosition.TOP,
                  target: PortPosition.BOTTOM
                };
              } else {
                layout = {
                  source: PortPosition.LEFT,
                  target: PortPosition.RIGHT
                };
              }

              return {
                ...edge,
                layout
              };
            }
            return edge;
          })
          .map(normalize)
      };
      dispatch(actions.updateSchema(newSchema));
    }
  };

  const onDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  const onDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    const el = wrapper.current;

    if (el && reactFlowInstance) {
      const reactFlowBounds = el.getBoundingClientRect();
      const data = event.dataTransfer.getData('application/reactflow');
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top
      });
      if (isNodeType(data)) {
        const newNode: Node = {
          id: v4(),
          label: String(schema.nodes.length + 1),
          type: data as NodeType,
          layout: {
            position
          }
        };
        dispatch(actions.addNode(newNode));
      } else {
        const oldNodes = schema.nodes;
        const oldEdges = schema.edges;

        const newNodes = oldNodes.map(node =>
          node.id === data
            ? {
                ...node,
                layout: {
                  position
                }
              }
            : node
        );

        const denormalized = denormalize({
          nodes: newNodes,
          edges: oldEdges
        });

        const newEdges = denormalized.edges
          .map(edge => {
            const { source, target } = edge;

            const shouldHaveLayout =
              (source.id === data || target.id === data) &&
              !!source.layout &&
              !!target.layout &&
              !edge.layout;

            if (shouldHaveLayout) {
              const sourcePosition = (source.layout as NodeLayout).position;
              const targetPosition = (target.layout as NodeLayout).position;
              // рассчитываем угол наклона прямой, соединяющей узлы начала и конца ветви [-π, π]
              const angle = Math.atan2(
                targetPosition.y - sourcePosition.y,
                targetPosition.x - sourcePosition.x
              );
              let layout: EdgeLayout;
              if (angle > -Math.PI / 4 && angle < Math.PI / 4) {
                layout = {
                  source: PortPosition.RIGHT,
                  target: PortPosition.LEFT
                };
              } else if (angle >= Math.PI / 4 && angle <= (3 * Math.PI) / 4) {
                layout = {
                  source: PortPosition.BOTTOM,
                  target: PortPosition.TOP
                };
              } else if (angle >= -(3 * Math.PI) / 4 && angle <= -Math.PI / 4) {
                layout = {
                  source: PortPosition.TOP,
                  target: PortPosition.BOTTOM
                };
              } else {
                layout = {
                  source: PortPosition.LEFT,
                  target: PortPosition.RIGHT
                };
              }

              return {
                ...edge,
                layout
              };
            }

            return edge;
          })
          .map(normalize);

        const newSchema = {
          ...schema,
          nodes: newNodes,
          edges: newEdges
        };

        dispatch(actions.updateSchema(newSchema));
      }
    }
  };

  const onEdgeUpdate = (
    reactFlowEdge: ReactFlowEdge<Edge>,
    newConnection: Connection
  ): void => {
    const { data } = reactFlowEdge;
    if (data) {
      const { source, target, sourceHandle, targetHandle } = newConnection;
      if (source && target) {
        dispatch(
          actions.updateEdge({
            ...data,
            layout: {
              source: sourceHandle as PortPosition,
              target: targetHandle as PortPosition
            }
          })
        );
      }
    }
  };

  const onConnect = (connection: ReactFlowEdge<Edge> | Connection): void => {
    const { sourceHandle, targetHandle } = connection;
    let { source, target } = connection;
    if (source && target) {
      if (source.startsWith('node_')) {
        source = source.replace('node_', '');
      }
      if (target.startsWith('node_')) {
        target = target.replace('node_', '');
      }
      dispatch(
        actions.addEdge({
          id: v4(),
          source,
          target,
          layout: {
            source: sourceHandle as PortPosition,
            target: targetHandle as PortPosition
          }
        })
      );
    }
  };

  const onElementsRemove =
    mode === PowerGridMode.CONFIGURATION
      ? (elements: Elements) => {
          elements.forEach((element: FlowElement) => {
            if (isReactFlowNode(element)) {
              const { data } = element;
              if (data) {
                dispatch(actions.removeNode(data as Node));
              }
            }
            if (isReactFlowEdge(element)) {
              const { data } = element;
              if (data) {
                dispatch(actions.removeEdge(data as Edge));
              }
            }
          });
        }
      : undefined;

  const onSelectionChange = (elements: Elements<Node | Edge> | null) => {
    const [selectedElement] = elements || [];
    if (selectedElement && selectedElement.data) {
      const { data } = selectedElement;
      setSelection({
        type: ((isReactFlowNode(selectedElement) && SelectionType.NODE) ||
          (isReactFlowEdge(selectedElement) &&
            SelectionType.EDGE)) as SelectionType,
        id: data.id
      });
    } else {
      setSelection(undefined);
    }
  };

  const { nodes, edges } = schema;

  const selectedNode = useMemo(
    () =>
      selection && selection.type === SelectionType.NODE
        ? nodes.find(node => node.id === selection.id)
        : undefined,
    [selection, nodes]
  );

  const selectedEdge = useMemo(
    () =>
      selection && selection.type === SelectionType.EDGE
        ? edges.find(edge => edge.id === selection.id)
        : undefined,
    [selection, edges]
  );

  const isConfigMode = mode === PowerGridMode.CONFIGURATION;

  return (
    <Container mode={mode} style={style}>
      {isConfigMode && <NodeListView selected={selectedNode} />}
      <ReactFlowWrapper ref={wrapper}>
        <ReactFlow
          style={{
            background: '#F4F3F5'
          }}
          elements={elements}
          minZoom={0.1}
          maxZoom={10}
          onNodeDrag={onNodeDrag}
          onNodeDragStop={onNodeDragStop}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          onEdgeUpdate={onEdgeUpdate}
          onElementsRemove={onElementsRemove}
          onConnect={onConnect}
          onLoad={onLoad}
          onDragOver={onDragOver}
          onDrop={onDrop}
          onSelectionChange={onSelectionChange}
          connectionLineType={ConnectionLineType.Straight}
          connectionMode={ConnectionMode.Loose}
          nodesDraggable={isConfigMode}
          nodesConnectable={isConfigMode}
          multiSelectionKeyCode={-1}
          selectionKeyCode={-1}
        >
          <Controls onFitView={onFitView} showInteractive={false} />
        </ReactFlow>
      </ReactFlowWrapper>
      <PropertiesView selection={selectedNode || selectedEdge} />
    </Container>
  );
};

export default GraphView;
