// © ООО «Эдиспа», 2022

import { InputNumber } from 'antd';
import styled from 'styled-components/macro';

import style from 'themes';

export default styled(InputNumber)`
  &.ant-input-number {
    width: 100%;
    border: none;
    border-radius: 8px;
    .ant-input-number-handler-wrap {
      display: none;
    }
    .ant-input-number-input {
      height: 40px;
      transition: none;
      border-radius: 8px;
      border: 1px solid transparent;
      background-color: ${style('input.background')};
      font-size: 16px;
      caret-color: ${style('input.caret.color')};
      :hover:not(:disabled) {
        border: 1px solid ${style('input.hover.border.color')};
      }
      :focus,
      :active:not(:disabled) {
        outline: none;
        border: 1px solid ${style('input.focus.border.color')};
      }
    }
  }
  &.ant-input-number:focus,
  &.ant-input-number-focused {
    box-shadow: none;
  }
  &.ant-input-number-disabled {
    .ant-input-number-input {
      background-color: ${style('input.disabled.background')};
    }
  }
`;
