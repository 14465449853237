// © ООО «Эдиспа», 2022

import { getAuth, signOut as firebaseAuthSignOut, User } from 'firebase/auth';

export const isAuthenticated = (): boolean => {
  return !!getAuth().currentUser;
};

export const signOut = (): void => {
  const auth = getAuth();
  firebaseAuthSignOut(auth);
};

/**
 * Возвращает текущего аутентифицированного пользователя. Не рекомендуется использовать
 * эту функцию в контексте, где пользователь может быть не аутентифицирован.
 */
export const getCurrentUser = (): User => {
  const auth = getAuth();
  return auth.currentUser as User;
};
