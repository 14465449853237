// © ООО «Эдиспа», 2022

export enum Route {
  HOME = '/',
  LOGIN = '/login',
  GRID_DETAILS = '/grids/:id',
  AUTH_ACTION = '/auth/action',
  SETTINGS = '/settings',
  HELP = '/docs'
}
