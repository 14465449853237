// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Checkbox, message } from 'antd';

import Alert from 'components/Alert';
import SubmitButton from 'components/button/SubmitButton';
import Form, { FormValues } from 'components/form/Form';
import { Toolbar } from 'components/Layout';
import Message from 'components/message/Message';
import { Title, Paragraph } from 'components/Typography';
import { useUpdatePowerGrid } from 'grid/hooks';
import { SettingsViewContainer } from 'grid/components/Layout';
import { usePowerGridContext, usePowerGridDispatch } from 'grid/context';
import { actions } from 'grid/store';
import { useUserContext } from 'auth/context';

const { Item } = Form;

const PowerGridAccessControlView: FunctionComponent = () => {
  const [form] = Form.useForm();
  const { grid } = usePowerGridContext();
  const dispatch = usePowerGridDispatch();
  const { user } = useUserContext();

  const [updatePowerGrid, { loading, error, data }] = useUpdatePowerGrid();

  const onFinish = (values: FormValues) => {
    const { grantAccess } = values;
    updatePowerGrid({
      id: grid.id,
      scope: grantAccess ? 'public' : user.uid
    });
  };

  useEffect(() => {
    if (data) {
      message.info({
        content: <Message type="info">Настройки обновлены.</Message>
      });
      const { updatedAt } = data;
      dispatch(
        actions.onUpdate({
          updatedAt
        })
      );
    }
  }, [data, dispatch]);

  return (
    <SettingsViewContainer>
      <Title>
        <FormattedMessage id="page.access.control.title" />
      </Title>
      <Paragraph className="edispa-body-one-light">
        <FormattedMessage id="page.access.control.description" />
      </Paragraph>
      <Alert error={error} />
      <Form
        form={form}
        initialValues={{
          grantAccess: grid.scope === 'public'
        }}
        onFinish={onFinish}
      >
        <Item name="grantAccess" valuePropName="checked">
          <Checkbox>
            <FormattedMessage id="label.grant.access" />
          </Checkbox>
        </Item>
        <Item>
          <Toolbar>
            <SubmitButton loading={loading}>
              <FormattedMessage id="button.save" />
            </SubmitButton>
          </Toolbar>
        </Item>
      </Form>
    </SettingsViewContainer>
  );
};

export default PowerGridAccessControlView;
