// © ООО «Эдиспа», 2022

import ReactMarkdown from 'react-markdown';
import styled from 'styled-components/macro';

export const Title = styled.h2`
  font-size: 32px;
  font-weight: 400;
`;

export const Paragraph = styled.p`
  font-size: 16px;
  font-weight: 300;
`;

export const Markdown = styled(ReactMarkdown)`
  font-size: 16px;
  font-weight: 300;
  strong {
    font-weight: 500;
  }
`;
