// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';

import { isEdge, isNode, Node, Edge } from 'grid';
import EdgeDetailsView from 'grid/components/graph/EdgeDetailsView';
import NodeDetailsView from 'grid/components/graph/NodeDetailsView';
import PowerGridPropertiesView from 'grid/components/PowerGridPropertiesView';

const Container = styled.div`
  width: 248px;
  flex-shrink: 0;
  display: block;
  grid-area: properties;
  overflow: auto;
`;

export interface PropertiesViewProps {
  selection?: Node | Edge;
}

const PropertiesView: FunctionComponent<PropertiesViewProps> = ({
  selection
}) => {
  return (
    <Container>
      {isNode(selection) && <NodeDetailsView node={selection} />}
      {isEdge(selection) && <EdgeDetailsView edge={selection} />}
      {!selection && <PowerGridPropertiesView />}
    </Container>
  );
};

export default PropertiesView;
