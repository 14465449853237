// © ООО «Эдиспа», 2022

import { User } from 'firebase/auth';

import { createConverter } from 'utils/firestore';

export interface UserProfile {
  organization: string;
  position: string;
  phone: string;
}

export interface UserState {
  acceptedTerms: boolean;
  active: boolean;
  disabled: boolean;
}

export type UserContext = {
  user: User;
  profile?: UserProfile;
} & UserState;

export const convert = {
  toUserProfile: createConverter<UserProfile>([
    'organization',
    'position',
    'phone'
  ]),
  toUserState: createConverter<UserState>([
    'acceptedTerms',
    'active',
    'disabled'
  ])
};
