// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { message } from 'antd';

import Alert from 'components/Alert';
import SubmitButton from 'components/button/SubmitButton';
import { FormValues } from 'components/form/Form';
import { Toolbar } from 'components/Layout';
import Message from 'components/message/Message';
import { Title } from 'components/Typography';
import { useUpdatePowerGrid } from 'grid/hooks';
import { SettingsViewContainer } from 'grid/components/Layout';
import PowerGridDetailsForm from 'grid/components/PowerGridDetailsForm';
import { usePowerGridContext, usePowerGridDispatch } from 'grid/context';
import { actions } from 'grid/store';

const PowerGridCommonSettingsView: FunctionComponent = () => {
  const { grid } = usePowerGridContext();
  const dispatch = usePowerGridDispatch();
  const [updatePowerGrid, { loading, error, data }] = useUpdatePowerGrid();

  const onFinish = (values: FormValues) => {
    const { title, description } = values;
    updatePowerGrid({
      id: grid.id,
      title,
      description
    });
  };

  useEffect(() => {
    if (data) {
      message.info({
        content: <Message type="info">Настройки обновлены.</Message>
      });
      const { title, description, updatedAt } = data;
      dispatch(
        actions.onUpdate({
          title,
          description,
          updatedAt
        })
      );
    }
  }, [dispatch, data]);

  return (
    <SettingsViewContainer>
      <Title>
        <FormattedMessage id="page.power.grid.common.settings.title" />
      </Title>
      <Alert error={error} />
      <PowerGridDetailsForm
        requiredMark={false}
        initialValues={{
          title: grid.title,
          description: grid.description
        }}
        onFinish={onFinish}
        footer={
          <Toolbar>
            <SubmitButton loading={loading}>
              <FormattedMessage id="button.save" />
            </SubmitButton>
          </Toolbar>
        }
      />
    </SettingsViewContainer>
  );
};

export default PowerGridCommonSettingsView;
