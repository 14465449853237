// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Empty } from 'antd';
import { ColumnProps, TableProps } from 'antd/lib/table';
import { isBoolean, isNumber, isUndefined } from 'lodash';

import Label from 'components/form/Label';
import { Edge, Node, PowerGridMode } from 'grid';
import Table, {
  DEFAULT_PAGE_SIZE,
  useRenderWithMessage
} from 'grid/components/Table';
import { usePowerGridContext } from 'grid/context';
import { getLoadFactorSrc, getLoadFactorTgt } from 'grid/utils';
import formatters from 'utils/format';
import { compare, compareValues, sorter } from 'utils/table';

export interface EdgeTableProps {
  extraColumns: ColumnProps<Edge>[];
}

interface PaginationProps {
  page: number;
}

type NodeMap = { [key: string]: Node };

const EdgeTable: FunctionComponent<EdgeTableProps & TableProps<Edge>> = ({
  extraColumns,
  ...otherProps
}) => {
  const renderWithMessage = useRenderWithMessage<Edge>();
  const [paginationProps, setPaginationProps] = useState<PaginationProps>({
    page: 1
  });
  const { grid } = usePowerGridContext();
  const { schema, mode } = grid;

  const onPaginationChange = (page: number) =>
    setPaginationProps({
      page
    });

  const nodes = useMemo<NodeMap>(() => {
    const { nodes } = schema;
    return nodes.reduce<{ [key: string]: Node }>((acc, node) => {
      acc[node.id] = node;
      return acc;
    }, {});
  }, [schema]);

  const { edges } = schema;

  const layout =
    mode === PowerGridMode.CONFIGURATION
      ? [
          'index',
          'source',
          'target',
          'iMaxSrc',
          'iMaxTgt',
          'r',
          'x',
          'gc',
          'bc',
          'kt',
          'disabled'
        ]
      : [
          'index',
          'source',
          'target',
          'iSrc',
          'iTgt',
          'iMaxSrc',
          'iMaxTgt',
          'loadFactorSrc',
          'loadFactorTgt',
          'pSrc',
          'pTgt',
          'qSrc',
          'qTgt',
          'sSrc',
          'sTgt',
          'uSrc',
          'uTgt',
          'r',
          'x',
          'gc',
          'bc',
          'kt',
          'disabled'
        ];

  const onInvalidCell = (path: string) => (record: Edge) => {
    const { messages } = record;
    return messages && messages[path] ? { className: 'invalid' } : {};
  };

  const columns = useMemo<ColumnProps<Edge>[]>(() => {
    const columns: { [key: string]: ColumnProps<Edge> } = {
      index: {
        key: 'index',
        render: (_: string, edge: Edge, index: number) =>
          (paginationProps.page - 1) * DEFAULT_PAGE_SIZE + index + 1,
        align: 'right',
        width: 40,
        fixed: 'left'
      },
      source: {
        dataIndex: 'source',
        title: (
          <Label
            value={<FormattedMessage id="edge.prop.source.label" />}
            info={<FormattedMessage id="edge.prop.source.description" />}
          />
        ),
        render: (id: string) => {
          const node = nodes[id];
          return node ? node.label : null;
        },
        sorter: ({ source: a }: Edge, { source: b }: Edge) =>
          compare<Node, 'label'>(nodes[a], nodes[b], 'label'),
        ellipsis: true,
        width: 150,
        fixed: 'left'
      },
      target: {
        dataIndex: 'target',
        title: (
          <Label
            value={<FormattedMessage id="edge.prop.target.label" />}
            info={<FormattedMessage id="edge.prop.target.description" />}
          />
        ),
        render: (id: string) => {
          const node = nodes[id];
          return node ? node.label : null;
        },
        sorter: ({ target: a }: Edge, { target: b }: Edge) =>
          compare<Node, 'label'>(nodes[a], nodes[b], 'label'),
        ellipsis: true,
        width: 150,
        fixed: 'left'
      },
      r: {
        dataIndex: 'r',
        title: (
          <Label
            value={<FormattedMessage id="edge.prop.r.label" />}
            info={<FormattedMessage id="edge.prop.r.description" />}
          />
        ),
        render: renderWithMessage(formatters.number, 'r'),
        sorter: sorter<Edge>('r'),
        align: 'right',
        width: 100,
        onCell: onInvalidCell('r')
      },
      x: {
        dataIndex: 'x',
        title: (
          <Label
            value={<FormattedMessage id="edge.prop.x.label" />}
            info={<FormattedMessage id="edge.prop.x.description" />}
          />
        ),
        render: renderWithMessage(formatters.number, 'x'),
        sorter: sorter<Edge>('x'),
        align: 'right',
        width: 100,
        onCell: onInvalidCell('x')
      },
      gc: {
        dataIndex: 'gc',
        title: (
          <Label
            value={<FormattedMessage id="edge.prop.gc.label" />}
            info={<FormattedMessage id="edge.prop.gc.description" />}
          />
        ),
        render: renderWithMessage(formatters.number, 'gc'),
        sorter: sorter<Edge>('gc'),
        align: 'right',
        width: 100,
        onCell: onInvalidCell('gc')
      },
      bc: {
        dataIndex: 'bc',
        title: (
          <Label
            value={<FormattedMessage id="edge.prop.bc.label" />}
            info={<FormattedMessage id="edge.prop.bc.description" />}
          />
        ),
        render: renderWithMessage(formatters.number, 'bc'),
        sorter: sorter<Edge>('bc'),
        align: 'right',
        width: 100,
        onCell: onInvalidCell('bc')
      },
      kt: {
        dataIndex: 'kt',
        title: (
          <Label
            value={<FormattedMessage id="edge.prop.kt.label" />}
            info={<FormattedMessage id="edge.prop.kt.description" />}
          />
        ),
        render: renderWithMessage(formatters.number, 'kt'),
        sorter: sorter<Edge>('kt'),
        align: 'right',
        width: 100,
        onCell: onInvalidCell('kt')
      },
      disabled: {
        dataIndex: 'disabled',
        title: (
          <Label value={<FormattedMessage id="label.edge.active.state" />} />
        ),
        render: renderWithMessage(value => {
          if (isUndefined(value)) {
            return 'Вкл.';
          } else if (isBoolean(value)) {
            return !value ? 'Вкл.' : 'Выкл.';
          }
        }, 'disabled'),
        width: 100,
        sorter: sorter<Edge>('disabled'),
        onCell: onInvalidCell('disabled')
      },
      iMaxSrc: {
        dataIndex: 'iMaxSrc',
        title: (
          <Label
            value={<FormattedMessage id="edge.prop.i.max.src.label" />}
            info={<FormattedMessage id="edge.prop.i.max.src.description" />}
          />
        ),
        render: renderWithMessage(formatters.number, 'iMaxSrc'),
        sorter: sorter<Edge>('iMaxSrc'),
        align: 'right',
        width: 110,
        onCell: onInvalidCell('iMaxSrc')
      },
      iMaxTgt: {
        dataIndex: 'iMaxTgt',
        title: (
          <Label
            value={<FormattedMessage id="edge.prop.i.max.tgt.label" />}
            info={<FormattedMessage id="edge.prop.i.max.tgt.description" />}
          />
        ),
        render: renderWithMessage(formatters.number, 'iMaxTgt'),
        sorter: sorter<Edge>('iMaxTgt'),
        align: 'right',
        width: 110,
        onCell: onInvalidCell('iMaxTgt')
      },
      iSrc: {
        dataIndex: 'iSrc',
        title: (
          <Label
            value={<FormattedMessage id="edge.prop.i.src.label" />}
            info={<FormattedMessage id="edge.prop.i.src.description" />}
          />
        ),
        render: renderWithMessage(formatters.number, 'iSrc'),
        sorter: sorter<Edge>('iSrc'),
        align: 'right',
        width: 100,
        onCell: onInvalidCell('iSrc')
      },
      iTgt: {
        dataIndex: 'iTgt',
        title: (
          <Label
            value={<FormattedMessage id="edge.prop.i.tgt.label" />}
            info={<FormattedMessage id="edge.prop.i.tgt.description" />}
          />
        ),
        render: renderWithMessage(formatters.number, 'iTgt'),
        sorter: sorter<Edge>('iTgt'),
        align: 'right',
        width: 100,
        onCell: onInvalidCell('iTgt')
      },
      loadFactorSrc: {
        key: 'loadFactorSrc',
        title: (
          <Label
            value={<FormattedMessage id="edge.prop.load.factor.source.label" />}
            info={
              <FormattedMessage id="edge.prop.load.factor.source.description" />
            }
          />
        ),
        align: 'right',
        render: renderWithMessage((edge: Edge) => {
          const value = getLoadFactorSrc(edge);
          return isNumber(value) ? `${value.toFixed(2)}%` : '';
        }, 'loadFactorSrc'),
        sorter: (a: Edge, b: Edge) =>
          compareValues(getLoadFactorSrc(a), getLoadFactorSrc(b)),
        width: 110,
        onCell: onInvalidCell('loadFactorSrc')
      },
      loadFactorTgt: {
        key: 'loadFactorTgt',
        title: (
          <Label
            value={<FormattedMessage id="edge.prop.load.factor.target.label" />}
            info={
              <FormattedMessage id="edge.prop.load.factor.target.description" />
            }
          />
        ),
        align: 'right',
        render: renderWithMessage((edge: Edge) => {
          const value = getLoadFactorTgt(edge);
          return isNumber(value) ? `${value.toFixed(2)}%` : '';
        }, 'loadFactorTgt'),
        sorter: (a: Edge, b: Edge) =>
          compareValues(getLoadFactorTgt(a), getLoadFactorTgt(b)),
        width: 110,
        onCell: onInvalidCell('loadFactorTgt')
      },
      pSrc: {
        dataIndex: 'pSrc',
        title: (
          <Label
            value={<FormattedMessage id="edge.prop.p.src.label" />}
            info={<FormattedMessage id="edge.prop.p.src.description" />}
          />
        ),
        render: formatters.number,
        sorter: sorter<Edge>('pSrc'),
        align: 'right',
        width: 100
      },
      pTgt: {
        dataIndex: 'pTgt',
        title: (
          <Label
            value={<FormattedMessage id="edge.prop.p.tgt.label" />}
            info={<FormattedMessage id="edge.prop.p.tgt.description" />}
          />
        ),
        render: formatters.number,
        sorter: sorter<Edge>('pTgt'),
        align: 'right',
        width: 100
      },
      qSrc: {
        dataIndex: 'qSrc',
        title: (
          <Label
            value={<FormattedMessage id="edge.prop.q.src.label" />}
            info={<FormattedMessage id="edge.prop.q.src.description" />}
          />
        ),
        render: formatters.number,
        sorter: sorter<Edge>('qSrc'),
        align: 'right',
        width: 100
      },
      qTgt: {
        dataIndex: 'qTgt',
        title: (
          <Label
            value={<FormattedMessage id="edge.prop.q.tgt.label" />}
            info={<FormattedMessage id="edge.prop.q.tgt.description" />}
          />
        ),
        render: formatters.number,
        sorter: sorter<Edge>('qTgt'),
        align: 'right',
        width: 100
      },
      sSrc: {
        dataIndex: 'sSrc',
        title: (
          <Label
            value={<FormattedMessage id="edge.prop.s.src.label" />}
            info={<FormattedMessage id="edge.prop.s.src.description" />}
          />
        ),
        render: formatters.number,
        sorter: sorter<Edge>('sSrc'),
        align: 'right',
        width: 100
      },
      sTgt: {
        dataIndex: 'sTgt',
        title: (
          <Label
            value={<FormattedMessage id="edge.prop.s.tgt.label" />}
            info={<FormattedMessage id="edge.prop.s.tgt.description" />}
          />
        ),
        render: formatters.number,
        sorter: sorter<Edge>('sTgt'),
        align: 'right',
        width: 100
      },
      uSrc: {
        dataIndex: 'uSrc',
        title: (
          <Label
            value={<FormattedMessage id="edge.prop.u.src.label" />}
            info={<FormattedMessage id="edge.prop.u.src.description" />}
          />
        ),
        render: formatters.number,
        sorter: sorter<Edge>('uSrc'),
        align: 'right',
        width: 100
      },
      uTgt: {
        dataIndex: 'uTgt',
        title: (
          <Label
            value={<FormattedMessage id="edge.prop.u.tgt.label" />}
            info={<FormattedMessage id="edge.prop.u.tgt.description" />}
          />
        ),
        render: formatters.number,
        sorter: sorter<Edge>('uTgt'),
        align: 'right',
        width: 100
      }
    };
    return [...layout.map(id => columns[id]), ...extraColumns];
  }, [extraColumns, nodes]);

  return (
    <Table<Edge>
      showSorterTooltip={false}
      dataSource={edges}
      rowKey="id"
      size="small"
      pagination={{
        current: paginationProps.page,
        pageSize: DEFAULT_PAGE_SIZE,
        onChange: onPaginationChange,
        showSizeChanger: false,
        showTotal: (total, range) => `${range[0]}-${range[1]} из ${total}`
      }}
      columns={columns}
      scroll={{
        x: 'max-content'
      }}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<FormattedMessage id="label.empty" />}
          />
        )
      }}
      rowClassName={record => (!!record.messages ? 'invalid' : '')}
      {...otherProps}
    />
  );
};

export default EdgeTable;
