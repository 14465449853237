// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';

import Alert from 'components/Alert';
import Form from 'auth/components/form/Form';
import Input from 'auth/components/input/Input';
import { Paragraph, Title } from 'auth/components/Typography';
import { useSendPasswordResetEmail } from 'auth/hooks';
import { useDispatch } from 'auth/ui/context';
import { actions, ViewType } from 'auth/ui/store';
import Button from 'components/button/Button';
import SubmitButton from 'components/button/SubmitButton';

const { Item } = Form;

const Container = styled.div`
  max-width: 400px;
  margin: 0 auto;
  ${Title}, ${Paragraph}, ${Alert} {
    margin-bottom: 32px;
  }
`;

const Footer = styled.div`
  display: flex;
  ${Button} {
    flex: 1;
  }
  ${Button} + ${Button} {
    margin-left: 16px;
  }
`;

const ResetPasswordView: FunctionComponent = () => {
  const dispatch = useDispatch();

  const [sendPasswordResetEmail, { loading, error, data }] =
    useSendPasswordResetEmail();

  const onFinish = (values: any) => {
    const { email } = values;
    sendPasswordResetEmail({
      email,
      continueUrl: window.location.href
    });
  };

  const onCancel = () => dispatch(actions.setView(ViewType.LOGIN));

  useEffect(() => {
    if (data) {
      dispatch(actions.confirmPasswordReset(data));
    }
  }, [dispatch, data]);

  return (
    <Container>
      <Title>
        <FormattedMessage id="auth.password.reset.view.title" />
      </Title>
      <Paragraph>
        <FormattedMessage id="auth.password.reset.view.instructions" />
      </Paragraph>
      <Alert error={error} />
      <Form
        onFinish={onFinish}
        initialValues={{
          email: ''
        }}
      >
        <Item
          name="email"
          rules={[{ required: true }, { type: 'email' }]}
          label={<FormattedMessage id="form.label.email" />}
        >
          <Input />
        </Item>
        <Item noStyle>
          <Footer>
            <SubmitButton loading={loading}>
              <FormattedMessage id="button.send" />
            </SubmitButton>
            <Button onClick={onCancel}>
              <FormattedMessage id="button.cancel" />
            </Button>
          </Footer>
        </Item>
      </Form>
    </Container>
  );
};

export default ResetPasswordView;
