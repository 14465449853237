// © ООО «Эдиспа», 2022

import styled from 'styled-components/macro';

import style from 'themes';

import { UserDarkSvg } from 'svg';

const UserIcon = styled(UserDarkSvg)`
  cursor: pointer;
  .ed-shape {
    pointer-events: none;
  }
  .ed-background {
    :hover {
      opacity: 1;
      fill: ${style('icon.hover.color')};
    }
  }
`;

export default UserIcon;
