import React, { DragEvent, FunctionComponent } from 'react';
import styled from 'styled-components/macro';

import { NodeType } from 'grid';
import NodeShape from 'grid/components/graph/NodeShape';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 16px;
  > * {
    margin-top: 16px;
  }
`;

const onDragStart = (event: DragEvent<HTMLDivElement>, nodeType: NodeType) => {
  event.dataTransfer.setData('application/reactflow', nodeType);
  event.dataTransfer.effectAllowed = 'move';
};

const NewNodeList: FunctionComponent = () => {
  return (
    <Container>
      <NodeShape
        type={NodeType.LOAD}
        label="Нагрузка"
        className="dndnode"
        onDragStart={event => onDragStart(event, NodeType.LOAD)}
        draggable
      />
      <NodeShape
        type={NodeType.GENERATION}
        label="Генерация"
        className="dndnode"
        onDragStart={event => onDragStart(event, NodeType.GENERATION)}
        draggable
      />
      <NodeShape
        type={NodeType.BALANCE}
        label="Баланс"
        className="dndnode"
        onDragStart={(event: any) => onDragStart(event, NodeType.BALANCE)}
        draggable
      />
    </Container>
  );
};

export default NewNodeList;
