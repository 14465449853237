// © ООО «Эдиспа», 2022

import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { Table, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { isNil, isNumber, isPlainObject } from 'lodash';
import styled, { css } from 'styled-components/macro';

import { Messages } from 'grid';
import { IconExclamationCircleSvg } from 'svg';
import style from 'themes';

export const DEFAULT_PAGE_SIZE = 50;
const HEADER_HEIGHT = 44;
const PAGINATION_HEIGHT = 56;

// Ant Pagination#hideOnSinglePage полностью скрывает компонент,
// а нам нужно показывать общее количество записей даже в случае
// одной страницы. Поэтому мы не используем hideOnSinglePage,
// а частично скрываем компонент при помощи CSS, оставляя общее
// количество записей.
const StyledTable = styled(Table).attrs(props => {
  const { scroll, pagination, ...otherProps } = props;
  if (scroll && isPlainObject(scroll) && isNumber(scroll.y)) {
    const paginationHeight = pagination ? PAGINATION_HEIGHT : 0;
    return {
      ...otherProps,
      scroll: {
        ...scroll,
        y: scroll.y - HEADER_HEIGHT - paginationHeight
      }
    };
  }
  return props;
})`
  ${props => {
    const { dataSource } = props;
    if (dataSource && dataSource.length < DEFAULT_PAGE_SIZE) {
      return css`
        .ant-pagination-prev,
        .ant-pagination-item,
        .ant-pagination-next {
          display: none;
        }
      `;
    }
  }}
  .ant-table {
    line-height: 24px;
    border: 1px solid #f3f3f4;
    border-radius: 8px;
    overflow: hidden;
  }
  .ant-table-row.invalid {
    .ant-table-cell {
      background: ${style('color.red.four')};
    }
    .ant-table-cell.invalid {
      color: ${style('color.red.one')};
    }
  }
  .ant-table-small .ant-table-thead > tr > th {
    background-color: #fff;
    :hover {
      background: #fafafa;
    }
  }
  .ant-table-column-sorter-inner {
    vertical-align: middle;
  }
` as typeof Table;

export default StyledTable;

const ErrorIcon = styled(IconExclamationCircleSvg)`
  font-size: 20px;
  color: ${style('color.red.one')};
`;

type RenderFunction<T> = Required<ColumnProps<T>>['render'];

export function useRenderWithMessage<
  T extends {
    messages?: Messages;
  }
>() {
  const intl = useIntl();

  return useCallback(
    (render: RenderFunction<T>, path: keyof T): RenderFunction<T> => {
      const wrapper: RenderFunction<T> = (value, record, index) => {
        const { messages } = record;
        if (messages) {
          const message = messages[path as string];
          if (message) {
            const title = intl.formatMessage(
              { id: `validation/${message.code}` },
              message.details
            );
            const rendered = render(value, record, index);
            return (
              <Tooltip title={title}>
                {isNil(rendered) ? <ErrorIcon /> : rendered}
              </Tooltip>
            );
          }
        }
        return render(value, record, index);
      };
      return wrapper;
    },
    [intl]
  );
}
