// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { User } from 'firebase/auth';
import styled from 'styled-components/macro';

import ErrorView from 'auth/components/ErrorView';
import TroubleshootEmailModal from 'auth/components/TroubleshootEmailModal';
import { Title } from 'auth/components/Typography';
import { useSendEmailVerification } from 'auth/hooks';
import Button from 'components/button/Button';
import LinkButton from 'auth/components/button/LinkButton';
import MarkdownMessage from 'components/i18n/MarkdownMessage';
import { Row } from 'components/Layout';
import { AuthEmailSentSvg } from 'svg';

const Container = styled.div`
  max-width: 640px;
  margin: 0 auto;
  ${LinkButton} {
    margin-bottom: 32px;
    font-size: 16px;
  }
`;

export interface EmailVerificationViewProps {
  user: User;
  onComplete: () => void;
}

const EmailVerificationView: FunctionComponent<EmailVerificationViewProps> = ({
  user,
  onComplete
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const [sendEmailVerification, { loading, error, data }] =
    useSendEmailVerification();

  const onTroubleshootClick = () => setModalVisible(true);

  const onTroubleshootCancel = () => setModalVisible(false);

  const onTroubleshoot = () => sendEmailVerification(user);

  useEffect(() => {
    if (data) {
      setModalVisible(false);
    }
  }, [setModalVisible, data]);

  if (error) {
    return (
      <Container>
        <ErrorView error={error} />
      </Container>
    );
  }

  return (
    <Container>
      <AuthEmailSentSvg />
      <Title>
        <FormattedMessage id="auth.email.verification.view.title" />
      </Title>
      <MarkdownMessage
        id="auth.email.verification.view.instructions"
        values={{ email: user.email }}
      />
      <Row>
        <LinkButton onClick={onTroubleshootClick}>
          <FormattedMessage id="auth.troubleshoot.email.modal.title" />
        </LinkButton>
      </Row>
      <Row>
        <Button type="primary" onClick={onComplete}>
          <FormattedMessage id="button.go.to.sign.in" />
        </Button>
      </Row>
      <TroubleshootEmailModal
        visible={modalVisible}
        loading={loading}
        onSubmit={onTroubleshoot}
        onCancel={onTroubleshootCancel}
      />
    </Container>
  );
};

export default EmailVerificationView;
