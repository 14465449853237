// © ООО «Эдиспа», 2022

export function isCodeError(value: Error): value is CodeError {
  return (value as CodeError).code !== undefined;
}

export class CodeError extends Error {
  constructor(readonly code: string, message?: string) {
    super(message);
    this.code = code;
    this.name = 'CodeError';
  }
}
