// © ООО «Эдиспа», 2022

import { round } from 'lodash';

import { LiquidationEventMessage, LiquidationPhase } from 'emergency';
import { isOverloaded } from 'emergency/utils';
import { DenormalizedEdge, DenormalizedSchema, Edge, Node, Schema } from 'grid';
import {
  denormalize,
  getEdgeLabel,
  getLoadFactorSrc,
  getLoadFactorTgt,
  isGenerator
} from 'grid/utils';
import { LiquidationEvent } from 'emergency';

/**
 * Генератор.
 */
export type Generator = Required<
  Pick<Node, 'id' | 'label' | 'pGen' | 'pMin' | 'pMax'>
>;

/**
 * Эффективный генератор.
 */
export type EffectiveGenerator = Generator & {
  /**
   * Коэффициент эффективности.
   */
  factor: number;
};

/**
 * Перегруженная ветвь.
 */
export type OverloadedEdge = Required<
  Pick<Edge, 'iSrc' | 'iTgt' | 'iMaxSrc' | 'iMaxTgt'>
> & {
  /**
   * Идентификатор.
   */
  id: string;
  /**
   * Лэйбл.
   */
  label: string;
  /**
   * Коэффициент загрузки в начале ветви.
   */
  loadFactorSrc: number;
  /**
   * Коэффициент загрузки в конце ветви.
   */
  loadFactorTgt: number;
};

/**
 * Сводный отчет о ликвидации аварийного режима.
 */
interface Summary {
  /**
   * Действия над генераторами.
   */
  actions: Action[];
  /**
   * Массив разгруженных или частично разгруженных ветвей.
   */
  edges: OverloadedEdge[];
}

/**
 * Действие, выполненное над генератором для разгрузки ветви.
 */
export interface Action {
  /**
   * Идентификатор генератора.
   */
  id: string;
  /**
   * Лэйбл генератора.
   */
  label: string;
  /**
   * Тип действия - Загрузка, Разгрузка или Пропущен.
   */
  type: 'load' | 'unload' | 'skipped';
  /**
   * Значение мощности генерации, до которого необходимо загрузить или разгрузить генератор.
   */
  pGen: number;
}

/**
 * Этап разгрузки перегруженной ветви.
 */
export interface Phase {
  /**
   * Ветвь до разгрузки.
   */
  before: OverloadedEdge;
  /**
   * Ветвь после разгрузки.
   */
  after: OverloadedEdge;
  /**
   * Массив эффективных генераторов применяемых для разгрузки ветви.
   */
  generators: EffectiveGenerator[];
  /**
   * Действия над генераторами.
   */
  actions: Action[];
}

export type Deviation = Required<Pick<Edge, 'id' | 'disabled'>> & {
  /**
   * Лэйбл.
   */
  label: string;
};

/**
 * Отчет о ликвидации аварийного режима.
 */
export interface EmergencyLiquidationReport {
  /**
   * Массив генераторов.
   */
  generators: Generator[];
  /**
   * Массив перегруженных ветвей.
   */
  edges: OverloadedEdge[];
  /**
   * Этапы разгрузки перегруженных ветвей.
   */
  phases: Phase[];
  /**
   * Сводный отчет о ликвидации аварийного режима.
   */
  summary: Summary;
  /**
   * Название сети.
   */
  title: string;
  /**
   * Дата формирования отчета.
   */
  date: Date;
  /**
   * Отклонения ветвей от нормальной схемы
   */
  deviations: Deviation[];
}

const toOverloadedEdge = (edge: DenormalizedEdge): OverloadedEdge => {
  const { id, iSrc, iTgt, iMaxSrc, iMaxTgt } = edge;
  return {
    id,
    label: getEdgeLabel(edge),
    iSrc: round(iSrc as number, 2),
    iTgt: round(iTgt as number, 2),
    iMaxSrc: round(iMaxSrc as number, 2),
    iMaxTgt: round(iMaxTgt as number, 2),
    loadFactorSrc: round(getLoadFactorSrc(edge) as number, 2),
    loadFactorTgt: round(getLoadFactorTgt(edge) as number, 2)
  };
};

const toGenerator = (node: Node): Generator => {
  const { id, label, pGen, pMin, pMax } = node;
  return {
    id,
    label,
    pGen: round(pGen as number, 2),
    pMin: round(pMin as number, 2),
    pMax: round(pMax as number, 2)
  };
};

type NodeMap = { [key: string]: Node };

type EdgeMap = { [key: string]: DenormalizedEdge };

const buildSummary = (
  schemaBefore: DenormalizedSchema,
  schemaAfter: DenormalizedSchema
) => {
  const { nodes: nodesBefore, edges: edgesBefore } = schemaBefore;
  const { nodes: nodesAfter, edges: edgesAfter } = schemaAfter;

  // формируем сет идентификаторов перегруженных ветвей
  const overloadedEdgeIds = new Set(
    edgesBefore.filter(isOverloaded).map(edge => edge.id)
  );

  // используя данные схемы, полученной в результате ликвидации аварии,
  // ковертируем ранее перегруженные ветви в формат отчета
  const edges = edgesAfter.reduce<OverloadedEdge[]>((acc, edge) => {
    const { id } = edge;
    if (overloadedEdgeIds.has(id)) {
      acc.push(toOverloadedEdge(edge));
    }
    return acc;
  }, []);

  // формируем словарь мощностей генераторов до ликвидации аварии
  const pGenBeforeMap = nodesBefore.reduce<{ [key: string]: number }>(
    (acc, node) => {
      if (isGenerator(node)) {
        const { id, pGen } = node;
        acc[id] = pGen as number;
      }
      return acc;
    },
    {}
  );

  // создаем список режимных мероприятий
  const actions = nodesAfter.reduce<Action[]>((acc, node) => {
    if (isGenerator(node)) {
      const { id, label, pGen } = node;
      const pGenBefore = pGenBeforeMap[id];
      const pGenAfter = pGen as number;
      // режимное мероприятие создается в случае, если мощность генерации узла
      // изменилась в результате ликвидации аварии
      if (round(pGenAfter, 2) !== round(pGenBefore, 2)) {
        acc.push({
          id,
          label,
          type: pGenAfter > pGenBefore ? 'load' : 'unload',
          pGen: pGenAfter
        });
      }
    }
    return acc;
  }, []);

  return {
    actions,
    edges
  };
};

type ActionType = 'load' | 'unload' | 'skipped';

const getActionType = (event: LiquidationEvent): ActionType => {
  const { before, after } = event;
  if (after.pGen > before.pGen) {
    return 'load';
  } else if (after.pGen < before.pGen) {
    return 'unload';
  } else {
    return 'skipped';
  }
};

export const getActionTypeLabel = (type: ActionType) => {
  switch (type) {
    case 'load': {
      return 'Загрузка';
    }
    case 'unload': {
      return 'Разгрузка';
    }
    case 'skipped': {
      return 'Пропущен';
    }
  }
};

// TODO: consider moving this logic to edispa-cloudborn
export const buildLiquidateEmergencyReport = (
  schemaBefore: Schema,
  schemaAfter: Schema,
  phases: LiquidationPhase[]
): Pick<
  EmergencyLiquidationReport,
  'generators' | 'edges' | 'phases' | 'summary' | 'date'
> => {
  const denormalizedSchemaBefore = denormalize(schemaBefore);
  const denormalizedSchemaAfter = denormalize(schemaAfter);
  const { nodes, edges } = denormalizedSchemaBefore;
  const nodeMap = nodes.reduce<NodeMap>((acc, node) => {
    acc[node.id] = node;
    return acc;
  }, {});

  const edgeMap = edges.reduce<EdgeMap>((acc, edge) => {
    acc[edge.id] = edge;
    return acc;
  }, {});

  const getActionLimit = (message?: LiquidationEventMessage) => {
    if (message) {
      switch (message.type) {
        case 'generator_limit': {
          const { context } = message;
          if (context === 'max_load') {
            return 'Макс';
          } else if (context === 'min_load') {
            return 'Мин';
          }
          return undefined;
        }
        case 'overload': {
          const { context } = message;
          const edge = edgeMap[context];
          return `Ток ветви ${getEdgeLabel(edge)}`;
        }
      }
    }
  };

  const reportPhases = phases.map<Phase>(phase => {
    const { id, before, after, phases: events } = phase;
    const edge = edgeMap[id];
    return {
      before: toOverloadedEdge({
        ...edge,
        ...before
      }),
      after: toOverloadedEdge({
        ...edge,
        ...after
      }),
      generators: events
        ? events.map(event => {
            const { id, factor, before } = event;
            const generator = toGenerator(nodeMap[id]);
            return {
              ...generator,
              ...before,
              factor
            };
          })
        : [],
      actions: events
        ? events.map(event => {
            const { id, after, message } = event;
            const { pGen } = after;
            const { label } = nodeMap[id];
            return {
              id,
              label,
              type: getActionType(event),
              pGen: round(pGen, 2),
              limit: getActionLimit(message)
            };
          })
        : []
    };
  });

  return {
    generators: nodes.filter(isGenerator).map(toGenerator),
    edges: edges.filter(isOverloaded).map(toOverloadedEdge),
    phases: reportPhases,
    summary: buildSummary(denormalizedSchemaBefore, denormalizedSchemaAfter),
    date: new Date()
  };
};

export const buildDeviations = (
  schemaBefore: Schema,
  schemaAfter: Schema
): Deviation[] => {
  const denormalizedSchemaBefore = denormalize(schemaBefore);

  const edgesBefore = denormalizedSchemaBefore.edges;
  const edgesAfter = schemaAfter.edges;

  const edgeAfterDisabledMap = edgesAfter.reduce<{ [key: string]: boolean }>(
    (acc, edge) => {
      acc[edge.id] = !!edge.disabled;
      return acc;
    },
    {}
  );

  return edgesBefore.reduce<Deviation[]>((acc, edge) => {
    const { id, disabled: disabledBefore } = edge;
    const disabledAfter = edgeAfterDisabledMap[edge.id];
    if (!!disabledBefore !== disabledAfter) {
      acc.push({
        id,
        disabled: disabledAfter,
        label: getEdgeLabel(edge)
      });
    }
    return acc;
  }, []);
};
