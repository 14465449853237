// © ООО «Эдиспа», 2022

import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';

import { ExclamationSquareSvg } from 'svg';
import style from 'themes';

const Icon = styled(ExclamationSquareSvg)`
  color: ${style('demo.banner.icon.color')};
  font-size: 16px;
`;

const Container = styled.div`
  background-color: ${style('demo.banner.background')};
  padding: 8px 16px;
  display: flex;
  align-items: center;
  ${Icon} {
    margin-right: 8px;
  }
  font-weight: 500;
  font-size: 12px;
  color: white;
  grid-area: demo-banner;
`;

const DemoBanner = () => {
  return (
    <Container>
      <Icon />
      <FormattedMessage id="demo.banner.message" />
    </Container>
  );
};

export default DemoBanner;
