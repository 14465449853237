// © ООО «Эдиспа», 2022

import styled from 'styled-components/macro';

import Input from 'components/input/Input';
import style from 'themes';

const AuthInput = styled(Input)`
  padding: 0 8px;
  color: ${style('auth.input.color')};
  height: 48px;
  border: 1px solid transparent;
  border-radius: 8px;
  background-color: ${style('auth.input.background')};
  font-size: 16px;
  :hover {
    border: 1px solid ${style('auth.input.hover.border.color')};
  }
  :focus,
  :active {
    outline: none;
    border: 1px solid ${style('auth.input.focus.border.color')};
  }
  :-webkit-autofill::first-line,
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    font-size: 16px;
    font-family: Roboto, sans-serif;
    -webkit-text-fill-color: ${style('auth.input.color')};
    background-color: ${style('auth.input.background')};
    transition: background-color 5000s ease-in-out 0s;
    caret-color: ${style('auth.input.color')};
  }
`;

export default AuthInput;
