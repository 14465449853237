// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { isEqual, omit } from 'lodash';

import IconButton from 'components/button/IconButton';
import { Toolbar } from 'components/Layout';
import { Node, NodeType, PowerGridMode } from 'grid';
import AttrList from 'grid/components/graph/AttrList';
import {
  Header,
  Content,
  Container
} from 'grid/components/graph/DetailsViewComponents';
import NodeDetailsModal, {
  NodeDetailsModalProps
} from 'grid/components/node/DetailsModal';
import { usePowerGridContext, usePowerGridDispatch } from 'grid/context';
import { actions } from 'grid/store';
import { IconEditSvg, IconTrashSvg } from 'svg';
import formatters from 'utils/format';

export interface NodeDetailsViewProps {
  node: Node;
}

const NodeDetailsView: FunctionComponent<NodeDetailsViewProps> = ({ node }) => {
  const intl = useIntl();
  const dispatch = usePowerGridDispatch();
  const { grid } = usePowerGridContext();
  const { mode } = grid;

  const isConfigMode = mode === PowerGridMode.CONFIGURATION;

  const [modalProps, setModalProps] = useState<Partial<NodeDetailsModalProps>>({
    visible: false
  });

  const onEditClick = () =>
    setModalProps({
      node,
      visible: true
    });

  const onDeleteClick = () => dispatch(actions.removeNode(node));

  const onSubmitModal = (node: Node) => {
    dispatch(actions.updateNode(node));
    setModalProps({ visible: false });
  };

  const onCancelModal = () => setModalProps({ visible: false });

  return (
    <Container>
      <Header>
        <FormattedMessage id="label.node" />
        <Toolbar>
          <IconButton onClick={onEditClick}>
            <IconEditSvg />
          </IconButton>
          {mode === PowerGridMode.CONFIGURATION && (
            <IconButton onClick={onDeleteClick}>
              <IconTrashSvg />
            </IconButton>
          )}
        </Toolbar>
      </Header>
      <Content>
        <AttrList<Node>
          data={node}
          items={[
            {
              dataIndex: 'label',
              label: <FormattedMessage id="node.prop.label" />
            },
            {
              dataIndex: 'type',
              label: <FormattedMessage id="node.prop.type" />,
              render: (value: string) => {
                if (value === NodeType.GENERATION) {
                  return intl.formatMessage({ id: 'node.type.generation' });
                } else if (value === NodeType.LOAD) {
                  return intl.formatMessage({ id: 'node.type.load' });
                } else if (value === NodeType.BALANCE) {
                  return intl.formatMessage({ id: 'node.type.balance' });
                }
              }
            },
            {
              dataIndex: 'u',
              label: <FormattedMessage id="node.prop.u.label" />,
              render: formatters.number,
              disabled: isConfigMode
            },
            {
              dataIndex: 'delta',
              label: <FormattedMessage id="node.prop.delta.label" />,
              render: formatters.number,
              disabled: isConfigMode
            },
            {
              dataIndex: 'uNom',
              label: <FormattedMessage id="node.prop.u.nom.label" />,
              info: <FormattedMessage id="node.prop.u.nom.description" />,
              render: formatters.number
            },
            {
              dataIndex: 'uGiv',
              label: <FormattedMessage id="node.prop.u.giv.label" />,
              info: <FormattedMessage id="node.prop.u.giv.description" />,
              render: formatters.number
            },
            {
              dataIndex: 'pNom',
              label: <FormattedMessage id="node.prop.p.nom.label" />,
              info: <FormattedMessage id="node.prop.p.nom.description" />,
              render: formatters.number
            },
            {
              dataIndex: 'qNom',
              label: <FormattedMessage id="node.prop.q.nom.label" />,
              info: <FormattedMessage id="node.prop.q.nom.description" />,
              render: formatters.number
            },
            {
              dataIndex: 'pGen',
              label: <FormattedMessage id="node.prop.p.gen.label" />,
              info: <FormattedMessage id="node.prop.p.gen.description" />,
              render: formatters.number
            },
            {
              dataIndex: 'qGen',
              label: <FormattedMessage id="node.prop.q.gen.label" />,
              info: <FormattedMessage id="node.prop.q.gen.description" />,
              render: formatters.number
            },
            {
              dataIndex: 'pMin',
              label: <FormattedMessage id="node.prop.p.min.label" />,
              info: <FormattedMessage id="node.prop.p.min.description" />,
              render: formatters.number
            },
            {
              dataIndex: 'pMax',
              label: <FormattedMessage id="node.prop.p.max.label" />,
              info: <FormattedMessage id="node.prop.p.max.description" />,
              render: formatters.number
            },
            {
              dataIndex: 'qMin',
              label: <FormattedMessage id="node.prop.q.min.label" />,
              info: <FormattedMessage id="node.prop.q.min.description" />,
              render: formatters.number
            },
            {
              dataIndex: 'qMax',
              label: <FormattedMessage id="node.prop.q.max.label" />,
              info: <FormattedMessage id="node.prop.q.max.description" />,
              render: formatters.number
            }
          ]}
        />
      </Content>
      <NodeDetailsModal
        {...modalProps}
        onSubmit={onSubmitModal}
        onCancel={onCancelModal}
      />
    </Container>
  );
};

export default React.memo(NodeDetailsView, (prevProps, nextProps) => {
  const prevNode = prevProps.node;
  const nextNode = nextProps.node;
  return isEqual(omit(prevNode, 'layout'), omit(nextNode, 'layout'));
});
