// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { Tooltip, Typography } from 'antd';
import styled from 'styled-components/macro';

import { useUserContext } from 'auth/context';
import { PowerGrid } from 'grid';
import EntityCard from 'grid/components/EntityCard';
import { Row, Spacer } from 'components/Layout';
import { IconEyeFilledSvg, IconLockSvg, UserLightSvg } from 'svg';
import style from 'themes';
import { FormattedMessage } from 'react-intl';

const { Paragraph } = Typography;

const Title = styled(Paragraph).attrs({
  ellipsis: { rows: 3 }
})`
  line-height: normal;
  font-weight: 700;
  font-size: 16px;
`;

const Description = styled(Paragraph).attrs({
  ellipsis: { rows: 3 }
})`
  line-height: normal;
  color: ${style('page.power.grid.list.card.description.color')};
`;

const UserIcon = styled(UserLightSvg)`
  flex-shrink: 0;
`;

const Header = styled(Row)`
  ${Title} {
    flex: 1;
  }
`;

const Footer = styled(Row)`
  align-items: center;
  ${UserIcon} {
    margin-right: 16px;
  }
`;

const Container = styled(EntityCard)`
  border: 1px solid transparent;
  background: ${style('page.power.grid.list.card.background.color')};
  display: flex;
  flex-direction: column;
  :hover {
    border-color: ${style('page.power.grid.list.card.hover.border.color')};
  }
  :active {
    border-color: ${style('page.power.grid.list.card.active.border.color')};
  }
  ${Description} {
    margin-top: 8px;
  }
`;

// TODO: refactor to some icon component
const IconLock = styled(IconLockSvg)`
  font-size: 24px;
  z-index: 1;
`;

const IconEye = styled(IconEyeFilledSvg)`
  font-size: 24px;
  z-index: 1;
`;

export interface PowerGridCardProps {
  value: PowerGrid;
}

const PowerGridCard: FunctionComponent<PowerGridCardProps> = ({ value }) => {
  const { id, title, description, scope, owner } = value;
  const { user } = useUserContext();
  return (
    <Container url={`grids/${id}`}>
      <Header>
        <Title>{title}</Title>
        {scope === owner.id && (
          <Tooltip
            title={
              <FormattedMessage id="page.power.grid.list.private.scope.description" />
            }
            mouseEnterDelay={1}
          >
            <IconLock />
          </Tooltip>
        )}
        {scope === 'public' && owner.id === user.uid && (
          <Tooltip
            title={
              <FormattedMessage id="page.power.grid.list.public.scope.description" />
            }
            mouseEnterDelay={1}
          >
            <IconEye />
          </Tooltip>
        )}
      </Header>
      <Description>{description}</Description>
      <Spacer />
      <Footer>
        <UserIcon />
        <Paragraph ellipsis>{owner.displayName}</Paragraph>
      </Footer>
    </Container>
  );
};

export default PowerGridCard;
