// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import Button from 'components/button/Button';
import { Column, Toolbar } from 'components/Layout';
import Logo from 'components/Logo';
import Modal, { ModalProps } from 'components/modal/Modal';
import style from 'themes';

const Tag = styled.div`
  width: 102px;
  height: 56px;
  background-color: ${style('auth.tag.background')};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 16px;
`;

const LogoIcon = styled(Logo)`
  height: 24px;
  width: auto;
`;

const Container = styled(Modal)`
  .ant-modal-body {
    padding-top: 0;
  }
  p {
    margin-bottom: 0;
  }
`;

const Section = styled(Column)`
  margin-bottom: 24px;
  > * + * {
    margin-top: 8px;
  }
`;

const Content = styled.div``;

const AboutModal: FunctionComponent<ModalProps> = ({
  onCancel,
  ...otherProps
}) => (
  <Container
    {...otherProps}
    width={448}
    footer={
      <Toolbar>
        <Button type="secondary" onClick={onCancel}>
          <FormattedMessage id="button.close" />
        </Button>
      </Toolbar>
    }
  >
    <Content>
      <Section>
        <Tag>
          <LogoIcon />
        </Tag>
      </Section>
      <Section>
        <p className="edispa-label-one-dark">
          <FormattedMessage id="title.edispa" />
        </p>
        <p className="edispa-body-one-light">
          <FormattedMessage id="label.copyright" />
        </p>
      </Section>
      <Section>
        <Link
          to="/user-agreement.txt"
          target="_blank"
          rel="noreferrer noopener"
        >
          <FormattedMessage id="label.user.agreement" />
        </Link>
        <Link
          to="/privacy-policy.txt"
          target="_blank"
          rel="noreferrer noopener"
        >
          <FormattedMessage id="label.privacy.policy" />
        </Link>
        <Link to="/licenses.txt" target="_blank" rel="noreferrer noopener">
          <FormattedMessage id="label.used.licenses" />
        </Link>
      </Section>
    </Content>
  </Container>
);

export default AboutModal;
