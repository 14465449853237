// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import styled from 'styled-components/macro';

import ErrorPage from 'components/ErrorPage';
import Loading from 'components/loading/Loading';
import TabPanel from 'components/tab/TabPanel';
import { useDocs } from 'help/hooks';
import { useFormatMessage } from 'utils/format';

const StyledMarkdown = styled(Markdown)`
  img[src*='#screenshot'] {
    width: 100%;
  }
  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }
  table {
    margin: 16px 0;
  }
`;

const HelpPage: FunctionComponent = () => {
  const formatMessage = useFormatMessage();
  const [fetch, { loading, error, data }] = useDocs();

  useLayoutEffect(() => {
    fetch();
  }, []);

  if (loading) {
    return <Loading />;
  } else if (error) {
    return <ErrorPage type="unknown" />;
  } else if (!data) {
    return null;
  }

  const tabs = data.map(page => ({
    ...page,
    content: (
      <StyledMarkdown remarkPlugins={[remarkGfm]}>
        {page.content}
      </StyledMarkdown>
    )
  }));

  return (
    <>
      <Helmet>
        <title>{formatMessage('title.edispa')}</title>
      </Helmet>
      <TabPanel tabs={tabs} layout="settings" />
    </>
  );
};

export default HelpPage;
