// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useReducer } from 'react';

import { AbstractAction } from 'utils/reducer';

export interface ProviderProps<T> {
  value: T;
}

export interface MutableContext<T, K extends AbstractAction> {
  useContext: () => T;
  useDispatch: () => React.Dispatch<K>;
  Provider: FunctionComponent<ProviderProps<T>>;
}

export function createMutableContext<T, K extends AbstractAction>(
  reducer: React.Reducer<T, K>
): MutableContext<T, K> {
  const Context = React.createContext<T | undefined>(undefined);
  const DispatchContext = React.createContext<React.Dispatch<K> | undefined>(
    undefined
  );

  const useContext = (): T => {
    const context = React.useContext(Context);
    if (context === undefined) {
      throw new Error(
        'useContext хук должен быть использован в контексте провайдера'
      );
    }
    return context;
  };

  const useDispatch = (): React.Dispatch<K> => {
    const context = React.useContext(DispatchContext);
    if (context === undefined) {
      throw new Error(
        'useDispatch хук должен быть использован в контексте провайдера'
      );
    }
    return context;
  };

  const Provider: FunctionComponent<ProviderProps<T>> = ({
    children,
    value
  }) => {
    const [state, dispatch] = useReducer(reducer, value);

    return (
      <Context.Provider value={state}>
        <DispatchContext.Provider value={dispatch}>
          {children}
        </DispatchContext.Provider>
      </Context.Provider>
    );
  };

  return {
    useContext,
    useDispatch,
    Provider
  };
}
