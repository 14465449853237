// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';

import { Title, Paragraph } from 'auth/components/Typography';
import { isAuthenticated, signOut } from 'auth/utils';
import Button from 'components/button/Button';
import { Route } from 'router';
import { AuthEmailSuccessSvg } from 'svg';

const Image = styled(AuthEmailSuccessSvg)``;

const Container = styled.div`
  max-width: 640px;
  margin: 0 auto;
  ${Image} {
    margin-bottom: 24px;
  }
  ${Title}, ${Paragraph} {
    margin-bottom: 32px;
  }
`;

const EmailVerificationConfirmationView: FunctionComponent = () => {
  const navigate = useNavigate();
  const onClick = () => {
    // сброс возможной активной сессии пользователя, чтобы обновить токен и разблокировать доступ к Firestore
    if (isAuthenticated()) {
      signOut();
    }
    navigate(Route.HOME);
  };
  return (
    <Container>
      <Image />
      <Title>
        <FormattedMessage id="auth.email.verification.confirmation.view.title" />
      </Title>
      {!isMobileOnly && (
        <>
          <Paragraph>
            <FormattedMessage id="auth.email.verification.confirmation.view.instructions" />
          </Paragraph>
          <Button type="primary" onClick={onClick}>
            <FormattedMessage id="button.go.to.app" />
          </Button>
        </>
      )}
    </Container>
  );
};

export default EmailVerificationConfirmationView;
