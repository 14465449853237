// © ООО «Эдиспа», 2022

import { UserContext, UserProfile } from 'auth';
import { AbstractAction, PayloadAction } from 'utils/reducer';

import { createMutableContext } from 'utils/context';

export enum ActionType {
  ACCEPT_TERMS = 'AUTH/ACCEPT_TERMS',
  SET_USER_PROFILE = 'AUTH/SET_USER_PROFILE'
}

type AcceptTermsAction = AbstractAction<ActionType.ACCEPT_TERMS>;

type SetUserProfileAction = PayloadAction<
  ActionType.SET_USER_PROFILE,
  UserProfile
>;

type Action = AcceptTermsAction | SetUserProfileAction;

const reducer = (state: UserContext, action: Action): UserContext => {
  switch (action.type) {
    case ActionType.SET_USER_PROFILE: {
      return {
        ...state,
        profile: action.payload
      };
    }
    case ActionType.ACCEPT_TERMS: {
      return {
        ...state,
        acceptedTerms: true
      };
    }
    default: {
      return state;
    }
  }
};

const { useContext, useDispatch, Provider } = createMutableContext<
  UserContext,
  Action
>(reducer);

export const useUserContext = useContext;

export const useDispatchUserContext = useDispatch;

export const UserContextProvider = Provider;

export const actions = {
  acceptTerms: (): Action => ({
    type: ActionType.ACCEPT_TERMS
  }),
  setUserProfile: (payload: UserProfile): Action => ({
    type: ActionType.SET_USER_PROFILE,
    payload
  })
};
