// © ООО «Эдиспа», 2022

import styled from 'styled-components/macro';

const LinkButton = styled.button.attrs({
  type: 'button'
})`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  outline: none;
  :hover {
    text-decoration: none;
  }
`;

export default LinkButton;
