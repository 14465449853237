// © ООО «Эдиспа», 2022

import React, { ForwardRefRenderFunction, HTMLAttributes } from 'react';
import { Handle, Position } from 'react-flow-renderer';
import styled, { css } from 'styled-components/macro';

import { NodeType, PowerGridMode } from 'grid';
import { isValidConnection } from 'grid/components/graph/common';
import { usePowerGridContext } from 'grid/context';
import { getNodeSize } from 'grid/utils';
import style from 'themes';

const Title = styled.div`
  font-size: 12px;
  line-height: 14px;
  padding: 2px 4px;
  border-radius: 4px;
  background-color: #1e052d;
  color: #ffffff;
  white-space: nowrap;
`;

interface BodyProps {
  type: NodeType;
}

const Body = styled.div<BodyProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-width: 1px;
  border-style: solid;
  position: relative;
  ${props => {
    switch (props.type) {
      case NodeType.BALANCE: {
        const { width, height } = getNodeSize(NodeType.BALANCE);
        return css`
          width: ${width}px;
          height: ${height}px;
          border-radius: 4px;
        `;
      }
      case NodeType.LOAD: {
        const { width, height } = getNodeSize(NodeType.LOAD);
        return css`
          width: ${width}px;
          height: ${height}px;
          border-radius: 50%;
        `;
      }
      case NodeType.GENERATION: {
        const { width, height } = getNodeSize(NodeType.GENERATION);
        return css`
          width: ${width}px;
          height: ${height}px;
          border-radius: 4px;
        `;
      }
      default: {
        throw new Error('Неподдерживаемый тип узла.');
      }
    }
  }}
`;

interface ContainerProps {
  selected: boolean;
  invalid: boolean;
  customColor?: string;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${Title} {
    top: -26px;
    position: absolute;
  }
  &.dndnode {
    ${Title} {
      margin-bottom: 8px;
      top: unset;
      position: relative;
    }
  }
  .react-flow__handle {
    background: #ffffff;
    border-color: #1e052d;
    height: 8px;
    width: 8px;
    &.react-flow__handle-top {
      top: 0;
      left: 50%;
      transform: translate(-50%, -55%);
    }
    &.react-flow__handle-right {
      top: 50%;
      right: 0;
      transform: translate(55%, -50%);
    }
    &.react-flow__handle-bottom {
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 55%);
    }
    &.react-flow__handle-left {
      left: 0;
      top: 50%;
      transform: translate(-55%, -50%);
    }
  }
  ${props => {
    if (props.selected) {
      return css`
        ${Title} {
          background-color: ${style('graph.selected.color')};
        }
        ${Body}, .react-flow__handle {
          border-color: ${style('graph.selected.color')};
        }
      `;
    } else if (props.invalid) {
      return css`
        ${Title} {
          background-color: ${style('graph.error.color')};
        }
        ${Body}, .react-flow__handle {
          border-color: ${style('graph.error.color')};
        }
      `;
    } else if (props.customColor) {
      return css`
        ${Title} {
          background-color: ${props.customColor};
        }
        ${Body}, .react-flow__handle {
          border-color: ${props.customColor};
        }
      `;
    } else {
      return css`
        ${Title} {
          background-color: ${style('graph.color')};
        }
        ${Body}, .react-flow__handle {
          border-color: ${style('graph.color')};
        }
      `;
    }
  }}
`;

export interface NodeShapeProps {
  type: NodeType;
  label: string;
  selected?: boolean;
  invalid?: boolean;
  customColor?: string;
}

const NodeShape: ForwardRefRenderFunction<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & NodeShapeProps
> = (props, ref) => {
  const {
    type,
    label,
    selected = false,
    invalid = false,
    children,
    ...otherProps
  } = props;
  const { grid } = usePowerGridContext();
  return (
    <Container selected={selected} invalid={invalid} {...otherProps}>
      <Title>{label}</Title>
      <Body ref={ref} type={type}>
        {grid.mode === PowerGridMode.OPERATION && children}
        <Handle
          type="source"
          position={Position.Top}
          id="top"
          isValidConnection={isValidConnection}
        />
        <Handle
          type="source"
          position={Position.Right}
          id="right"
          isValidConnection={isValidConnection}
        />
        <Handle
          type="source"
          position={Position.Bottom}
          id="bottom"
          isValidConnection={isValidConnection}
        />
        <Handle
          type="source"
          position={Position.Left}
          id="left"
          isValidConnection={isValidConnection}
        />
      </Body>
    </Container>
  );
};

export default React.forwardRef(NodeShape);
