// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'antd';

import IconButton from 'grid/components/toolbar/IconButton';
import { usePowerGridDispatch } from 'grid/context';
import { actions, PowerGridFeature } from 'grid/store';
import { IconExportSvg } from 'svg';

const ExportToJsonButton: FunctionComponent = () => {
  const dispatch = usePowerGridDispatch();

  const onClick = () =>
    dispatch(actions.setActiveFeature(PowerGridFeature.EXPORT_TO_JSON));

  return (
    <Tooltip
      mouseEnterDelay={1}
      title={<FormattedMessage id="button.export" />}
    >
      <IconButton onClick={onClick}>
        <IconExportSvg />
      </IconButton>
    </Tooltip>
  );
};

export default ExportToJsonButton;
