// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'antd';
import { isEqual, isNumber, omit } from 'lodash';
import styled, { css } from 'styled-components/macro';

import IconButton from 'components/button/IconButton';
import { Toolbar } from 'components/Layout';
import Switch from 'components/switch/Switch';
import { Edge, Node, PowerGridMode } from 'grid';
import EdgeDetailsModal, {
  EdgeDetailsModalProps
} from 'grid/components/edge/DetailsModal';
import AttrList from 'grid/components/graph/AttrList';
import {
  Section,
  Header,
  Content,
  Container
} from 'grid/components/graph/DetailsViewComponents';
import { usePowerGridContext, usePowerGridDispatch } from 'grid/context';
import { actions } from 'grid/store';
import { getLoadFactorSrc, getLoadFactorTgt } from 'grid/utils';
import { IconEditSvg, IconTrashSvg } from 'svg';
import style from 'themes';
import formatters, { useFormatMessage } from 'utils/format';

const DisabledPropSection = styled(Section)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${Switch} {
    margin: -6px 0;
  }
`;

const Table = styled.div`
  display: table;
  width: 100%;
  font-size: 14px;
  line-height: 14px;
`;

const TableRow = styled.div`
  display: table-row;
`;

const TableHeader = styled(TableRow)`
  color: #a298a8;
`;

interface TableCellProps {
  align?: 'left' | 'center' | 'right';
}

const TableCell = styled.div<TableCellProps>`
  ${props => {
    switch (props.align) {
      case 'right':
        return css`
          text-align: right;
        `;
      case 'center':
        return css`
          text-align: center;
        `;
      case 'left':
      default:
        return css`
          text-align: left;
        `;
    }
  }}
  display: table-cell;
  padding: 16px;
  border-bottom: 1px solid #f4f3f5;
`;

const Invalid = styled.span`
  color: ${style('color.red.one')};
`;

export interface EdgeDetailsViewProps {
  edge: Edge;
}

const EdgeDetailsView: FunctionComponent<EdgeDetailsViewProps> = ({ edge }) => {
  const formatMessage = useFormatMessage();
  const { grid } = usePowerGridContext();
  const dispatch = usePowerGridDispatch();
  const { schema, mode } = grid;
  const nodes = schema.nodes;

  const [modalProps, setModalProps] = useState<Partial<EdgeDetailsModalProps>>({
    visible: false
  });

  const onEditClick = () =>
    setModalProps({
      edge,
      visible: true
    });

  const onDeleteClick = () => dispatch(actions.removeEdge(edge));

  const onEnabledChange = (checked: boolean) =>
    dispatch(
      actions.updateEdge({
        ...edge,
        disabled: !checked
      })
    );

  const onSubmitModal = (edge: Edge) => {
    dispatch(actions.updateEdge(edge));
    setModalProps({ visible: false });
  };

  const onCancelModal = () => setModalProps({ visible: false });

  const { source, target } = nodes.reduce(
    (acc, node) => {
      if (node.id === edge.source) {
        acc.source = node;
      } else if (node.id === edge.target) {
        acc.target = node;
      }
      return acc;
    },
    {} as {
      source: Node;
      target: Node;
    }
  );

  const loadFactorSrc = getLoadFactorSrc(edge);
  const loadFactorTgt = getLoadFactorTgt(edge);

  const { messages } = edge;
  const renderWithMessage = (value: any, path: keyof Edge) => {
    const message = messages ? messages[path] : undefined;
    if (message) {
      const title = formatMessage(
        `validation/${message.code}`,
        message.details
      );
      value = (
        <Tooltip title={title} placement="topRight">
          <Invalid>{value}</Invalid>
        </Tooltip>
      );
    }
    return value;
  };

  return (
    <Container>
      <Header>
        <FormattedMessage id="label.edge" />
        <Toolbar>
          <IconButton onClick={onEditClick}>
            <IconEditSvg />
          </IconButton>
          {mode === PowerGridMode.CONFIGURATION && (
            <IconButton onClick={onDeleteClick}>
              <IconTrashSvg />
            </IconButton>
          )}
        </Toolbar>
      </Header>
      <Content>
        <DisabledPropSection>
          <FormattedMessage id="edge.prop.enabled.label" />
          <Switch checked={!edge.disabled} onChange={onEnabledChange} />
        </DisabledPropSection>
        {mode === PowerGridMode.OPERATION && (
          <Table>
            <TableHeader>
              <TableCell />
              <TableCell align="center">
                <FormattedMessage id="edge.prop.source.label" />
              </TableCell>
              <TableCell align="center">
                <FormattedMessage id="edge.prop.target.label" />
              </TableCell>
            </TableHeader>
            <TableRow>
              <TableCell>
                <FormattedMessage id="edge.prop.load.factor.label" />
              </TableCell>
              <TableCell align="right">
                {renderWithMessage(
                  isNumber(loadFactorSrc) ? `${loadFactorSrc.toFixed(2)}%` : '',
                  'loadFactorSrc'
                )}
              </TableCell>
              <TableCell align="right">
                {renderWithMessage(
                  isNumber(loadFactorTgt) ? `${loadFactorTgt.toFixed(2)}%` : '',
                  'loadFactorTgt'
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <FormattedMessage id="edge.prop.i.label" />
              </TableCell>
              <TableCell align="right">
                {renderWithMessage(formatters.number(edge.iSrc), 'iSrc')}
              </TableCell>
              <TableCell align="right">
                {renderWithMessage(formatters.number(edge.iTgt), 'iTgt')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <FormattedMessage id="edge.prop.p.label" />
              </TableCell>
              <TableCell align="right">
                {formatters.number(edge.pSrc)}
              </TableCell>
              <TableCell align="right">
                {formatters.number(edge.pTgt)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <FormattedMessage id="edge.prop.q.label" />
              </TableCell>
              <TableCell align="right">
                {formatters.number(edge.qSrc)}
              </TableCell>
              <TableCell align="right">
                {formatters.number(edge.qTgt)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <FormattedMessage id="edge.prop.u.label" />
              </TableCell>
              <TableCell align="right">
                {formatters.number(edge.uSrc)}
              </TableCell>
              <TableCell align="right">
                {formatters.number(edge.uTgt)}
              </TableCell>
            </TableRow>
          </Table>
        )}
        <AttrList<Edge>
          items={[
            {
              dataIndex: 'source',
              label: <FormattedMessage id="edge.prop.source.label" />,
              render: () => source.label
            },
            {
              dataIndex: 'target',
              label: <FormattedMessage id="edge.prop.target.label" />,
              render: () => target.label
            },
            {
              dataIndex: 'r',
              label: <FormattedMessage id="edge.prop.r.label" />,
              info: <FormattedMessage id="edge.prop.r.description" />,
              render: formatters.number
            },
            {
              dataIndex: 'x',
              label: <FormattedMessage id="edge.prop.x.label" />,
              info: <FormattedMessage id="edge.prop.x.description" />,
              render: formatters.number
            },
            {
              dataIndex: 'gc',
              label: <FormattedMessage id="edge.prop.gc.label" />,
              info: <FormattedMessage id="edge.prop.gc.description" />,
              render: formatters.number
            },
            {
              dataIndex: 'bc',
              label: <FormattedMessage id="edge.prop.bc.label" />,
              info: <FormattedMessage id="edge.prop.bc.description" />,
              render: formatters.number
            },
            {
              dataIndex: 'iMaxSrc',
              label: <FormattedMessage id="edge.prop.i.max.src.label" />,
              info: <FormattedMessage id="edge.prop.i.max.src.description" />,
              render: formatters.number
            },
            {
              dataIndex: 'iMaxTgt',
              label: <FormattedMessage id="edge.prop.i.max.tgt.label" />,
              info: <FormattedMessage id="edge.prop.i.max.tgt.description" />,
              render: formatters.number
            },
            {
              dataIndex: 'kt',
              label: <FormattedMessage id="edge.prop.kt.label" />,
              info: <FormattedMessage id="edge.prop.kt.description" />,
              render: formatters.number
            }
          ]}
          data={edge}
        />
      </Content>
      <EdgeDetailsModal
        {...modalProps}
        onSubmit={onSubmitModal}
        onCancel={onCancelModal}
      />
    </Container>
  );
};

export default React.memo(EdgeDetailsView, (prevProps, nextProps) => {
  const prevEdge = prevProps.edge;
  const nextEdge = nextProps.edge;
  return isEqual(omit(prevEdge, 'layout'), omit(nextEdge, 'layout'));
});
