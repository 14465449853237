// © ООО «Эдиспа», 2022

import { createGlobalStyle } from 'styled-components/macro';

import style from 'themes';

export default createGlobalStyle`

  body {
    background-color: ${style('auth.background')};
  }

`;
