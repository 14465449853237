// © ООО «Эдиспа», 2022

import React from 'react';
import ReactDOM from 'react-dom';

import App from 'App';
import configureFirebase from 'configureFirebase';

configureFirebase().then(async () => {
  ReactDOM.render(<App />, document.getElementById('root'));
});
