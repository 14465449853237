// © ООО «Эдиспа», 2022

import React, { FunctionComponent, ReactNode } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import styled from 'styled-components/macro';

const Container = styled(FormattedMessage)`
  color: white;
`;

const Hint = styled.span`
  opacity: 0.5;
`;

const hint = (chunks: ReactNode) => <Hint>{chunks}</Hint>;

type PageTitleProps = Pick<MessageDescriptor, 'id'> & {
  values?: Record<string, any>;
};

const PageTitle: FunctionComponent<PageTitleProps> = ({ id, values }) => {
  return (
    <Container
      id={id}
      values={{
        ...values,
        hint
      }}
    />
  );
};

export default PageTitle;
