// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { message } from 'antd';
import { clamp, isArray } from 'lodash';
import { FunctionsError } from 'firebase/functions';
import styled from 'styled-components/macro';

import Alert from 'components/Alert';
import Button from 'components/button/Button';
import SubmitButton from 'components/button/SubmitButton';
import { FormValues } from 'components/form/Form';
import TextArea from 'components/input/TextArea';
import { Toolbar } from 'components/Layout';
import Modal, { ModalProps } from 'components/modal/Modal';
import { Paragraph } from 'components/Typography';
import { Schema } from 'grid';
import ImportFromJsonForm from 'import/components/ImportFromJsonForm';
import { useImportFromJson } from 'import/hooks';

const ErrorDetails = styled(TextArea).attrs({
  disabled: true
})`
  margin-bottom: 24px;
  resize: none;
`;

interface ImportFromJsonModalProps {
  onComplete: (schema: Schema) => void;
}

const ImportFromJsonModal: FunctionComponent<
  ModalProps & ImportFromJsonModalProps
> = ({ onComplete, onCancel, ...otherProps }) => {
  const intl = useIntl();
  const [importFromJson, { loading, error, data }] = useImportFromJson();

  const messages = useMemo(() => {
    if (error) {
      const { details } = error as FunctionsError;
      if (isArray(details)) {
        return details;
      }
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      onComplete(data);
    }
  }, [data]);

  const onFinish = (values: FormValues) => {
    const { fileList } = values;
    const [file] = fileList;
    message.destroy();
    importFromJson({
      file
    });
  };

  const details =
    messages &&
    messages
      .map(message => {
        const { code, details = {} } = message;
        const { path } = details;
        return `${
          isArray(path) ? `${path.join('/')}: ` : ''
        }${intl.formatMessage({
          id: `validation/${code}`
        })}`;
      })
      .join('\n');

  return (
    <Modal
      {...otherProps}
      title={<FormattedMessage id="modal.import.from.json.title" />}
    >
      <Alert
        error={
          details
            ? intl.formatMessage({
                id: 'modal.import.from.json.critical.error'
              })
            : error
        }
      />
      {details && (
        <ErrorDetails value={details} rows={clamp(messages.length, 3, 10)} />
      )}
      <Paragraph className="edispa-body-one-light">
        <FormattedMessage id="modal.import.from.json.instructions" />
      </Paragraph>
      <ImportFromJsonForm
        onFinish={onFinish}
        footer={
          <Toolbar>
            <SubmitButton loading={loading}>
              <FormattedMessage id="button.done" />
            </SubmitButton>
            <Button type="secondary" onClick={onCancel}>
              <FormattedMessage id="button.cancel" />
            </Button>
          </Toolbar>
        }
      />
    </Modal>
  );
};

export default ImportFromJsonModal;
