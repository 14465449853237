// © ООО «Эдиспа», 2022

import { isEmpty, isNumber, omit } from 'lodash';

import { DenormalizedEdge, Edge, Messages, Schema } from 'grid';

export const isOverloaded = (edge: Edge | DenormalizedEdge): boolean => {
  const { iSrc, iTgt, iMaxSrc, iMaxTgt } = edge;
  return (
    isNumber(iMaxSrc) &&
    isNumber(iMaxTgt) &&
    isNumber(iSrc) &&
    isNumber(iTgt) &&
    (iSrc > iMaxSrc || iTgt > iMaxTgt)
  );
};

export const validateEmergency = (edge: Edge) => {
  const { iSrc, iTgt, iMaxSrc, iMaxTgt } = edge;
  const messages: Messages = {};
  if (isNumber(iSrc) && isNumber(iMaxSrc) && iSrc > iMaxSrc) {
    messages.iSrc = {
      code: 'number/max',
      details: {
        limit: iMaxSrc,
        path: 'iSrc'
      }
    };
    messages.loadFactorSrc = {
      code: 'number/max',
      details: {
        limit: 100,
        path: 'loadFactorSrc'
      }
    };
  }
  if (isNumber(iTgt) && isNumber(iMaxTgt) && iTgt > iMaxTgt) {
    messages.iTgt = {
      code: 'number/max',
      details: {
        limit: iMaxTgt,
        path: 'iTgt'
      }
    };
    messages.loadFactorTgt = {
      code: 'number/max',
      details: {
        limit: 100,
        path: 'loadFactorTgt'
      }
    };
  }
  if (isEmpty(messages)) {
    return edge;
  }
  return {
    ...edge,
    messages: {
      ...edge.messages,
      ...messages
    }
  };
};

export const clearEmergency = (schema: Schema) => {
  const { edges, ...otherProps } = schema;
  return {
    ...otherProps,
    edges: edges.map(edge => {
      let { messages } = edge;
      if (messages) {
        messages = omit(messages, [
          'iSrc',
          'iTgt',
          'loadFactorSrc',
          'loadFactorTgt'
        ]);
      }
      return isEmpty(messages) ? omit(edge, 'messages') : { ...edge, messages };
    })
  };
};
