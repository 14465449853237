// © ООО «Эдиспа», 2022

import { useFetch } from 'utils/hooks';

import grids from 'help/docs/grids.md';
import menu from 'help/docs/menu.md';
import settings from 'help/docs/settings.md';
import modes from 'help/docs/modes.md';
import configuration from 'help/docs/configuration.md';
import toolbar from 'help/docs/toolbar.md';
import leftSidebar from 'help/docs/left-sidebar.md';
import workspace from 'help/docs/workspace.md';
import nodes from 'help/docs/nodes.md';
import edges from 'help/docs/edges.md';
import importSchema from 'help/docs/import.md';
import model from 'help/docs/model.md';
import gridSettings from 'help/docs/grid-settings.md';
import powerFlow from 'help/docs/power-flow.md';
import emergency from 'help/docs/emergency.md';

export interface Page {
  key: string;
  title: string;
  content: string;
}

const pages: Page[] = [
  {
    key: 'grids',
    title: 'Список сетей',
    content: grids
  },
  {
    key: 'menu',
    title: 'Меню приложения',
    content: menu
  },
  {
    key: 'settings',
    title: 'Настройки приложения',
    content: settings
  },
  {
    key: 'modes',
    title: 'Режимы работы с сетью',
    content: modes
  },
  {
    key: 'configuration',
    title: 'Режим редактирования сети',
    content: configuration
  },
  {
    key: 'toolbar',
    title: 'Верхняя панель управления',
    content: toolbar
  },
  {
    key: 'left-sidebar',
    title: 'Левая панель управления',
    content: leftSidebar
  },
  {
    key: 'workspace',
    title: 'Рабочая область схемы',
    content: workspace
  },
  {
    key: 'nodes',
    title: 'Таблица узлов',
    content: nodes
  },
  {
    key: 'edges',
    title: 'Таблица ветвей',
    content: edges
  },
  {
    key: 'import',
    title: 'Импорт схемы',
    content: importSchema
  },
  {
    key: 'model',
    title: 'Модель схемы и ее валидация',
    content: model
  },
  {
    key: 'grid-settings',
    title: 'Настройки сети',
    content: gridSettings
  },
  {
    key: 'power-flow',
    title: 'Расчет баланса сети',
    content: powerFlow
  },
  {
    key: 'emergency',
    title: 'Ликвидация аварии',
    content: emergency
  }
];

export const useDocs = () =>
  useFetch<Page[], void>(async () => {
    const contents = await Promise.all(
      pages.map(page => fetch(page.content).then(response => response.text()))
    );
    return pages.map((page, index) => ({
      ...page,
      content: contents[index]
    }));
  });
