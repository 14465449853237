// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';

import { EmailActionType } from 'auth/action/store';
import { isAuthenticated, signOut } from 'auth/utils';
import FormattedError from 'components/i18n/FormattedError';
import { Title, Paragraph } from 'auth/components/Typography';
import Button from 'components/button/Button';
import { Route } from 'router';
import {
  AuthEmailFailureSvg,
  AuthLockFailureSvg,
  AuthSadFaceFailureSvg
} from 'svg';

const Container = styled.div`
  max-width: 640px;
  margin: 0 auto;
  ${Title} {
    margin-top: 24px;
    margin-bottom: 32px;
  }
  ${Paragraph} {
    margin-bottom: 32px;
  }
`;

interface BaseErrorViewProps {
  error: Error;
}

const Footer: FunctionComponent = () => {
  const navigate = useNavigate();

  const onClick = () => {
    if (isAuthenticated()) {
      signOut();
    }
    navigate(Route.HOME);
  };

  // приложение не поддерживается на мобильных устройствах, поэтому навигация отсутствует
  if (isMobileOnly) {
    return null;
  }

  return (
    <Button type="primary" onClick={onClick}>
      <FormattedMessage id="button.go.to.sign.in" />
    </Button>
  );
};

const EmailVerificationErrorView: FunctionComponent<BaseErrorViewProps> = ({
  error
}) => {
  return (
    <Container>
      <AuthEmailFailureSvg />
      <Title>
        <FormattedMessage id="auth.email.verification.error.view.title" />
      </Title>
      <Paragraph>
        <FormattedError error={error} />
      </Paragraph>
      <Footer />
    </Container>
  );
};

const PasswordResetErrorView: FunctionComponent<BaseErrorViewProps> = ({
  error
}) => {
  return (
    <Container>
      <AuthLockFailureSvg />
      <Title>
        <FormattedMessage id="auth.password.reset.error.view.title" />
      </Title>
      <Paragraph>
        <FormattedError error={error} />
      </Paragraph>
      <Footer />
    </Container>
  );
};

const UnknownErrorView: FunctionComponent<BaseErrorViewProps> = ({ error }) => {
  // TODO: надо ли показывать текст ошибок, которые сложно классифицировать?
  return (
    <Container>
      <AuthSadFaceFailureSvg />
      <Title>
        <FormattedMessage id="auth.unknown.error.view.title" />
      </Title>
      <Paragraph>
        <FormattedError error={error} />
      </Paragraph>
      <Footer />
    </Container>
  );
};

interface ErrorViewProps extends BaseErrorViewProps {
  type: EmailActionType;
}

const ErrorView: FunctionComponent<ErrorViewProps> = ({ type, error }) => {
  let Component;
  if (type === EmailActionType.RESET_PASSWORD) {
    Component = PasswordResetErrorView;
  } else if (type === EmailActionType.VERIFY_EMAIL) {
    Component = EmailVerificationErrorView;
  } else {
    Component = UnknownErrorView;
  }
  return <Component error={error} />;
};

export default ErrorView;
