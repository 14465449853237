// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useEffect, useReducer } from 'react';

import { actions, reducer, Action, State } from 'grid/store';

const Context = React.createContext<State | undefined>(undefined);

const DispatchContext = React.createContext<React.Dispatch<Action> | undefined>(
  undefined
);

export const usePowerGridContext = (): State => {
  const context = React.useContext(Context);
  if (context === undefined) {
    throw new Error(
      'usePowerGridContext хук должен быть использован в контексте провайдера'
    );
  }
  return context;
};

export const usePowerGridDispatch = (): React.Dispatch<Action> => {
  const context = React.useContext(DispatchContext);
  if (context === undefined) {
    throw new Error(
      'usePowerGridDispatch хук должен быть использован в контексте провайдера'
    );
  }
  return context;
};

export interface PowerGridProviderProps {
  value: State;
}

export const PowerGridProvider: FunctionComponent<PowerGridProviderProps> = ({
  children,
  value
}) => {
  const [state, dispatch] = useReducer(reducer, value);

  useEffect(() => {
    dispatch(actions.setContext(value));
  }, [value, dispatch]);

  return (
    <Context.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </Context.Provider>
  );
};
