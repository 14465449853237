// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useEffect, useRef } from 'react';
import { message } from 'antd';

import LoadingMask from 'components/loading/LoadingMask';
import Message from 'components/message/Message';
import { PowerGridMode } from 'grid';
import { usePowerGridContext, usePowerGridDispatch } from 'grid/context';
import { useUpdatePowerGrid } from 'grid/hooks';
import { actions } from 'grid/store';

const PowerGridModeFeature: FunctionComponent = () => {
  const { grid } = usePowerGridContext();
  const ref = useRef(grid);
  const dispatch = usePowerGridDispatch();

  const [update, state] = useUpdatePowerGrid();

  useEffect(() => {
    const { id, mode } = ref.current;
    message.destroy();
    update({
      id,
      mode:
        mode === PowerGridMode.CONFIGURATION
          ? PowerGridMode.OPERATION
          : PowerGridMode.CONFIGURATION
    });
  }, [ref, update]);

  useEffect(() => {
    const { error, data } = state;
    if (error || data) {
      // деактивируем расчет режима баланса
      dispatch(actions.setActiveFeature());
    }
    if (error) {
      message.error({
        duration: 0,
        content: (
          <Message type="error">Произошла непредвиденная ошибка.</Message>
        )
      });
      console.error(error);
    } else if (data) {
      if (data.mode === PowerGridMode.CONFIGURATION) {
        message.info({
          content: (
            <Message type="info">
              Сеть переведена в режим редактирования.
            </Message>
          )
        });
      } else {
        message.info({
          content: (
            <Message type="info">Сеть переведена в режим эксплуатации.</Message>
          )
        });
      }
      dispatch(actions.setPowerGrid(data));
    }
  }, [state, dispatch]);

  return <LoadingMask />;
};

export default PowerGridModeFeature;
