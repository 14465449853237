// © ООО «Эдиспа», 2022

import { Dispatch, useCallback, useEffect } from 'react';
import { getAuth, User } from 'firebase/auth';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  serverTimestamp,
  updateDoc,
  where
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import flatten from 'flat';
import { isEmpty } from 'lodash';
import { DeepPartial } from 'simplytyped';

import { getCurrentUser } from 'auth/utils';
import {
  convertDocumentToPowerGrid,
  PowerGrid,
  PowerGridMode,
  Schema
} from 'grid';
import useDocumentConverter from 'grid/converter';
import { actions, State, useFetch, useList, useReducer } from 'utils/hooks';
import { compact } from 'utils/object';
import { CodeError } from 'utils/CodeError';

export const useListPowerGrids = () =>
  useList(async () => {
    const currentUser = getCurrentUser();

    const scopes = [currentUser.uid, 'public'];

    const db = getFirestore();
    const gridsRef = collection(db, 'grids');
    const listPowerGridsQuery = query(gridsRef, where('scope', 'in', scopes));
    const { docs } = await getDocs(listPowerGridsQuery);

    return docs.map(convertDocumentToPowerGrid);
  });

interface CreatePowerGridParams {
  title: string;
  description: string;
}

export const useCreatePowerGrid = () =>
  useFetch<PowerGrid, CreatePowerGridParams>(async params => {
    const { title, description } = params;
    const { uid, displayName } = getAuth().currentUser as User;

    const db = getFirestore();
    const gridsRef = collection(db, 'grids');
    const ref = await addDoc(gridsRef, {
      title,
      description,
      owner: {
        id: uid,
        displayName
      },
      scope: uid,
      mode: PowerGridMode.CONFIGURATION,
      schema: {
        nodes: [],
        edges: [],
        version: 1
      },
      createdAt: serverTimestamp()
    });
    const doc = await getDoc(ref);
    return convertDocumentToPowerGrid(doc);
  });

export const useReadPowerGrid = (id: string): State<PowerGrid> => {
  const [state, dispatch] = useReducer<PowerGrid>({
    loading: true
  });
  const converter = useDocumentConverter();

  useEffect(() => {
    const readPowerGrid = async (id: string) => {
      try {
        const db = getFirestore();
        const gridRef = doc(db, 'grids', id);
        const gridDoc = await getDoc(gridRef);
        const powerGrid = converter.toPowerGrid(gridDoc);
        dispatch(actions.onSuccess(powerGrid));
      } catch (error: any) {
        dispatch(actions.onFailure(error));
      }
    };

    readPowerGrid(id);
  }, [dispatch, converter, id]);

  return state;
};

type UpdatePowerGridParams = Pick<PowerGrid, 'id'> &
  DeepPartial<
    Pick<PowerGrid, 'title' | 'description' | 'schema' | 'scope' | 'mode'>
  >;

export const useUpdatePowerGrid = (): [
  Dispatch<UpdatePowerGridParams>,
  State<PowerGrid>
] => {
  const [state, dispatch] = useReducer<PowerGrid>({
    loading: false
  });

  const dispatchUpdatePowerGrid = useCallback<Dispatch<UpdatePowerGridParams>>(
    async (params: UpdatePowerGridParams) => {
      try {
        const { id, title, description, schema, scope, mode } = params;

        const props = compact(
          flatten(
            {
              title,
              description,
              schema,
              scope,
              mode
            },
            { maxDepth: 2 }
          )
        );

        if (isEmpty(props)) {
          return;
        }

        dispatch(actions.onStart());
        const db = getFirestore();
        const gridRef = doc(db, 'grids', id);

        await updateDoc(gridRef, {
          ...props,
          updatedAt: serverTimestamp()
        });

        const gridDoc = await getDoc(gridRef);
        const result = convertDocumentToPowerGrid(gridDoc);

        dispatch(actions.onSuccess(result));
      } catch (error: any) {
        dispatch(actions.onFailure(error));
      }
    },
    [dispatch]
  );

  return [dispatchUpdatePowerGrid, state];
};

export const useDeletePowerGrid = () =>
  useFetch<boolean, string>(async id => {
    const db = getFirestore();
    const gridRef = doc(db, 'grids', id);
    await deleteDoc(gridRef);
    return true;
  });

interface ClonePowerGridParams {
  id: string;
  title: string;
  description: string;
}

export const useClonePowerGrid = () =>
  useFetch<PowerGrid, ClonePowerGridParams>(async params => {
    const { id, title, description } = params;

    const db = getFirestore();
    const sourceRef = doc(db, 'grids', id);
    const sourceDoc = await getDoc(sourceRef);

    const { schema } = convertDocumentToPowerGrid(sourceDoc);

    const { uid, displayName } = getAuth().currentUser as User;
    const gridsRef = collection(db, 'grids');
    const gridRef = await addDoc(gridsRef, {
      title,
      description,
      owner: {
        id: uid,
        displayName
      },
      mode: PowerGridMode.CONFIGURATION,
      schema,
      scope: uid,
      createdAt: serverTimestamp()
    });

    const gridDoc = await getDoc(gridRef);
    return convertDocumentToPowerGrid(gridDoc);
  });

interface ExportPowerGridParams {
  title: string;
  description: string;
  schema: Schema;
}

export const useExportPowerGrid = () =>
  useFetch<PowerGrid, ExportPowerGridParams>(async params => {
    const { title, description, schema } = params;

    const db = getFirestore();

    const { uid, displayName } = getAuth().currentUser as User;
    const gridsRef = collection(db, 'grids');
    const gridRef = await addDoc(gridsRef, {
      title,
      description,
      owner: {
        id: uid,
        displayName
      },
      mode: PowerGridMode.CONFIGURATION,
      schema,
      scope: uid,
      createdAt: serverTimestamp()
    });

    const gridDoc = await getDoc(gridRef);
    return convertDocumentToPowerGrid(gridDoc);
  });

interface CalcBalanceParams {
  schema: Schema;
}

export const useCalcPowerFlow = () =>
  useFetch<Schema, CalcBalanceParams>(async params => {
    const { schema } = params;

    const functions = getFunctions();
    const calcPowerFlow = httpsCallable<Schema, Schema>(
      functions,
      'calcPowerFlow'
    );

    const { data } = await calcPowerFlow(schema);
    return data;
  });

interface UpdateSchemaParams {
  id: string;
  schema: Schema;
}

type UpdateSchemaResponse =
  | {
      status: 'success';
      details: PowerGrid;
    }
  | {
      status: 'failure';
      details: Schema;
    };

export const useUpdateSchema = () =>
  useFetch<UpdateSchemaResponse, UpdateSchemaParams>(async params => {
    const { id, schema } = params;

    const functions = getFunctions();
    const updateSchema = httpsCallable<
      UpdateSchemaParams,
      UpdateSchemaResponse
    >(functions, 'updateSchema');

    const { data } = await updateSchema({ id, schema });
    return data;
  });

export interface ValidateSchemaResponse {
  schema: Schema;
  isValid: boolean;
}

export const useExportToJson = () =>
  useFetch<boolean, Schema>(async input => {
    const functions = getFunctions();
    const validateSchema = httpsCallable<Schema, ValidateSchemaResponse>(
      functions,
      'validateSchema'
    );
    const result = await validateSchema(input);
    const { schema, isValid } = result.data;
    if (!isValid) {
      throw new CodeError('edispa/invalid-exported-schema');
    }
    const json = JSON.stringify(schema, null, 2);
    const blob = new Blob([json], { type: 'application/json' });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = `schema-${Date.now()}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return true;
  });
