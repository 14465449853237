// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useLayoutEffect, useState } from 'react';
import { ReactFlowProvider } from 'react-flow-renderer';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';

import { Row } from 'components/Layout';
import { PowerGridMode } from 'grid';
import ActiveViewSelector from 'grid/components/ActiveViewSelector';
import GraphView from 'grid/components/GraphView';
import NodeListView from 'grid/components/node/ListView';
import EdgeListView from 'grid/components/edge/ListView';
import PageTitle from 'grid/components/PageTitle';
import PrimaryToolbar from 'grid/components/toolbar/PrimaryToolbar';
import SecondaryToolbar from 'grid/components/toolbar/SecondaryToolbar';
import { usePowerGridContext } from 'grid/context';
import { PowerGridView } from 'grid/store';
import { actions as page, useDispatchPageContext } from 'page/context';
import { Route } from 'router';

const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(600px, 1fr);
  grid-template-areas: 'header' 'content';
  grid-template-rows: max-content 1fr;
  overflow: auto;
`;

const Header = styled(Row)`
  background-color: #37174a;
  height: 56px;
  align-items: center;
  padding: 0 16px;
  flex-shrink: 0;
  grid-area: header;
`;

enum View {
  NODE_LIST = 'nodes',
  EDGE_LIST = 'edges',
  GRAPH = 'graph',
  ADVANCED = 'advanced'
}

const PowerGridMainView: FunctionComponent = () => {
  const [activeViewKey, setActiveViewKey] = useState(View.GRAPH);
  const dispatch = useDispatchPageContext();
  const { grid, view } = usePowerGridContext();
  const { title, mode } = grid;

  useLayoutEffect(() => {
    if (view !== PowerGridView.MAIN) {
      return;
    }

    let id;
    if (mode === PowerGridMode.CONFIGURATION) {
      id = 'page.power.grid.details.title.configuration';
    } else if (mode === PowerGridMode.OPERATION) {
      id = 'page.power.grid.details.title.operation';
    }
    dispatch(
      page.setContext({
        route: Route.HOME,
        title: (
          <PageTitle
            id={id}
            values={{
              title
            }}
          />
        )
      })
    );
  }, [title, view, mode, dispatch]);

  const onViewChange = (key: string) => setActiveViewKey(key as View);

  return (
    <Container
      style={view === PowerGridView.MAIN ? undefined : { display: 'none' }}
    >
      <Header>
        <PrimaryToolbar />
        <ActiveViewSelector
          activeViewKey={activeViewKey}
          views={[
            {
              key: View.GRAPH,
              label: <FormattedMessage id="label.schema" />
            },
            {
              key: View.NODE_LIST,
              label: <FormattedMessage id="label.nodes" />
            },
            {
              key: View.EDGE_LIST,
              label: <FormattedMessage id="label.edges" />
            }
          ]}
          onChange={onViewChange}
        />
        <SecondaryToolbar />
      </Header>
      <NodeListView
        style={
          activeViewKey === View.NODE_LIST ? undefined : { display: 'none' }
        }
      />
      <EdgeListView
        style={
          activeViewKey === View.EDGE_LIST ? undefined : { display: 'none' }
        }
      />
      <ReactFlowProvider>
        <GraphView
          style={activeViewKey === View.GRAPH ? undefined : { display: 'none' }}
        />
      </ReactFlowProvider>
    </Container>
  );
};

export default PowerGridMainView;
