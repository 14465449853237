// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { message } from 'antd';
import { createGlobalStyle, keyframes } from 'styled-components/macro';

import style from 'themes';

message.config({
  prefixCls: 'edispa-message',
  getContainer: () => document.getElementById('root') as HTMLElement
});

const MessageMoveOut = keyframes`
  from {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  to {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
`;

const GlobalStyle = createGlobalStyle`

  .edispa-message {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    position: fixed;
    bottom: 8px;
    left: 0;
    z-index: 10;
    width: 100%;
    pointer-events: none;

    .edispa-message-notice {
      padding: 8px;
      text-align: center;

      .edispa-message-notice-content {
        display: inline-block;
        border-radius: 8px;
        box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
        pointer-events: all;
        
        .edispa-message-custom-content {
          border-radius: 8px;
          padding: 12px;
          color: ${style('text.body.four.light.color')};

          .anticon {
            display: none;
          }

          p {
            margin: 0;
          }

          &.edispa-message-success {
            background: ${style('message.success.background')};
          }

          &.edispa-message-warning {
            background: ${style('message.warning.background')};
          }

          &.edispa-message-info {
            background: ${style('message.info.background')};
          }

          &.edispa-message-error {
            background: ${style('message.error.background')};
          }
        }
      }
      &.edispa-move-up-leave.edispa-move-up-leave-active {
        animation-name: ${MessageMoveOut};
        animation-duration: 0.3s;
      }
    }

  }
`;

const GlobalStyleWrapper: FunctionComponent = () => {
  const location = useLocation();

  useEffect(() => {
    message.destroy();
  }, [location]);

  return <GlobalStyle />;
};

export default GlobalStyleWrapper;
