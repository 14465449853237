// © ООО «Эдиспа», 2022

import React, { FunctionComponent, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components/macro';

import Form from 'auth/components/form/Form';
import Input from 'auth/components/input/Input';
import PasswordInput from 'auth/components/input/PasswordInput';
import LinkButton from 'auth/components/button/LinkButton';
import { Title, Paragraph } from 'auth/components/Typography';
import { useQuery, useSignInUser } from 'auth/hooks';
import { useDispatch } from 'auth/ui/context';
import { actions, ViewType } from 'auth/ui/store';
import Alert from 'components/Alert';
import SubmitButton from 'components/button/SubmitButton';
import { Route } from 'router';

const { Item } = Form;

const Header = styled.div`
  ${Title} {
    margin-bottom: 16px;
  }
`;

const Container = styled.div`
  max-width: 400px;
  margin: 0 auto;
  ${Header}, ${Alert} {
    margin-bottom: 32px;
  }
  ${SubmitButton} {
    width: 50%;
  }
`;

const PasswordLabel = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0 8px;
`;

const SignInView: FunctionComponent = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const [signInUser, { loading, error, data }] = useSignInUser();

  const onFinish = (values: any) => {
    const { email, password } = values;
    signInUser({ email, password });
  };

  const link = (chunks: ReactNode) => {
    const onRegisterClick = () => dispatch(actions.setView(ViewType.SIGN_UP));
    return <LinkButton onClick={onRegisterClick}>{chunks}</LinkButton>;
  };

  if (data) {
    return <Navigate to={query.get('redirectUrl') || Route.HOME} />;
  }

  return (
    <Container>
      <Header>
        <Title>
          <FormattedMessage id="auth.sign.in.view.title" />
        </Title>
        <Paragraph>
          <FormattedMessage
            id="auth.sign.in.view.instructions.sign.up"
            values={{
              link
            }}
          />
        </Paragraph>
      </Header>
      <Alert error={error} />
      <Form
        onFinish={onFinish}
        initialValues={{
          email: '',
          password: ''
        }}
      >
        <Item
          name="email"
          rules={[{ required: true }, { type: 'email' }]}
          label={<FormattedMessage id="form.label.email" />}
        >
          <Input />
        </Item>
        <Item noStyle>
          <PasswordLabel>
            <span>
              <FormattedMessage id="form.label.password" />
            </span>
            <LinkButton
              onClick={() => dispatch(actions.setView(ViewType.PASSWORD_RESET))}
            >
              <FormattedMessage id="auth.sign.in.view.instructions.password.reset" />
            </LinkButton>
          </PasswordLabel>
        </Item>
        <Item name="password" rules={[{ required: true }]}>
          <PasswordInput />
        </Item>
        <Item>
          <SubmitButton loading={loading}>
            <FormattedMessage id="button.sign.in" />
          </SubmitButton>
        </Item>
      </Form>
    </Container>
  );
};

export default SignInView;
