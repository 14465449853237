// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';

import Alert from 'components/Alert';
import ErrorPage from 'components/ErrorPage';
import Loading from 'components/loading/Loading';
import { Title } from 'components/Typography';
import { PowerGrid } from 'grid';
import CreatePowerGridModal from 'grid/components/CreatePowerGridModal';
import PowerGridCard from 'grid/components/PowerGridCard';
import { useListPowerGrids } from 'grid/hooks';
import { PlusLargeSvg } from 'svg';
import style from 'themes';
import { isCodeError } from 'utils/CodeError';
import { useFormatMessage } from 'utils/format';

const Content = styled.div`
  display: grid;
  grid-row-gap: 16px;
  row-gap: 16px;
  justify-items: stretch;
  align-items: stretch;
  justify-content: center;
  grid-auto-flow: row;
  flex: 1;
  grid-auto-rows: 200px;
  grid-column-gap: 16px;
  column-gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(336px, 1fr));
`;

const Container = styled.div`
  background-color: ${style('page.power.grid.list.background.color')};
  padding: 24px;
  ${Title} {
    margin-bottom: 24px;
  }
  flex: 1;
`;

const PlusIcon = styled(PlusLargeSvg)`
  font-size: 80px;
`;

const NewPowerGridButton = styled.button`
  background: white;
  border-radius: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  :hover {
    color: ${style('page.power.grid.list.card.hover.border.color')};
    border: 1px solid ${style('page.power.grid.list.card.hover.border.color')};
  }
  :active {
    color: ${style('page.power.grid.list.card.active.border.color')};
    border: 1px solid ${style('page.power.grid.list.card.active.border.color')};
  }
`;

const PowerGridListPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const { loading, error, data } = useListPowerGrids();
  const [modalVisible, setModalVisible] = useState(false);
  const formatMessage = useFormatMessage();

  if (loading) {
    return <Loading />;
  } else if (error) {
    if (isCodeError(error)) {
      const { code } = error;
      if (code === 'edispa/power-grid-not-found') {
        return <ErrorPage type="not-found" />;
      } else if (code === 'permission-denied') {
        return <ErrorPage type="permission-denied" />;
      }
    }
    return <Alert error={error} />;
  }

  const onCreatePowerGrid = (grid: PowerGrid) => {
    navigate(`/grids/${grid.id}`);
  };

  const showModal = () => setModalVisible(true);

  const closeModal = () => setModalVisible(false);

  return (
    <Container>
      <Helmet>
        <title>{formatMessage('title.edispa')}</title>
      </Helmet>
      <Title>
        <FormattedMessage id="page.power.grid.list.title" />
      </Title>
      <Content>
        <NewPowerGridButton onClick={showModal}>
          <PlusIcon />
        </NewPowerGridButton>
        {data.map(item => (
          <PowerGridCard key={item.id} value={item} />
        ))}
      </Content>
      <CreatePowerGridModal
        visible={modalVisible}
        onComplete={onCreatePowerGrid}
        onCancel={closeModal}
      />
    </Container>
  );
};

export default PowerGridListPage;
