// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { FormItemProps, FormProps } from 'antd/lib/form';
import styled from 'styled-components/macro';

import Form from 'components/form/Form';
import Input from 'components/input/Input';
import TextArea from 'components/input/TextArea';
import { FormattedMessage } from 'react-intl';

const { Item } = Form;

export interface PowerGridDetailsFormProps {
  /**
   * футер
   */
  footer: FormItemProps['children'];
}

const PowerGridDetailsForm: FunctionComponent<
  PowerGridDetailsFormProps & FormProps
> = ({ footer, ...otherProps }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} layout="vertical" {...otherProps}>
      <Item
        name="title"
        label={<FormattedMessage id="form.label.title" />}
        rules={[{ required: true }]}
      >
        <Input autoFocus />
      </Item>
      <Item
        name="description"
        label={<FormattedMessage id="label.description" />}
      >
        <TextArea rows={4} />
      </Item>
      {footer && <Item noStyle>{footer}</Item>}
    </Form>
  );
};

export default styled(PowerGridDetailsForm)``;
