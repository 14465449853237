// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { Modal as AntModal } from 'antd';
import styled from 'styled-components/macro';

import { ModalProps as AntModalProps } from 'antd/lib/modal/Modal';
// TODO: move from auth
import { Paragraph } from 'auth/components/Typography';

const Container = styled(AntModal)`
  &.ant-modal {
    top: 200px;
    .ant-modal-content {
      border-radius: 16px;
    }
  }
`;

export const Footer = styled.div`
  margin-top: 32px;
  white-space: nowrap;
  display: flex;
  > * + * {
    margin-left: 16px;
  }
`;

const Title = styled(Paragraph)`
  font-size: 20px;
  font-weight: 600;
`;

export type ModalProps = Omit<AntModalProps, 'closable' | 'destroyOnClose'>;

const Modal: FunctionComponent<ModalProps> = ({
  children,
  title,
  footer,
  ...otherProps
}) => (
  <Container
    {...otherProps}
    closable={false}
    footer={null}
    destroyOnClose={true}
  >
    {!!title && <Title>{title}</Title>}
    {children}
    {footer}
  </Container>
);

export default Modal;
