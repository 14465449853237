// © ООО «Эдиспа», 2022

import { get, mapValues } from 'lodash';

export interface Themeable {
  theme: Record<string, string>;
}

export interface StyledProps {
  className?: string;
}

export function attrs<T extends Record<string, unknown>>(
  attrs: T
): { [key: string]: any } {
  return (props: T & Themeable) =>
    mapValues(attrs, (path: string, key: keyof T): any => {
      return props[key] || get(props.theme, path);
    });
}

const style =
  (path: string) =>
  (props: Themeable): string | undefined => {
    const value = get(props.theme, path);
    if (!value) {
      console.warn(`The path ${path} has no matching value in the theme`);
    }
    return value;
  };

export default style;
