// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';

import Form, { FormProps } from 'components/form/Form';

type AuthFormProps = Omit<
  FormProps,
  'form' | 'layout' | 'requiredMark' | 'validateTrigger' | 'onValuesChange'
>;

type IAuthForm<T> = FunctionComponent<T> & {
  Item: typeof Form.Item;
};

const AuthForm: IAuthForm<AuthFormProps> = props => {
  const [form] = Form.useForm();

  const onValuesChange = (changedValues: any) => {
    Object.keys(changedValues).forEach(field => {
      const error = form.getFieldError(field);
      if (!error.length) {
        return;
      }
      form.setFields([
        {
          name: field,
          errors: []
        }
      ]);
    });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark={false}
      validateTrigger="onSubmit"
      onValuesChange={onValuesChange}
      {...props}
    />
  );
};

AuthForm.Item = Form.Item;

export default AuthForm;
