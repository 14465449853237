// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { message } from 'antd';
import { isNil, omitBy } from 'lodash';

import LoadingMask from 'components/loading/LoadingMask';
import Message from 'components/message/Message';
import { isOverloaded, validateEmergency } from 'emergency/utils';
import { Edge, Node } from 'grid';
import { usePowerGridContext, usePowerGridDispatch } from 'grid/context';
import { useCalcPowerFlow } from 'grid/hooks';
import { actions, PowerGridFeature } from 'grid/store';

const PowerFlowFeature: FunctionComponent = () => {
  const intl = useIntl();
  const { grid } = usePowerGridContext();
  const { schema } = grid;
  const ref = useRef(schema);
  const dispatch = usePowerGridDispatch();
  const [calcBalance, state] = useCalcPowerFlow();

  useEffect(() => {
    message.destroy();
    calcBalance({ schema: ref.current });
  }, [calcBalance, ref]);

  useEffect(() => {
    const { error, data } = state;
    if (error) {
      message.error({
        duration: 0,
        content: (
          <Message type="error" closable>
            Ошибка расчета режима баланса.
          </Message>
        )
      });
      console.error(error);
      // деактивируем расчет режима баланса
      dispatch(actions.setActiveFeature());
    } else if (data) {
      const { nodes } = data;
      let { edges } = data;
      // проверяем, есть ли в схеме перегруженные ветви
      if (edges.some(isOverloaded)) {
        edges = edges.map(validateEmergency);
        // активируем функционал ликвидации аварийного режима
        dispatch(actions.setActiveFeature(PowerGridFeature.EMERGENCY));
      } else {
        message.success({
          content: (
            <Message type="success">
              {intl.formatMessage({
                id: 'message.calc.power.flow.success'
              })}
            </Message>
          )
        });
        // деактивируем расчет режима баланса
        dispatch(actions.setActiveFeature());
      }
      // TODO: расчет режима не должен возвращать объекты с пустыми полями - это плохо влияет на валидацию
      dispatch(
        actions.updateSchema({
          ...data,
          nodes: nodes.map(node => omitBy<Node>(node, isNil) as Node),
          edges: edges.map(edge => omitBy<Edge>(edge, isNil) as Edge)
        })
      );
    }
  }, [state, dispatch]);

  return <LoadingMask />;
};

export default PowerFlowFeature;
