// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';

import FormattedError from 'components/i18n/FormattedError';

import style, { StyledProps } from 'themes';
import { isString } from 'lodash';

const Container = styled.div`
  background-color: ${style('alert.background')};
  border-radius: 8px;
  color: ${style('alert.color')};
  padding: 16px;
  margin-bottom: 24px;
`;

export interface AlertProps {
  error?: Error | string;
}

const Alert: FunctionComponent<AlertProps & StyledProps> = ({
  error,
  className
}) => {
  if (error) {
    return (
      <Container className={className}>
        {isString(error) ? error : <FormattedError error={error} />}
      </Container>
    );
  }
  return null;
};

const StyledAlert = styled(Alert)``;

export default StyledAlert;
