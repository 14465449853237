// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'antd';

import IconButton from 'grid/components/toolbar/IconButton';
import { usePowerGridDispatch } from 'grid/context';
import { actions, PowerGridFeature } from 'grid/store';
import { IconPlaySvg } from 'svg';

const CalcPowerFlowButton: FunctionComponent = () => {
  const dispatch = usePowerGridDispatch();

  const onClick = () =>
    dispatch(actions.setActiveFeature(PowerGridFeature.POWER_FLOW));

  return (
    <Tooltip
      mouseEnterDelay={1}
      title={<FormattedMessage id="button.calc.power.flow" />}
    >
      <IconButton onClick={onClick}>
        <IconPlaySvg />
      </IconButton>
    </Tooltip>
  );
};

export default CalcPowerFlowButton;
