// © ООО «Эдиспа», 2022

import { DocumentSnapshot } from 'firebase/firestore';
import { mapValues, pick } from 'lodash';

type DocumentConverter<T> = (doc: DocumentSnapshot) => T;

export function createConverter<T>(
  paths: (keyof T)[],
  iteratee?: (value: any, key: string, object: any) => any
): DocumentConverter<T> {
  return (doc: DocumentSnapshot): T => {
    const data = doc.data();
    if (!data) {
      // TODO: локализация ошибок
      throw new Error('Документ не найден.');
    }

    const props = pick(data, paths) as any;
    if (iteratee) {
      return mapValues(props, iteratee) as T;
    }
    return props as T;
  };
}
