// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { isCodeError } from 'utils/CodeError';

export interface FormattedErrorProps {
  error: Error;
}

const FormattedError: FunctionComponent<FormattedErrorProps> = ({ error }) => {
  // TODO: продумать механизм автоматической отправки отчета об ошибке
  console.error(error.message);
  return (
    <FormattedMessage
      id={isCodeError(error) ? error.code : 'auth/edispa/unexpected-error'}
    />
  );
};

export default FormattedError;
