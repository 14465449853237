// © ООО «Эдиспа», 2022

import styled from 'styled-components/macro';

import IconButton from 'components/button/IconButton';

const StyledIconButton = styled(IconButton)`
  color: white;
`;

export default StyledIconButton;
