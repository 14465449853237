// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';

import { signOut } from 'auth/utils';
import Container from 'auth/components/Container';
import { Title } from 'auth/components/Typography';
import Button from 'components/button/Button';
import MarkdownMessage from 'components/i18n/MarkdownMessage';
import { AuthUserWaitingSvg } from 'svg';

const Content = styled.div`
  max-width: 640px;
  margin: 0 auto;
  ${Title} {
    margin-top: 24px;
    margin-bottom: 32px;
  }
  ${MarkdownMessage} {
    margin-bottom: 32px;
  }
`;

const InactiveUserPage: FunctionComponent = () => {
  const onClick = () => signOut();

  return (
    <Container>
      <Content>
        <AuthUserWaitingSvg />
        <Title>
          <FormattedMessage id="auth.inactive.user.view.title" />
        </Title>
        <MarkdownMessage id="auth.inactive.user.view.instructions" />
        <Button type="primary" onClick={onClick}>
          <FormattedMessage id="button.go.to.sign.in" />
        </Button>
      </Content>
    </Container>
  );
};

export default InactiveUserPage;
