// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { StyledProps } from 'themes';

const Container = styled.div`
  position: relative;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  border-radius: 16px;
  padding: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Overlay = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 16px;
`;

export interface EntityCardProps {
  url: string;
}

const EntityCard: FunctionComponent<EntityCardProps & StyledProps> = ({
  url,
  className,
  children
}) => (
  <Container className={className}>
    <Link to={url}>
      <Overlay />
    </Link>
    <Content>{children}</Content>
  </Container>
);

export default EntityCard;
