// © ООО «Эдиспа», 2022

import React, { ReactNode } from 'react';
import { Tooltip } from 'antd';
import { identity, isNil } from 'lodash';
import styled, { css } from 'styled-components/macro';

import { Messages } from 'grid';
import { IconExclamationCircleSvg, IconInfoCircleSvg } from 'svg';
import style from 'themes';
import { useFormatMessage } from 'utils/format';

const Container = styled.ul`
  padding: 0;
  margin: 0;
  display: block;
`;

const Label = styled.div``;

const Value = styled.div`
  text-align: end;
  padding-right: 8px;
  max-width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

type ItemProps = {
  invalid: boolean;
};

const Item = styled.li<ItemProps>`
  display: grid;
  grid-template-columns: 30% 60% 10%;
  padding: 12px 16px;
  align-items: center;
  border-bottom: 1px solid #f4f3f5;
  ${props =>
    props.invalid &&
    css`
      ${Value} {
        color: ${style('color.red.one')};
      }
      background: ${style('color.red.four')};
    `}
`;

const InfoIcon = styled(IconInfoCircleSvg)`
  font-size: 20px;
  justify-self: center;
`;

const ErrorIcon = styled(IconExclamationCircleSvg)`
  font-size: 20px;
  vertical-align: middle;
  margin-top: -2px;
`;

export interface AttrProps<T> {
  dataIndex: keyof T & string;
  label: ReactNode;
  render?: (value: any, record: T) => ReactNode;
  info?: ReactNode;
  disabled?: boolean;
}

export interface AttrListProps<T> {
  items: AttrProps<T>[];
  data: T;
}

export default function AttrList<T extends { messages?: Messages }>(
  props: AttrListProps<T>
) {
  const formatMessage = useFormatMessage();
  const { items, data } = props;
  return (
    <Container>
      {items
        .filter(item => !item.disabled)
        .map(item => {
          const { dataIndex, label, render = identity, info } = item;
          let value = render(data[dataIndex], data);
          const { messages } = data;
          const message = messages ? messages[dataIndex] : undefined;
          if (message) {
            const title = formatMessage(
              `validation/${message.code}`,
              message.details
            );
            value = (
              <Tooltip title={title} placement="topRight">
                {isNil(value) ? <ErrorIcon /> : value}
              </Tooltip>
            );
          }
          return (
            <Item key={dataIndex} invalid={!!message}>
              <Label>{label}</Label>
              <Value>{value}</Value>
              {info && (
                <Tooltip title={info} placement="topRight">
                  <InfoIcon />
                </Tooltip>
              )}
            </Item>
          );
        })}
    </Container>
  );
}
