// © ООО «Эдиспа», 2022

import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { isError } from 'lodash';

import { isCodeError } from 'utils/CodeError';

type InputValue = number | string;

/**
 * @returns true если входящее значение является числом или его строковым представлением.
 */
export const isNumeric = (value: InputValue) => {
  if (typeof value === 'number') {
    // проверяем, является ли число конечным
    return Number.isFinite(value);
  } else if (typeof value === 'string') {
    // проверяем, является ли строка непустой и в ней находится число
    return !!value.trim() && !isNaN(value as any);
  }
};

export const formatNumber = (value?: number): string | undefined =>
  typeof value === 'number' ? value.toFixed(2) : undefined;

const formatters = {
  number: formatNumber
};

export const useFormatMessage = () => {
  const intl = useIntl();

  return useCallback(
    (id: Error | string, values?: any) => {
      if (isError(id)) {
        if (isCodeError(id)) {
          id = id.code;
        } else {
          return id.message;
        }
      }
      return intl.formatMessage(
        {
          id
        },
        values
      );
    },
    [intl]
  );
};

export default formatters;
