// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Dropdown } from 'antd';
import { isFunction } from 'lodash';
import styled from 'styled-components/macro';

import IconButton from 'components/button/IconButton';
import UserMenu from 'page/components/UserMenu';
import { usePageContext } from 'page/context';
import { Route } from 'router';
import { ArrowLeftSvg, IconHomeSvg, LogoSvg } from 'svg';
import UserIcon from 'svg/components/UserIcon';
import style from 'themes';

const LogoIcon = styled(LogoSvg)`
  height: 24px;
  width: auto;
`;

const Logo = styled(Link)`
  background-color: ${style('main.header.logo.background')};
  color: ${style('main.header.logo.color')};
  :hover {
    color: ${style('main.header.logo.color')};
  }
  padding: 16px;
  display: flex;
`;

const Left = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
`;

const Title = styled.div`
  // TODO: theme
  color: white;
  flex-shrink: 0;
`;

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  ${UserIcon} {
    margin-right: 24px;
  }
`;

const Breadcrumb = styled(Link)`
  font-size: 24px;
  color: ${style('auth.icon.color')};
  display: inherit;
  :hover {
    color: ${style('auth.icon.hover.color')};
  }
`;

const StyledIconButton = styled(IconButton)`
  color: white;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: ${style('main.header.background')};
  ${Breadcrumb}, ${StyledIconButton} {
    margin-left: 16px;
  }
  grid-area: header;
`;

const Header: FunctionComponent = () => {
  const { pathname } = useLocation();
  const { callback, title } = usePageContext();
  let { route } = usePageContext();

  if (!route && !callback) {
    route = Route.HOME;
  }

  return (
    <Container>
      <Left>
        <Logo to={Route.HOME}>
          <LogoIcon />
        </Logo>
        {pathname !== Route.HOME &&
          (route ? (
            <Breadcrumb to={route}>
              {route === Route.HOME ? <IconHomeSvg /> : <ArrowLeftSvg />}
            </Breadcrumb>
          ) : (
            callback && (
              <StyledIconButton onClick={callback}>
                <ArrowLeftSvg />
              </StyledIconButton>
            )
          ))}
      </Left>
      {title && <Title>{isFunction(title) ? title() : title}</Title>}
      <Right>
        <Dropdown trigger={['click']} overlay={<UserMenu />}>
          <UserIcon />
        </Dropdown>
      </Right>
    </Container>
  );
};

export default Header;
