// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';

import Alert from 'components/Alert';
import Form from 'auth/components/form/Form';
import Input from 'auth/components/input/Input';
import PasswordInput from 'auth/components/input/PasswordInput';
import { Title } from 'auth/components/Typography';
import { useSignUpUser } from 'auth/hooks';
import { useDispatch } from 'auth/ui/context';
import { actions, ViewType } from 'auth/ui/store';
import Button from 'components/button/Button';
import SubmitButton from 'components/button/SubmitButton';

const { Item } = Form;

const Container = styled.div`
  max-width: 400px;
  margin: 0 auto;
  ${Title}, ${Alert} {
    margin-bottom: 32px;
  }
`;

const Footer = styled.div`
  display: flex;
  ${Button} {
    flex: 1;
  }
  ${Button} + ${Button} {
    margin-left: 16px;
  }
`;

const SignUpView: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [signUpUser, { loading, error, data }] = useSignUpUser();

  const onFinish = (values: any) => {
    const { displayName, email, password } = values;
    signUpUser({
      displayName,
      email,
      password
    });
  };

  useEffect(() => {
    if (data) {
      dispatch(actions.verifyEmail(data));
    }
  }, [dispatch, data]);

  const onCancel = () => dispatch(actions.setView(ViewType.LOGIN));

  return (
    <Container>
      <Title>
        <FormattedMessage id="auth.sign.up.view.title" />
      </Title>
      <Alert error={error} />
      <Form
        onFinish={onFinish}
        initialValues={{
          displayName: '',
          email: '',
          password: ''
        }}
      >
        <Item
          name="displayName"
          rules={[{ required: true }]}
          label={<FormattedMessage id="form.label.full.name" />}
        >
          <Input />
        </Item>
        <Item
          name="email"
          rules={[{ required: true }, { type: 'email' }]}
          label={<FormattedMessage id="form.label.email" />}
        >
          <Input />
        </Item>
        <Item
          name="password"
          rules={[{ min: 6 }, { required: true }]}
          label={<FormattedMessage id="form.label.password" />}
        >
          <PasswordInput />
        </Item>
        <Item noStyle>
          <Footer>
            <SubmitButton loading={loading}>
              <FormattedMessage id="button.done" />
            </SubmitButton>
            <Button onClick={onCancel}>
              <FormattedMessage id="button.cancel" />
            </Button>
          </Footer>
        </Item>
      </Form>
    </Container>
  );
};

export default SignUpView;
