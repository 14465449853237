// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { Routes as ReactRoutes, Route as ReactRoute } from 'react-router-dom';

import ActionPage from 'auth/action/components/ActionPage';
import LoginPage from 'auth/components/LoginPage';
import ErrorPage from 'components/ErrorPage';
import SettingsPage from 'components/SettingsPage';
import PowerGridDetailsPage from 'grid/components/PowerGridDetailsPage';
import PowerGridListPage from 'grid/components/PowerGridListPage';
import HelpPage from 'help/components/HelpPage';
import { Route } from 'router';
import PrivateRoute from 'router/PrivateRoute';

const Routes: FunctionComponent = () => {
  return (
    <ReactRoutes>
      <ReactRoute path={Route.AUTH_ACTION} element={<ActionPage />} />
      <ReactRoute path={Route.LOGIN} element={<LoginPage />} />
      <PrivateRoute path={Route.HOME} element={<PowerGridListPage />} />
      <PrivateRoute
        path={Route.GRID_DETAILS}
        element={<PowerGridDetailsPage />}
      />
      <PrivateRoute path={Route.SETTINGS} element={<SettingsPage />} />
      <PrivateRoute path={Route.HELP} element={<HelpPage />} />
      <PrivateRoute path="*" element={<ErrorPage type="not-found" />} />
    </ReactRoutes>
  );
};

export default Routes;
