// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';

import { PowerGrid } from 'grid';
import Alert from 'components/Alert';
import Button from 'components/button/Button';
import SubmitButton from 'components/button/SubmitButton';
import { FormValues } from 'components/form/Form';
import { Toolbar } from 'components/Layout';
import Modal, { ModalProps } from 'components/modal/Modal';
import PowerGridDetailsForm from 'grid/components/PowerGridDetailsForm';
import { useCreatePowerGrid } from 'grid/hooks';

const Footer = styled(Toolbar)`
  justify-content: stretch;
  > * {
    flex: 1;
  }
`;

export interface CreatePowerGridModalProps extends ModalProps {
  onComplete: (grid: PowerGrid) => void;
}

const CreatePowerGridModal: FunctionComponent<CreatePowerGridModalProps> = ({
  onComplete,
  onCancel,
  ...otherProps
}) => {
  const [createPowerGrid, { loading, error, data }] = useCreatePowerGrid();

  const ref = useRef(onComplete);

  useEffect(() => {
    if (data) {
      const onComplete = ref.current;
      onComplete(data);
    }
  }, [data, ref]);

  if (ref.current !== onComplete) {
    ref.current = onComplete;
  }

  const onFinish = (values: FormValues) => {
    const { title, description } = values;
    createPowerGrid({
      title,
      description
    });
  };

  return (
    <Modal
      {...otherProps}
      title={<FormattedMessage id="modal.create.power.grid.title" />}
    >
      <Alert error={error} />
      <PowerGridDetailsForm
        requiredMark={false}
        initialValues={{
          title: '',
          description: ''
        }}
        onFinish={onFinish}
        footer={
          <Footer>
            <SubmitButton loading={loading}>
              <FormattedMessage id="button.done" />
            </SubmitButton>
            <Button type="secondary" onClick={onCancel}>
              <FormattedMessage id="button.cancel" />
            </Button>
          </Footer>
        }
      />
    </Modal>
  );
};

export default CreatePowerGridModal;
