// © ООО «Эдиспа», 2022

import styled from 'styled-components/macro';

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  > * + * {
    margin-left: 16px;
  }
  > .ant-divider.ant-divider-vertical + * {
    margin-left: 0;
  }
`;

export const Page = styled.div`
  padding: 16px;
`;

export const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Spacer = styled.div`
  flex: 1;
`;

// Базовый контейнер для всех экранов
export const Container = styled.div`
  padding: 24px 24px 0 24px;
  > * {
    margin-bottom: 24px;
  }
`;
