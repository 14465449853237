// © ООО «Эдиспа», 2022

import { Connection } from 'react-flow-renderer';
import styled from 'styled-components/macro';

import { NodeType } from 'grid';
import { getNodeSize } from 'grid/utils';

const balanceNodeSize = getNodeSize(NodeType.BALANCE);

export const BalanceNodeShape = styled.div`
  width: ${balanceNodeSize.width}px;
  height: ${balanceNodeSize.height}px;
  border-radius: 4px;
  background-color: white;
  border: 1px solid gray;
`;

const generationNodeSize = getNodeSize(NodeType.GENERATION);

export const GenerationNodeShape = styled.div`
  width: ${generationNodeSize.width}px;
  height: ${generationNodeSize.height}px;
  border-radius: 4px;
  background-color: white;
  border: 1px solid gray;
`;

const loadNodeSize = getNodeSize(NodeType.LOAD);

export const LoadNodeShape = styled.div`
  width: ${loadNodeSize.width}px;
  height: ${loadNodeSize.height}px;
  border-radius: 36px;
  background-color: white;
  border: 1px solid gray;
`;

export const NodeContainer = styled.div`
  font-size: 8px;
  .react-flow__handle.react-flow__handle-connecting {
    background: #ff6060;
  }
  .react-flow__handle.react-flow__handle-valid {
    background: #55dd99;
  }
`;

export const isValidConnection = (connection: Connection): boolean => {
  const { source, target } = connection;
  return !!source && !!target && source !== target;
};
