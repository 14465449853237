// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';

import Button, { ButtonProps } from 'components/button/Button';
import Spinner from 'svg/components/Spinner';

type SubmitButtonProps = Omit<ButtonProps, 'icon'> & {
  loading: boolean;
};

const SubmitButton: FunctionComponent<SubmitButtonProps> = ({
  loading,
  type = 'primary',
  htmlType = 'submit',
  onClick,
  ...otherProps
}) => (
  <Button
    type={type}
    htmlType={htmlType}
    icon={loading && <Spinner />}
    disabled={loading}
    onClick={loading ? undefined : onClick}
    {...otherProps}
  />
);

const StyledSubmitButton = styled(SubmitButton)``;

export default StyledSubmitButton;
