// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';

import { isAuthenticated, signOut } from 'auth/utils';
import FormattedError from 'components/i18n/FormattedError';
import { Title, Paragraph } from 'auth/components/Typography';
import Button from 'components/button/Button';
import { Route } from 'router';
import { AuthSadFaceFailureSvg } from 'svg';

const Container = styled.div`
  max-width: 640px;
  margin: 0 auto;
  ${Title} {
    margin-top: 24px;
    margin-bottom: 32px;
  }
  ${Paragraph} {
    margin-bottom: 32px;
  }
`;

const Footer: FunctionComponent = () => {
  const navigate = useNavigate();

  const onClick = () => {
    if (isAuthenticated()) {
      signOut();
    } else {
      navigate(Route.HOME);
    }
  };

  return (
    <Button type="primary" onClick={onClick}>
      <FormattedMessage id="button.go.to.sign.in" />
    </Button>
  );
};

export interface ErrorViewProps {
  error: Error;
}

const ErrorView: FunctionComponent<ErrorViewProps> = ({ error }) => {
  return (
    <Container>
      <AuthSadFaceFailureSvg />
      <Title>
        <FormattedMessage id="auth.unknown.error.view.title" />
      </Title>
      <Paragraph>
        <FormattedError error={error} />
      </Paragraph>
      <Footer />
    </Container>
  );
};

export default ErrorView;
