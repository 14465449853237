// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';

import { Paragraph } from 'components/Typography';
import { usePowerGridContext } from 'grid/context';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  > * {
    margin-bottom: 16px;
  }
`;

const Formatted = styled.pre`
  font-family: inherit;
  white-space: break-spaces;
  line-height: normal;
`;

const PowerGridPropertiesView: FunctionComponent = () => {
  const { grid } = usePowerGridContext();
  const { title, description } = grid;

  return (
    <Container>
      <Paragraph className="edispa-body-two-dark">{title}</Paragraph>
      <Formatted className="edispa-caption-text-one-light">
        {description}
      </Formatted>
    </Container>
  );
};

export default PowerGridPropertiesView;
