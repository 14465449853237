// © ООО «Эдиспа», 2022

import React, { FunctionComponent, ReactNode, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';

import TabPanel from 'components/tab/TabPanel';
import { Paragraph } from 'components/Typography';
import { Node } from 'grid';
import NewNodeList from 'grid/components/graph/NewNodeList';
import NodeList from 'grid/components/graph/NodeList';
import { usePowerGridContext } from 'grid/context';
import { denormalize } from 'grid/utils';

const EmptyNodeList = styled(Paragraph).attrs({
  className: 'edispa-caption-text-one-light'
})`
  margin: 16px;
`;

const Container = styled.div`
  width: 248px;
  flex-shrink: 0;
  display: block;
  overflow: auto;
  grid-area: node-list;
  .edispa-tab-panel {
    height: 100%;
    .edispa-tab-panel-content {
      overflow: auto;
    }
  }
`;

const Counter = styled.span`
  color: #fc7456;
`;

const counter = (chunks: ReactNode) => <Counter>{chunks}</Counter>;

export interface NodeListViewProps {
  selected?: Node;
}

const NodeListView: FunctionComponent<NodeListViewProps> = ({ selected }) => {
  const { grid } = usePowerGridContext();
  const { schema } = grid;

  const hiddenNodes = useMemo(() => {
    const { nodes, edges } = denormalize(schema);
    if (selected) {
      return edges.reduce<Node[]>((acc, edge) => {
        const { source, target } = edge;
        if (source.id === selected.id) {
          if (!target.layout) {
            acc.push(target);
          }
        } else if (target.id === selected.id) {
          if (!source.layout) {
            acc.push(source);
          }
        }
        return acc;
      }, []);
    } else {
      return nodes.filter((node: Node) => !node.layout);
    }
  }, [selected, schema]);

  const tabs = [
    {
      key: 'new',
      title: <FormattedMessage id="label.new.node" />,
      content: <NewNodeList />
    },
    {
      key: 'hidden',
      title: hiddenNodes.length ? (
        <FormattedMessage
          id="label.hidden.nodes.with.counter"
          values={{
            count: hiddenNodes.length,
            counter
          }}
        />
      ) : (
        <FormattedMessage id="label.hidden.nodes" />
      ),
      content:
        hiddenNodes.length > 0 ? (
          <NodeList nodes={hiddenNodes} />
        ) : (
          <EmptyNodeList>
            <FormattedMessage
              id={
                selected
                  ? 'text.no.hidden.linked.nodes'
                  : 'text.no.hidden.nodes'
              }
            />
          </EmptyNodeList>
        )
    }
  ];

  return (
    <Container>
      <TabPanel tabs={tabs} />
    </Container>
  );
};

export default NodeListView;
