// © ООО «Эдиспа», 2022

import React, { CSSProperties, FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useResizeDetector } from 'react-resize-detector';
import { isNumber } from 'lodash';
import styled from 'styled-components/macro';

import Button from 'components/button/Button';
import IconButton from 'components/button/IconButton';
import { Toolbar } from 'components/Layout';
import { Edge, PowerGridMode } from 'grid';
import EdgeDetailsModal, {
  EdgeDetailsModalProps
} from 'grid/components/edge/DetailsModal';
import { usePowerGridContext, usePowerGridDispatch } from 'grid/context';
import { actions } from 'grid/store';
import EdgeTable from 'grid/components/edge/Table';
import { IconEditSvg, IconTrashSvg } from 'svg';

const ActionToolbar = styled(Toolbar)`
  justify-content: center;
`;

const StyledToolbar = styled(Toolbar)`
  grid-area: toolbar;
  margin-bottom: 24px;
`;

const Content = styled.div`
  grid-area: table;
  overflow: auto;
`;

const Container = styled.div`
  display: grid;
  grid-area: content;
  padding: 24px 24px 0 24px;
  grid-template-areas: 'toolbar' 'table';
  grid-template-rows: max-content 1fr;
  overflow: auto;
`;

export interface EdgeListViewProps {
  style?: CSSProperties;
}

const EdgeListView: FunctionComponent<EdgeListViewProps> = ({ style }) => {
  const { grid } = usePowerGridContext();
  const dispatch = usePowerGridDispatch();
  const { height, ref } = useResizeDetector();
  const { mode } = grid;

  const [modalProps, setModalProps] = useState<Partial<EdgeDetailsModalProps>>({
    visible: false
  });

  const onSubmitModal = (edge: Edge) => {
    dispatch(
      modalProps.edge ? actions.updateEdge(edge) : actions.addEdge(edge)
    );
    setModalProps({ visible: false });
  };

  const onCancelModal = () => setModalProps({ visible: false });

  const onAddEdgeClick = () => setModalProps({ visible: true });

  const renderActionsColumn = (_: any, record: Edge) => {
    const onEditClick = () =>
      setModalProps({
        edge: record,
        visible: true
      });
    const onDeleteClick = () => dispatch(actions.removeEdge(record));
    return (
      <ActionToolbar>
        <IconButton onClick={onEditClick}>
          <IconEditSvg />
        </IconButton>
        {mode === PowerGridMode.CONFIGURATION && (
          <IconButton onClick={onDeleteClick}>
            <IconTrashSvg />
          </IconButton>
        )}
      </ActionToolbar>
    );
  };

  return (
    <Container style={style}>
      {mode === PowerGridMode.CONFIGURATION && (
        <StyledToolbar>
          <Button type="secondary" onClick={onAddEdgeClick}>
            <FormattedMessage id="button.add" />
          </Button>
        </StyledToolbar>
      )}
      <Content ref={ref}>
        {isNumber(height) && (
          <EdgeTable
            scroll={{ y: height }}
            extraColumns={[
              {
                key: 'actions',
                width: 100,
                align: 'center',
                render: renderActionsColumn,
                fixed: 'right'
              }
            ]}
          />
        )}
      </Content>
      <EdgeDetailsModal
        {...modalProps}
        onSubmit={onSubmitModal}
        onCancel={onCancelModal}
      />
    </Container>
  );
};

export default EdgeListView;
