// © ООО «Эдиспа», 2022

import React from 'react';
import ReactPDF, { Text, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { isArray, isObject } from 'lodash';

import styles from 'components/pdf/summary/styles';
import { Column } from 'components/pdf/summary/types';

interface SummaryProps<T> {
  record: T;
  columns: Column<T>[];
}

export default function Summary<T>(
  props: SummaryProps<T> & ReactPDF.ViewProps
) {
  const { columns, record, style, ...otherProps } = props;
  const allStyles: Style[] = [styles.summary];
  if (isArray(style)) {
    allStyles.push(...style);
  } else if (isObject(style)) {
    allStyles.push(style);
  }
  return (
    <View style={styles.summary} {...otherProps}>
      {columns.map((column, index) => (
        <View
          key={index}
          style={[
            styles.column,
            {
              textAlign: column.align || 'center'
            }
          ]}
        >
          <Text style={styles.value}>
            {column.render
              ? column.render(record, column.dataIndex)
              : record[column.dataIndex]}
          </Text>
          <Text style={styles.label}>{column.title}</Text>
        </View>
      ))}
    </View>
  );
}
