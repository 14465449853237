// © ООО «Эдиспа», 2022

import React, { FunctionComponent, ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import LinkButton from 'components/button/LinkButton';
import style from 'themes';

export interface MenuItemProps {
  href?: string;
  newTab?: boolean;
  icon?: ReactNode;
  onClick?: () => void;
}

const Container = styled.li`
  display: flex;
  justify-content: stretch;
  :hover {
    background-color: #f8f8f9;
  }
`;

const mixin = css`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 8px 16px;
`;

const StyledLink = styled(Link)`
  ${mixin};
  color: ${style('color')};
  :hover {
    color: ${style('color')};
  }
`;

const StyledLinkButton = styled(LinkButton)`
  ${mixin};
`;

const Content = styled.div`
  ${mixin};
`;

const Icon = styled.span`
  font-size: 24px;
  display: flex;
  flex-shrink: 0;
  margin-right: 16px;
`;

const MenuItem: FunctionComponent<MenuItemProps> = ({
  href,
  newTab,
  icon,
  onClick,
  children
}) => {
  const location = useLocation();
  if (href) {
    const props: any = {
      to: href,
      state: { pathname: location.pathname },
      onClick
    };
    if (newTab) {
      props.target = '_blank';
      props.rel = 'noopener noreferrer';
    }
    return (
      <Container>
        <StyledLink {...props}>
          {icon && <Icon>{icon}</Icon>}
          {children}
        </StyledLink>
      </Container>
    );
  } else if (onClick) {
    return (
      <Container>
        <StyledLinkButton onClick={onClick}>
          {icon && <Icon>{icon}</Icon>}
          {children}
        </StyledLinkButton>
      </Container>
    );
  }
  return (
    <Container>
      <Content>
        {icon && <Icon>{icon}</Icon>}
        {children}
      </Content>
    </Container>
  );
};

export default MenuItem;
