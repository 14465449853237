// © ООО «Эдиспа», 2022

import React, { FunctionComponent, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

import DemoBanner from 'page/components/DemoBanner';
import GlobalStyle from 'page/components/GlobalStyle';
import Header from 'page/components/Header';
import { actions, useDispatchPageContext } from 'page/context';

const Container = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: minmax(800px, 1fr);
  grid-template-rows: max-content max-content 1fr;
  grid-template-areas: 'demo-banner' 'header';
`;

const PageContainer: FunctionComponent = ({ children }) => {
  const dispatch = useDispatchPageContext();
  const location = useLocation();

  // сброс контекста страницы при переходе на другую страницу
  useLayoutEffect(() => {
    dispatch(actions.setContext({}));
  }, [location, dispatch]);

  return (
    <Container>
      <GlobalStyle />
      <DemoBanner />
      <Header />
      {children}
    </Container>
  );
};

export default PageContainer;
