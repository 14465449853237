// © ООО «Эдиспа», 2022

import {
  buildLiquidateEmergencyReport,
  EmergencyLiquidationReport
} from 'emergency/report';
import { Schema } from 'grid';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useFetch } from 'utils/hooks';

interface HandleEmergencyResponse {
  schema: Schema;
  report: Pick<
    EmergencyLiquidationReport,
    'generators' | 'edges' | 'phases' | 'summary' | 'date'
  >;
}

export const useLiquidateEmergency = () =>
  useFetch<HandleEmergencyResponse, Schema>(async schemaBefore => {
    const functions = getFunctions();
    const handleEmergency = httpsCallable<Schema, any>(
      functions,
      'handleEmergency',
      {
        timeout: 300000
      }
    );

    const { data } = await handleEmergency(schemaBefore);
    const { schema: schemaAfter, phases } = data;

    return {
      schema: schemaAfter,
      report: buildLiquidateEmergencyReport(schemaBefore, schemaAfter, phases)
    };
  });
