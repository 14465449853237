// © ООО «Эдиспа», 2022

import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  summary: {
    display: 'flex',
    flexDirection: 'row'
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 24
  },
  label: {
    color: '#A298A8',
    fontSize: 10
  },
  value: {
    color: '#1E052D',
    fontSize: 12
  }
});
