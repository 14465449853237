// © ООО «Эдиспа», 2022

import styled from 'styled-components/macro';

import style from 'themes';

const TextArea = styled.textarea`
  width: 100%;
  appearance: none;
  padding: 8px;
  color: ${style('color')};
  border: 1px solid transparent;
  border-radius: 8px;
  background-color: ${style('input.background')};
  font-size: 16px;
  caret-color: ${style('input.caret.color')};
  :hover {
    border: 1px solid ${style('input.hover.border.color')};
  }
  :focus,
  :active {
    outline: none;
    border: 1px solid ${style('input.focus.border.color')};
  }
`;

export default TextArea;
