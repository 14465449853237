// © ООО «Эдиспа», 2022

import styled from 'styled-components/macro';

import style from 'themes';

const Tag = styled.div`
  width: 148px;
  height: 128px;
  background-color: ${style('auth.tag.background')};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 16px;
  box-shadow: 0px 4px 24px ${style('auth.tag.shadow')};
`;

export default Tag;
