// © ООО «Эдиспа», 2022

import styled from 'styled-components/macro';

import IconButton from 'components/button/IconButton';

export const Section = styled.div`
  border-bottom: 1px solid #f4f3f5;
  padding: 16px;
  font-size: 14px;
  line-height: 14px;
`;

export const Header = styled(Section)`
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${IconButton} {
    margin-top: -6px;
    margin-bottom: -6px;
  }
`;

export const Content = styled.div`
  flex: 1;
  overflow: auto;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
