// © ООО «Эдиспа», 2022

import React, { FunctionComponent } from 'react';
import ReactPDF, { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import { DateTime } from 'luxon';

import logo from 'emergency/components/logo.png';

const styles = StyleSheet.create({
  logo: {
    width: 74,
    height: 64
  },
  reportTitle: {
    marginTop: 24,
    color: '#A298A8',
    fontWeight: 'bold',
    fontSize: 12
  },
  delimiter: {
    marginTop: 4,
    height: 2,
    width: 48,
    backgroundColor: '#FC7456'
  },
  infoContent: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginTop: 16,
    flexGrow: 1
  },
  infoContainer: {
    flexDirection: 'row'
  },
  title: {
    fontSize: 12
  },
  date: {
    fontSize: 8,
    marginTop: 2
  }
});

export interface OwnProps {
  title: string;
  date: Date;
}

type HeaderProps = OwnProps & ReactPDF.ViewProps;

const Header: FunctionComponent<HeaderProps> = ({
  title,
  date,
  ...otherProps
}) => {
  return (
    <View {...otherProps}>
      <View style={styles.infoContainer}>
        <Image style={styles.logo} src={logo} />
        <View style={styles.infoContent}>
          <Text style={styles.title}>{title}</Text>
          <Text style={styles.date}>
            {DateTime.fromJSDate(date).toFormat('dd.LL.yyyy в HH:mm')}
          </Text>
        </View>
      </View>
      <Text style={styles.reportTitle}>План ликвидации аварии</Text>
      <View style={styles.delimiter} />
    </View>
  );
};

export default Header;
